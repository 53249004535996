<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "app"
};
</script>

<style lang='scss'>
@import "./assets/scss/reset.scss";
@import "./assets/scss/mixin.scss";
@import "./assets/scss/modal.scss";
@import "./assets/scss/button.scss";

</style>

