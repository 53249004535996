var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jumbotron d-flex align-items-center min-vh-100" },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _vm.step1
          ? _c("div", { staticClass: "row d-flex justify-content-center" }, [
              _c(
                "div",
                {
                  staticClass: "col-sm welcome-container",
                  staticStyle: { "max-width": "668px" }
                },
                [
                  _vm._m(0),
                  _vm._m(1),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row d-flex justify-content-center ml-5 mr-5 mt-5"
                    },
                    [
                      _c("b-img", {
                        attrs: {
                          center: "",
                          src: "imgs/qrcode.png",
                          width: "120px",
                          height: "120px"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row d-flex justify-content-center ml-5 mr-5 mt-5"
                    },
                    [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://apps.apple.com/us/app/truthbrush/id1521192393"
                            }
                          },
                          [
                            _c("b-img", {
                              attrs: {
                                right: "",
                                src: require("../assets/image/ios-store.png"),
                                fluid: "",
                                alt: "Fluid image",
                                width: "121px",
                                height: "36px"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://play.google.com/store/apps/details?id=com.candibell.brush"
                            }
                          },
                          [
                            _c("b-img", {
                              attrs: {
                                left: "",
                                src: require("../assets/image/google-play.png"),
                                fluid: "",
                                alt: "Fluid image",
                                width: "121px",
                                height: "36px"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row d-flex justify-content-center text-center ml-5 mr-5 mt-5"
                    },
                    [
                      _vm._v(
                        "Download the TruthBrush application today to view your listing and have your patients experience the TruthBrush!"
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row d-flex justify-content-center text-center ml-5 mr-5 mt-5 mb-5"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "blueButton",
                          staticStyle: {
                            width: "100%",
                            height: "40px",
                            "font-size": "16px"
                          },
                          attrs: { type: "submit", variant: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.getstart()
                            }
                          }
                        },
                        [_vm._v("注册牙医信息")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e(),
        !_vm.step1
          ? _c("div", { staticClass: "row d-flex justify-content-center" }, [
              _c(
                "div",
                {
                  staticClass: "col-sm welcome-container",
                  staticStyle: { "max-width": "668px" }
                },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmit($event)
                        },
                        reset: _vm.onReset
                      }
                    },
                    [
                      _c("div", { staticClass: "row d-flex" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h2", { staticClass: "semi-bold" }, [
                            _vm._v("基础信息")
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "row d-flex",
                          staticStyle: { width: "80%" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              staticStyle: {
                                color: "#939393",
                                "font-size": "14px"
                              }
                            },
                            [
                              _vm._v(
                                "您的信息将会显示在Candibell的手机App中，以便于您的病人浏览您的主页和与您分享数据"
                              )
                            ]
                          )
                        ]
                      ),
                      _c("div", { staticClass: "row d-flex mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "姓 *",
                                  "label-class": "form-label",
                                  "label-for": "input-1"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.lastname,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "lastname", $$v)
                                    },
                                    expression: "form.lastname"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "名字 *",
                                  "label-class": "form-label",
                                  "label-for": "input-1"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.firstname,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "firstname", $$v)
                                    },
                                    expression: "form.firstname"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row d-flex mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "称呼 *" }
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    type: "text",
                                    options: _vm.titles,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.title,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "title", $$v)
                                    },
                                    expression: "form.title"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "牙医类 *" }
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    type: "text",
                                    options: _vm.types,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row d-flex mt-5" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h2", { staticClass: "semi-bold" }, [
                            _vm._v("工作信息")
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "row d-flex",
                          staticStyle: { width: "80%" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col",
                              staticStyle: {
                                color: "#939393",
                                "font-size": "14px"
                              }
                            },
                            [
                              _vm._v(
                                "请填写您的工作信息，这些信息将让客人更加方便的找到您"
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.loader2, rounded: "lg" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row d-flex pt-4 pb-4 align-items-end"
                            },
                            [
                              _c("div", { staticClass: "col-3" }, [
                                _c("div", {
                                  staticClass: "circle-avatar",
                                  style: {
                                    "background-image":
                                      "url(" + _vm.getprofileimageurl() + ")"
                                  }
                                })
                              ]),
                              _c(
                                "div",
                                { staticClass: "col pb-3" },
                                [
                                  _c("b-form-file", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      accept: ".jpg, .png, .gif",
                                      state: Boolean(_vm.file),
                                      placeholder: "请选择和添加头像...",
                                      "drop-placeholder": "Drop image here..."
                                    },
                                    model: {
                                      value: _vm.file,
                                      callback: function($$v) {
                                        _vm.file = $$v
                                      },
                                      expression: "file"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _c("div", { staticClass: "row d-flex mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "诊所名称 *" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.practicename,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "practicename", $$v)
                                    },
                                    expression: "form.practicename"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col", attrs: { hidden: "" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "Email *" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "email", readonly: "" },
                                  model: {
                                    value: _vm.form.provideremail,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "provideremail", $$v)
                                    },
                                    expression: "form.provideremail"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row d-flex mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "地址1 *" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.address1,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "address1", $$v)
                                    },
                                    expression: "form.address1"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "地址2 (可选)" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.form.address2,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "address2", $$v)
                                    },
                                    expression: "form.address2"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row d-flex mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "城市 *" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.city,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "city", $$v)
                                    },
                                    expression: "form.city"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "省/直辖市/自治区 *" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.state,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "state", $$v)
                                    },
                                    expression: "form.state"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row d-flex mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "邮政编码 *" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "number", required: "" },
                                  model: {
                                    value: _vm.form.zipcode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "zipcode", $$v)
                                    },
                                    expression: "form.zipcode"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "手机号码 (11位) *" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.phone,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "phone", $$v)
                                    },
                                    expression: "form.phone"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row d-flex mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "网站 (可选)" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.form.website,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "website", $$v)
                                    },
                                    expression: "form.website"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: { label: "执照 # (可选)" }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.form.license,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "license", $$v)
                                    },
                                    expression: "form.license"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row d-flex mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "描述",
                                  description:
                                    "请简单描述您的牙医诊所，医院，以及您的服务或者特长"
                                }
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    id: "textarea",
                                    placeholder: "",
                                    rows: "3",
                                    "max-rows": "6"
                                  },
                                  model: {
                                    value: _vm.form.description,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "description", $$v)
                                    },
                                    expression: "form.description"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "row d-flex mt-2 pb-4 justify-content-end"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticStyle: {
                                    "border-color": "#05c5ee",
                                    color: "#05c5ee"
                                  },
                                  attrs: {
                                    variant: "outline-primary",
                                    size: "lg"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancel()
                                    }
                                  }
                                },
                                [_vm._v("取消")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "blueButton",
                                  staticStyle: {
                                    width: "100%",
                                    "font-size": "16px"
                                  },
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "lg"
                                  }
                                },
                                [_vm._v("保存")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "canvas" }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-flex justify-content-center" }, [
      _c("h1", { staticClass: "semi-bold text-center" }, [
        _vm._v("Success! You are now part of the Candibell TruthBrush network.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "row d-flex justify-content-center text-center ml-5 mr-5 mt-5"
      },
      [
        _vm._v(
          " The TruthBrush is a new preventative care tool that allows you to provide the best in oral care. Use the TruthBrush to monitor and coach your patients brush habits. Remember to check your email for patient monitoring requests. "
        ),
        _c("br"),
        _c("br"),
        _vm._v(
          "Candibell platform offers information for dentists on structuring a preventative care program. Contact Candibell today to acquire TruthBrush products for your patients at: "
        ),
        _c("br"),
        _c("a", { attrs: { href: "https://www.candibell.cn" } }, [
          _vm._v("https://www.candibell.cn")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }