<template>
  <b-overlay :show="loader" rounded="lg">
    <b-container fluid style="background-color:#f3f3f3; height:100%">
      <b-row class="pl-2 pr-2 pt-3">
        <b-breadcrumb :items="breadcrumbitems()" style="color:#05c5ee"></b-breadcrumb>
      </b-row>

      <b-row class="mt-2 pl-4 pr-4">
        <b-form @submit.prevent="onSubmit" @reset="onReset" style="width:100%">
          <b-row>
            <div class="col">
              <h2 class="semi-bold">病人基本信息</h2>
            </div>
          </b-row>
          <b-row>
            <div class="col" style="color:#939393;font-size:14px">Information about this patient.</div>
          </b-row>

          <b-row
            class="mt-2 ml-0 mr-0 pt-3 pb-3"
            style="max-width:820px; background-color:white;border: solid 1px #e6eaee;"
          >
            <b-col cols="12">
              <b-form-group
                class="text-left"
                label="病人 ID"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
                description="An ID or reference number used in your dental management system to uniquely identify this patient"
              >
                <b-form-input v-model="form.externalpatientid" type="text" class="graybg"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="text-left"
                label="姓"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
                description="e.g. Smith"
              >
                <b-form-input v-model="form.externallastname" type="text" class="graybg" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="text-left"
                label="名"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
                description="e.g. Eric"
              >
                <b-form-input
                  v-model="form.calculatedfirstname"
                  type="text"
                  class="graybg"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>

            
          </b-row>

          <b-row class="mt-4">
            <div
              class="col"
              style="color:#939393;font-size:14px"
            >病人家庭信息</div>
          </b-row>
          <b-row
            class="mt-2 ml-0 mr-0 pt-3 pb-3"
            style="max-width:820px; background-color:white;border: solid 1px #e6eaee;"
          >
            <b-col cols="6">
              <b-form-group
                class="text-left"
                label="家庭 ID"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
                description="这个ID将可以映射家庭中每个成员"
              >
                <b-form-input v-model="customer.externalid" type="text" class="graybg"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                class="text-left"
                label="电话"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
                description="家庭主成员的手机电话"
              >
                <b-form-input v-model="customer.phone" type="tel" class="graybg"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                class="text-left"
                label="地址"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
                description="家庭地址"
              >
                <b-form-input v-model="customer.shippingline1" type="text" class="graybg"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                class="text-left"
                label="城市"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
              >
                <b-form-input v-model="customer.shippingcity" type="text" class="graybg"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 ml-0 mr-0 pt-3 pb-3">
            <b-button
              class="blueButton"
              type="submit"
              variant="primary"
              size="lg"
              style="font-size:16px"
            >存储</b-button>
          </b-row>
        </b-form>
      </b-row>

      <b-row class="mt-5">
        <div class="col">
          <h2 class="semi-bold">爱牙精灵 配置信息</h2>
        </div>
      </b-row>
      <b-row>
        <div class="col" style="color:#939393;font-size:14px">Information from Truthbrush App</div>
      </b-row>

      <b-form @submit.prevent="onSubmit2" @reset="onReset2" style="width:100%">
        <b-row
          class="mt-3 mb-4 ml-0 mr-0 pt-3 pb-5"
          style="max-width:820px; background-color:white;border: solid 1px #e6eaee;"
        >
          <b-col cols="12" class="pt-3">
            <b-row>
              <b-col cols="auto">
                <div
                  class="circle-avatar profileicon"
                  style
                  v-bind:style="{ 'background-image': 'url(' + getprofileimageurl() + ')' }"
                ></div>
              </b-col>
              <b-col cols="6" class="ml-0 pl-5" align-self="center">
                <b-row>
                  <b-col>
                    <b-row class="profilelabel">爱牙精灵 用户ID</b-row>
                    <b-row>{{getprofileID()}}</b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" class="mt-5 ml-0 pl-5">
            <b-row class="profilelabel">账号</b-row>
            <b-row class="pt-2">{{getUserId}}</b-row>
          </b-col>

          <b-col cols="6" class="mt-5 ml-0 pl-5" style>
            <b-row>
              <b-form-group
                class="text-left"
                label="生日"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
              >
                <b-form-input v-model="dob" type="date" class="graybg" style></b-form-input>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col cols="6" class="mt-4 ml-0 pl-5">
            <b-row class="profilelabel">性别</b-row>
            <b-row>{{getSexHTML}}</b-row>
          </b-col>

          <b-col cols="6" class="mt-4 pl-5">
            <b-row class="profilelabel">状态</b-row>
            <b-row>Active</b-row>
          </b-col>

          <b-col cols="6" class="mt-5 ml-0 pl-5">
            <b-row class="profilelabel">用户建立时间 (用户本地时间)</b-row>
            <b-row>{{getProfileCreateDate}}</b-row>
          </b-col>

          <b-col cols="6" class="mt-5 pl-5">
            <b-row class="profilelabel">牙刷类型</b-row>
            <b-row>{{getBrushType}}</b-row>
          </b-col>

          <b-col cols="6" class="mt-5 pl-5">
            <b-row class="profilelabel">左右手</b-row>
            <b-row>{{getHandedness()}}</b-row>
          </b-col>

          <b-col cols="6" class="mt-5 pl-5">
            <b-row class="profilelabel">用户最新更新时间 (用户本地时间)</b-row>
            <b-row>{{getLastActivity()}}</b-row>
          </b-col>

          <b-col cols="6" class="mt-5 ml-0 pl-5">
            <b-row class="profilelabel">时长定时器</b-row>
            <b-row class="pt-2">
              <b-input-group>
                <template v-slot:append>
                  <b-form-select v-model="buzzertimer" :options="buzzertimeroption"></b-form-select>
                </template>
              </b-input-group>
            </b-row>
          </b-col>

          <b-col cols="6" class="mt-5 pl-5 pr-5">
            <b-row class="profilelabel">是否通知刷牙事件</b-row>
            <b-row>
              <b-form-group
                class="text-left"
                description="如果是，当此用户每次刷完牙时，用户家长将通过app收到推送"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
              >
                <b-form-checkbox
                  v-model="perconsumptionnotification"
                  name="check-button"
                  switch
                  variant="primary"
                  size="lg"
                ></b-form-checkbox>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col cols="6" class="mt-3 pl-5">
            <b-row class="profilelabel">时区</b-row>
            <b-row class="pt-2">{{getTimezone}}</b-row>
          </b-col>
          <b-col cols="6" class="mt-3 pl-5 pr-5">
            <b-row class="profilelabel">忘记刷牙提醒</b-row>
            <b-row class="pt-2">
              <b-form-group
                class="text-left"
                description="当目标用户没有及时完成刷牙时，家长通过app收到推送通知。您可以设置早上和晚上一共两个时间点。"
                label-class="form-label"
                label-for="input-1"
                style="font-size: 12px !important;"
              >
                <b-input-group>
                  <template v-slot:prepend>
                    <b-form-select v-model="amhr" :options="amhroptions"></b-form-select>
                  </template>

                  <template v-slot:append>
                    <b-form-select v-model="pmhr" :options="pmhroptions"></b-form-select>
                  </template>
                </b-input-group>
              </b-form-group>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-0 ml-0 mr-0 pt-0 pb-5">
          <b-button
            class="blueButton"
            type="submit"
            variant="primary"
            size="lg"
            style="font-size:16px"
          >存储</b-button>
        </b-row>
      </b-form>
      <b-row class="mt-5 d-none">
        <div class="col">
          <h2 class="semi-bold">Trackers and Hubs</h2>
        </div>
      </b-row>
      <b-row class="mt-0 d-none">
        <div
          class="col"
          style="color:#939393;font-size:14px"
        >Information about Truthbrush trackers and hubs.</div>
      </b-row>

      <b-row class="mt-4 ml-0 mr-0 pb-4 mb-5 d-none">
        <b-table
          id="device-table"
          :fields="fields"
          :items="devicelist"
          hover
          style="background-color: white;"
          sort-icon-left
          :busy="isBusy"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2" style="color:#05c5ee">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(equipment)="data">
            <span v-html="getDeviceImage(data.item)"></span>
          </template>
          <template v-slot:cell(deviceid)="data">
            <span v-html="getDeviceID(data.item)"></span>
          </template>
          <template v-slot:cell(createdtime)="data">
            <span v-html="getDeviceDate(data.item.createdtime)"></span>
          </template>
          <template v-slot:cell(fwversion)="data">
            <span v-html="getFWVersion(data.item)"></span>
          </template>
          <template v-slot:cell(lastupdatedtime)="data">
            <span v-html="getDeviceDate(data.item.lastupdatedtime)"></span>
          </template>
          <template v-slot:cell(battery)="data">
            <span v-html="getBatteryHTML(data.item)"></span>
          </template>
        </b-table>
      </b-row>
      <b-row
        class="mt-0 ml-0 mr-0 pb-4"
        v-if="devicelist.length == 0"
      >This patient has no equipment registered.</b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { getCustomer, setCustomer } from "../api/customer";
import { setProfile } from "../api/profile";
import { getDeviceList } from "../api/device";
import storage from "./../storage";
export default {
  name: "singlepatientedit",

  data() {
    return {
      loader: false,
      form: {
        externalpatientid: "",
        calculatedfirstname: "",
        externallastname: ""
      },
      customer: {
        externalid: "",
        shippingline1: "",
        phone: "",
        shippingcity: ""
      },
      dob: "1900-01-01",
      perconsumptionnotification: false,
      idlenotification: false,
      notification: false,
      amhr: 9,
      buzzertimer: 120,
      amhroptions: [
        { value: -1, text: "早上警告关" },
        { value: 0, text: "午夜" },
        { value: 1, text: "1 AM" },
        { value: 2, text: "2 AM" },
        { value: 3, text: "3 AM" },
        { value: 4, text: "4 AM" },
        { value: 5, text: "5 AM" },
        { value: 6, text: "6 AM" },
        { value: 7, text: "7 AM" },
        { value: 8, text: "8 AM" },
        { value: 9, text: "9 AM" },
        { value: 10, text: "10 AM" },
        { value: 11, text: "11 AM" }
      ],
      pmhr: 9,
      pmhroptions: [
        { value: -1, text: "下午警告关" },
        { value: 0, text: "中午" },
        { value: 1, text: "1 PM" },
        { value: 2, text: "2 PM" },
        { value: 3, text: "3 PM" },
        { value: 4, text: "4 PM" },
        { value: 5, text: "5 PM" },
        { value: 6, text: "6 PM" },
        { value: 7, text: "7 PM" },
        { value: 8, text: "8 PM" },
        { value: 9, text: "9 PM" },
        { value: 10, text: "10 PM" },
        { value: 11, text: "11 PM" }
      ],
      buzzertimeroption: [
        { value: 40, text: "40 seconds" },
        { value: 50, text: "50 seconds" },
        { value: 60, text: "60 seconds" },
        { value: 70, text: "70 seconds" },
        { value: 80, text: "80 seconds" },
        { value: 90, text: "90 seconds" },
        { value: 100, text: "100 seconds" },
        { value: 110, text: "110 seconds" },
        { value: 120, text: "120 seconds" },
        { value: 130, text: "130 seconds" },
        { value: 140, text: "140 seconds" },
        { value: 150, text: "150 seconds" },
        { value: 160, text: "160 seconds" },
        { value: 170, text: "170 seconds" },
        { value: 180, text: "180 seconds" },
        { value: 190, text: "190 seconds" },
        { value: 200, text: "200 seconds" },
        { value: 210, text: "210 seconds" },
        { value: 220, text: "220 seconds" },
        { value: 230, text: "230 seconds" },
        { value: 240, text: "240 seconds" },
        { value: 250, text: "250 seconds" },
        { value: 260, text: "260 seconds" },
        { value: 270, text: "270 seconds" },
        { value: 280, text: "280 seconds" },
        { value: 290, text: "290 seconds" },
        { value: 300, text: "300 seconds" }
      ],
      devicelist: [],
      isBusy: false,
      fields: [
        {
          key: "equipment",
          label: "Equipment",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "devicetype",
          label: "Type",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "fwversion",
          label: "Firmware",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "deviceid",
          label: "Device ID",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "createdtime",
          label: "Register Time",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "lastupdatedtime",
          label: "Last Communication",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "battery",
          label: "Battery",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        }
      ]
    };
  },
  watch: {
    dob: function(val) {
      console.log(val);
    }
  },
  computed: {
    getTimezone() {
      var row = this.$store.state.selectedprofile;
      if (row.timezone) {
        return row.timezone;
      } else {
        return "--";
      }
    },
    getProfileBuzzerTimer() {
      var row = this.$store.state.selectedprofile;
      if (row.buzzertimer) {
        return row.buzzertimer + " sec";
      } else {
        return "Not set";
      }
    },
    getBrushType() {
      var row = this.$store.state.selectedprofile;
      if (row.toothbrushtype == "manual") {
        return "Manual";
      } else {
        return "Electric";
      }
    },
    getProfileCreateDate() {
      var row = this.$store.state.selectedprofile;
      var date = new Date(row.createdtime);
      return date.toLocaleString();
    },
    getSexHTML() {
      var row = this.$store.state.selectedprofile;
      if (row.sex && row.sex == "f") {
        return "Female";
      } else {
        return "Male";
      }
    },
    getUserId: function() {
      return this.$store.state.selectedprofile.userid;
    },
    getDobHTML: function() {
      var row = this.$store.state.selectedprofile;
      if (row.birthday && row.birthmonth && row.birthyear) {
        var m = "";
        switch (row.birthmonth) {
          case 1:
            m = "Jan";
            break;
          case 2:
            m = "Feb";
            break;
          case 3:
            m = "Mar";
            break;
          case 4:
            m = "Apr";
            break;
          case 5:
            m = "May";
            break;
          case 6:
            m = "Jun";
            break;
          case 7:
            m = "Jul";
            break;
          case 8:
            m = "Aug";
            break;
          case 9:
            m = "Sep";
            break;
          case 10:
            m = "Oct";
            break;
          case 11:
            m = "Nov";
            break;
          case 12:
            m = "Dec";
            break;
        }
        return m + "/" + ("0" + row.birthday).slice(-2) + "/" + row.birthyear;
      } else {
        return "N/A";
      }
    }
  },
  methods: {
    getFWVersion(row) {
      if (row.fwversion) {
        var version = parseInt(row.fwversion, 16);
        return version;
      } else {
        return "--";
      }
    },
    getDeviceID(row) {
      return row.deviceid.slice(-4);
    },
    getDeviceImage(row) {
      //var icon = row.devicedecoration
      var url = "";
      if (row.devicetype == "HUB") {
        url = "hub@3x.png";
      } else {
        if (row.devicedecoration == "DEFAULT") {
          url = "imageDeviceTrackerDefault@3x.png";
        } else if (row.devicedecoration == "FLAMINGO") {
          url = "imageDeviceTrackerFlamingo@3x.png";
        } else if (row.devicedecoration == "UNICORN") {
          url = "imageDeviceTrackerUnicorn@3x.png";
        } else if (row.devicedecoration == "PEACE") {
          url = "imageDeviceTrackerPeace@3x.png";
        } else if (row.devicedecoration == "ASTRONAUT") {
          url = "imageDeviceTrackerAstronaut@3x.png";
        } else if (row.devicedecoration == "CAR") {
          url = "imageDeviceTrackerCar@3x.png";
        } else if (row.devicedecoration == "PEACOCK") {
          url = "imageDeviceTrackerPeacock@3x.png";
        } else if (row.devicedecoration == "ORANGE") {
          url = "imageDeviceTrackerOrange@3x.png";
        } else if (row.devicedecoration == "DINOSAUR") {
          url = "imageDeviceTrackerDinosaur@3x.png";
        } else if (row.devicedecoration == "HEART") {
          url = "imageDeviceTrackerHeart@3x.png";
        }
      }
      console.log(url);
      var html =
        "<img src='/imgs/" +
        url +
        "' style='width:60px; max-width:90px'></img>";

      return html;
    },
    getDeviceDate(time) {
      console.log(time)
      let profile = this.$store.state.selectedprofile;
      var date = new Date(profile.createdtime);
      var timezone = profile.timezone;
      if (timezone == null) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      var localdatestr = date.toLocaleString("en-US", { timeZone: timezone });

      return localdatestr;
    },
    getBatteryHTML(row) {
      if (row.battery) {
        return (parseInt(row.battery, 16) / 1000.0).toFixed(2) + "v";
      } else {
        return "--";
      }
    },
    saveprofiles() {
      let profile = this.$store.state.selectedprofile;
      profile.externalid = this.form.externalpatientid;
      profile.externalfirstname = this.form.calculatedfirstname;
      profile.externallastname = this.form.externallastname;
      profile.notification = true;
      profile.perconsumptionnotification = this.perconsumptionnotification;

      profile.amhr = this.amhr;
      profile.pmhr = this.pmhr;
      profile.buzzertimer = this.buzzertimer;
      if (this.amhr >= 0 || this.pmhr >= 0) {
        profile.idlenotification = true;
      } else {
        profile.idlenotification = false;
      }

      if (this.dob != null) {
        var token = this.dob.split("-");
        if (token != null && token.length >= 3) {
          profile.birthyear = token[0];
          profile.birthmonth = token[1];
          profile.birthday = token[2];
        }
      }
      let providerid = storage.getProvider().providerid;

      this.loader = true;
      let customer = this.customer;
      let context = this;
      setProfile(providerid, profile)
        .then(function() {
          setCustomer(providerid, customer)
            .then(function() {
              context.loader = false;
            })
            .catch(function() {
              context.loader = false;
            });
        })
        .catch(function() {
          context.loader = false;
        });
    },
    getprofileimageurl() {
      let profile = this.$store.state.selectedprofile;
      if (profile.avatarid != null) {
        return profile.avatarid;
      }
      return "/imgs/defaultuser@3x.png";
    },
    getLastActivity() {
      let profile = this.$store.state.selectedprofile;
      if(profile.lastactivitytime == null ){
        return "--";
      }
      var date = new Date(profile.lastactivitytime);
      var timezone = profile.timezone;
      if (timezone == null) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      var localdatestr = date.toLocaleString("en-US", { timeZone: timezone });

      return localdatestr;
    },
    getHandedness() {
      let profile = this.$store.state.selectedprofile;
      if (profile.lefthand == true) {
        return "Left";
      } else {
        return "Right";
      }
    },
    getEventNotification() {
      let profile = this.$store.state.selectedprofile;
      if (
        profile.notification == true &&
        profile.perconsumptionnotification == true
      ) {
        return "On";
      } else {
        return "Off";
      }
    },
    getIdleNotification() {
      let profile = this.$store.state.selectedprofile;
      if (profile.notification == true && profile.idlenotification == true) {
        return "On";
      } else {
        return "Off";
      }
    },
    getprofileID() {
      let profile = this.$store.state.selectedprofile;
      return profile.profileid;
    },
    getNameHTML(row) {
      var lastname = row.externallastname;
      var firstname = row.externalfirstname;
      if (firstname == null || firstname == "") {
        firstname = row.firstname;
      }
      var name = "";
      if (lastname && firstname) {
        name = lastname + ", " + firstname;
      } else if (lastname) {
        name = lastname + ", ";
      } else if (firstname) {
        name = firstname;
      }
      if (name == null || name == "") {
        return "<p style='color:#c6c6c6'>N/A</p>";
      } else {
        return name;
      }
    },
    breadcrumbitems() {
      var name = this.getNameHTML(this.$store.state.selectedprofile);
      var array = [
        {
          text: "My patients",
          href: "#/patients"
        },
        {
          text: name,
          href: "#/singlepatient"
        },
        {
          text: "Edit",
          href: "#",
          active: true
        }
      ];
      return array;
    },
    onReset() {},
    onReset2() {},

    onSubmit() {
      this.saveprofiles();
    },
    onSubmit2() {
      this.saveprofiles();
    }
  },
  mounted() {
    var provider = storage.getProvider()
    if (
      provider == null ||
      this.$store.state.selectedprofile == null
    ) {
      window.location.href = "/";
    }

    console.log(
      "activity log page. Activity ProfileId:" +
        this.$store.state.selectedprofile.profileid
    );

    var profile = this.$store.state.selectedprofile;
    if (profile.externalid) {
      this.form.externalpatientid = profile.externalid;
    } else {
      this.form.externalpatientid = profile.profileid;
    }

    if (profile.firstname) {
      this.form.calculatedfirstname = profile.firstname;
    }

    if (profile.externalfirstname) {
      this.form.calculatedfirstname = profile.externalfirstname;
    }

    if (profile.externallastname) {
      this.form.externallastname = profile.externallastname;
    }

    if (profile.birthday != null) {
      this.dob =
        profile.birthyear + "-" + ('0'+profile.birthmonth).slice(-2) + "-" + ('0'+profile.birthday).slice(-2);
    }
    this.notification = true;
    this.perconsumptionnotification = profile.perconsumptionnotification;
    this.idlenotification = profile.idlenotification;

    this.amhr = profile.amhr;
    this.pmhr = profile.pmhr;

    if(this.amhr == null || this.idlenotification == false){
      this.amhr = -1;
    }

    if(this.pmhr == null || this.idlenotification == false){
      this.pmhr = -1;
    }
    

    this.buzzertimer = profile.buzzertimer;
    if (this.buzzertimer == null) {
      this.buzzertimer = 120;
    }
    let providerid = provider.providerid;
    let userid = this.$store.state.selectedprofile.userid;
    var context = this;
    this.loader = true;
    getCustomer(providerid, userid)
      .then(res => {
        var customer = res.data;
        context.customer = customer;
        if (customer.externalid) {
          context.customer.externalid = customer.externalid;
        } else {
          context.customer.externalid = customer.userid;
        }
        if (customer.shippingline1) {
          context.customer.shippingline1 = customer.shippingline1;
        }
        if (customer.phone) {
          context.customer.phone = customer.phone;
        }
        if (customer.shippingcity) {
          context.customer.shippingcity = customer.shippingcity;
        }
        context.loader = false;
      })
      .catch(function() {
        context.loader = false;
      });
    this.isBusy = true;
    getDeviceList(providerid, userid)
      .then(res => {
        context.isBusy = false;
        context.devicelist = res.data.filter(
          elem =>
            elem.profileid == profile.profileid || elem.devicetype == "HUB"
        );
      })
      .catch(function() {
        context.loader = false;
      });
  }
};
</script>

<style>
.profileicon {
  width: 72px !important;
  height: 72px !important;
}

.circle-avatar {
  /* make it responsive */
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
  /* div height to be the same as width*/
  padding-top: 100%;

  /* make it a circle */
  border-radius: 50%;

  /* Centering on image`s center*/
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;

  /* it makes the clue thing, takes smaller dimension to fill div */
  background-size: cover;

  /* it is optional, for making this div centered in parent*/
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.profilelabel {
  font-size: 12px;
  color: #6f6f6f;
}

.graybg {
  background-color: #f4f4f4 !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #05c5ee !important;
  background: #05c5ee !important;
}
</style>