var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticStyle: { "background-color": "#f3f3f3", height: "100%" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 pt-3" },
        [
          _c("b-breadcrumb", {
            staticStyle: { color: "#05c5ee" },
            attrs: { items: _vm.breadcrumbitems }
          })
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 pt-2" },
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("h5", { staticClass: "semi-bold" }, [_vm._v("家庭成员")])
          ])
        ],
        1
      ),
      _c("b-row", { staticClass: "mt-0 pl-2" }, [
        _c(
          "div",
          {
            staticClass: "col",
            staticStyle: { color: "#939393", "font-size": "14px" }
          },
          [_vm._v("关于所有家庭成员的信息")]
        )
      ]),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 mt-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("b-table", {
                staticStyle: { "background-color": "white" },
                attrs: {
                  id: "my-table",
                  fields: _vm.fields,
                  items: _vm.profilelist,
                  hover: "",
                  "sort-icon-left": "",
                  busy: _vm.isBusy,
                  "tbody-tr-class": _vm.rowClass
                },
                on: {
                  "row-clicked": function(item, index, event) {
                    return _vm.onClickRow(item)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-busy",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "text-center my-2",
                            staticStyle: { color: "#05c5ee" }
                          },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _c("strong", [_vm._v(" Loading...")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(composedname)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getNameHTML(data.item))
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(status)",
                    fn: function() {
                      return [
                        _c("span", { staticStyle: { color: "#189940" } }, [
                          _vm._v("Active")
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(sex)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getSexHTML(data.item))
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(dob)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getDobHTML(data.item))
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 pt-5", attrs: { "align-v": "center" } },
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("div", {
              staticClass: "circle-avatar profileicon",
              style: {
                "background-image": "url(" + _vm.getprofileimageurl() + ")"
              }
            })
          ]),
          _c(
            "b-col",
            { attrs: { cols: "auto pl-3" } },
            [
              _c("b-row", [
                _c(
                  "h2",
                  {
                    staticClass: "bold",
                    staticStyle: { "margin-bottom": "0" }
                  },
                  [_vm._v(_vm._s(_vm.getDisplayName()))]
                )
              ]),
              _c("b-row", [
                _c(
                  "h5",
                  {
                    staticClass: "bold",
                    staticStyle: { "margin-bottom": "0", "padding-top": "5px" }
                  },
                  [_vm._v(_vm._s(_vm.displaylocation))]
                )
              ])
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticStyle: { "border-color": "#05c5ee" },
                  attrs: {
                    size: "lg",
                    variant: "outline-info",
                    href: "/#/singlepatientedit"
                  }
                },
                [_vm._v("配置用户")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "b-form-group",
                { attrs: { id: "input-group-3", "label-for": "input-3" } },
                [
                  _c("b-form-select", {
                    staticStyle: {
                      "border-radius": "30px",
                      border: "2px solid #05c5ee",
                      width: "150px"
                    },
                    attrs: {
                      size: "lg",
                      id: "input-3",
                      options: _vm.timewindows
                    },
                    model: {
                      value: _vm.timewindow,
                      callback: function($$v) {
                        _vm.timewindow = $$v
                      },
                      expression: "timewindow"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 mt-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h5", { staticClass: "semi-bold mb-2" }, [_vm._v("概况")])
          ]),
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h5", { staticClass: "semi-bold mb-2" }, [_vm._v("数据")])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 pt-2" },
        [
          _c(
            "b-col",
            { attrs: { xl: "6" } },
            [
              _c(
                "b-container",
                {
                  staticClass: "pb-4 pt-2",
                  staticStyle: {
                    "background-color": "white",
                    border: "1px solid #e6eaee"
                  }
                },
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "pt-4 ml-0 mr-0 pb-3",
                      staticStyle: { "border-bottom": "1px solid #e6eaee" }
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pl-0", attrs: { cols: "auto" } },
                        [
                          _c("h5", [
                            _vm._v(
                              "在最近" +
                                _vm._s(_vm.windowdayTitle) +
                                "周, " +
                                _vm._s(_vm.getDisplayName()) +
                                " ..."
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-icon", {
                            staticStyle: { color: "gray", float: "right" },
                            attrs: {
                              icon: "question-circle",
                              "font-scale": "1.5",
                              id: "questionmark"
                            }
                          }),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "questionmark",
                                triggers: "hover",
                                placement: "top"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "title",
                                  fn: function() {
                                    return [_vm._v("刷牙质量平分")]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c(
                                "b-container",
                                [
                                  _c("b-row", { staticClass: "pt-2" }, [
                                    _c(
                                      "p",
                                      { staticStyle: { display: "inline" } },
                                      [
                                        _c("b", [_vm._v("次数:")]),
                                        _vm._v(" 每天刷牙两次天数的比例 ")
                                      ]
                                    )
                                  ]),
                                  _c("b-row", { staticClass: "pt-2" }, [
                                    _c(
                                      "p",
                                      { staticStyle: { display: "inline" } },
                                      [
                                        _c("b", [_vm._v("时长:")]),
                                        _vm._v(
                                          " 平均每次刷牙时间。刷牙前的准备时间和后的清洁时间不在统计之内 "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("b-row", { staticClass: "pt-2" }, [
                                    _c(
                                      "p",
                                      { staticStyle: { display: "inline" } },
                                      [
                                        _c("b", [_vm._v("覆盖:")]),
                                        _vm._v(
                                          " 是否覆盖了每个区域。某个区域长期的覆盖不足将被展现出来 "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("b-row", { staticClass: "pt-2" }, [
                                    _c(
                                      "p",
                                      { staticStyle: { display: "inline" } },
                                      [
                                        _c("b", [_vm._v("效率:")]),
                                        _vm._v(
                                          " 刷牙中是否专心，能否一气呵成的刷完牙 "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "pl-3 pt-2",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pt-3 pb-3" },
                        [
                          _c("b-row", { staticClass: "pl-3 pt-0" }, [
                            _c("h5", { staticClass: "semi-bold mb-2" }, [
                              _vm._v("分类")
                            ])
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "pl-3 pt-3 mt-1" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-0 pr-0",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("b-img", {
                                    staticStyle: {
                                      width: "70%",
                                      "max-width": "48px"
                                    },
                                    attrs: {
                                      src: "/imgs/frequency@3x.png",
                                      alt: "Center image"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "pl-0" },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0" },
                                    [
                                      _c("b-col", { staticClass: "pl-0" }, [
                                        _c("h5", { staticClass: "semi-bold" }, [
                                          _vm._v("次数")
                                        ])
                                      ]),
                                      _c(
                                        "b-col",
                                        { staticClass: "pl-0" },
                                        [
                                          _vm.frequencypaul < 60
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "danger" }
                                                },
                                                [_vm._v("Low")]
                                              )
                                            : _vm.frequencypaul <= 85 &&
                                              _vm.frequencypaul >= 60
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "warning" }
                                                },
                                                [_vm._v("Fair")]
                                              )
                                            : _vm.frequencypaul > 85
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "success" }
                                                },
                                                [_vm._v("Good")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", { staticClass: "ml-0" }, [
                                    _vm._v(
                                      _vm._s(_vm.frequencypaul) +
                                        "% - 的日子刷了两次牙"
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "pl-3 pt-3 mt-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-0 pr-0",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("b-img", {
                                    staticStyle: {
                                      width: "70%",
                                      "max-width": "48px"
                                    },
                                    attrs: {
                                      src: "/imgs/duration@3x.png",
                                      alt: "Center image"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "pl-0" },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0" },
                                    [
                                      _c("b-col", { staticClass: "pl-0" }, [
                                        _c("h5", { staticClass: "semi-bold" }, [
                                          _vm._v("时长")
                                        ])
                                      ]),
                                      _c(
                                        "b-col",
                                        { staticClass: "pl-0" },
                                        [
                                          _vm.averageduration < 60
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "danger" }
                                                },
                                                [_vm._v("Low")]
                                              )
                                            : _vm.averageduration >= 60 &&
                                              _vm.averageduration < 90
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "warning" }
                                                },
                                                [_vm._v("Fair")]
                                              )
                                            : _vm.averageduration >= 90
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "success" }
                                                },
                                                [_vm._v("Good")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", { staticClass: "ml-0" }, [
                                    _vm._v(
                                      _vm._s(_vm.averageduration) + "s 平均时长"
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "pl-3 pt-3 mt-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-0 pr-0",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("b-img", {
                                    staticStyle: {
                                      width: "70%",
                                      "max-width": "48px"
                                    },
                                    attrs: {
                                      src: "/imgs/completeness@3x.png",
                                      alt: "Center image"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "pl-0" },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0" },
                                    [
                                      _c("b-col", { staticClass: "pl-0" }, [
                                        _c("h5", { staticClass: "semi-bold" }, [
                                          _vm._v("覆盖")
                                        ])
                                      ]),
                                      _c(
                                        "b-col",
                                        { staticClass: "pl-0" },
                                        [
                                          _vm.completeness < 51
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "danger" }
                                                },
                                                [_vm._v("Low")]
                                              )
                                            : _vm.completeness < 80
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "warning" }
                                                },
                                                [_vm._v("Fair")]
                                              )
                                            : _vm.completeness <= 100
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "success" }
                                                },
                                                [_vm._v("Good")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", { staticClass: "ml-0" }, [
                                    _vm._v(
                                      _vm._s(_vm.completeness) +
                                        "% 的内外上下牙齿区域"
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "pl-3 pt-3 mt-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pl-0 pr-0",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("b-img", {
                                    staticStyle: {
                                      width: "70%",
                                      "max-width": "48px"
                                    },
                                    attrs: {
                                      src: "/imgs/efficiency@3x.png",
                                      alt: "Center image"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "pl-0" },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0" },
                                    [
                                      _c("b-col", { staticClass: "pl-0" }, [
                                        _c(
                                          "h5",
                                          { staticClass: "semi-bold mb-2" },
                                          [_vm._v("效率")]
                                        )
                                      ]),
                                      _c(
                                        "b-col",
                                        { staticClass: "pl-0" },
                                        [
                                          _vm.efficiency < 40
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "danger" }
                                                },
                                                [_vm._v("Low")]
                                              )
                                            : _vm.efficiency >= 40 &&
                                              _vm.efficiency < 70
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "warning" }
                                                },
                                                [_vm._v("Fair")]
                                              )
                                            : _vm.efficiency >= 70
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    display: "table-cell"
                                                  },
                                                  attrs: { variant: "success" }
                                                },
                                                [_vm._v("Good")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", { staticClass: "ml-0" }, [
                                    _vm._v(
                                      _vm._s(_vm.efficiency) + "% 总体运动时间"
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6", "align-v": "center" } },
                        [
                          _c(
                            "b-row",
                            {
                              staticStyle: { "align-items": "center" },
                              attrs: { id: "pb", "align-h": "center" }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0",
                                  staticStyle: { "text-align": "center" }
                                },
                                [_vm._v("红色部分需要多刷")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "6" } },
            [
              _c(
                "b-container",
                {
                  staticClass: "pl-0 pr-0",
                  staticStyle: { "background-color": "white", height: "100%" }
                },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "charttabview",
                      attrs: { "content-class": "mt-3", fill: "" }
                    },
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: { active: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("h5", { staticClass: "semi-bold" }, [
                                    _vm._v("次数")
                                  ]),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.frequencypaul * 1).toFixed(0)
                                      ) + "%"
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("canvas", {
                            staticClass: "canvas-patient",
                            attrs: { id: "frequency-chart" }
                          })
                        ]
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("h5", { staticClass: "semi-bold" }, [
                                    _vm._v("时长")
                                  ]),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.averageduration) + "s")
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("canvas", {
                            staticClass: "canvas-patient",
                            attrs: { id: "duration-chart" }
                          })
                        ]
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("h5", { staticClass: "semi-bold" }, [
                                    _vm._v("覆盖")
                                  ]),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.completeness) + "%")
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("canvas", {
                            staticClass: "canvas-patient",
                            attrs: { id: "completeness-chart" }
                          })
                        ]
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _c("h5", { staticClass: "semi-bold" }, [
                                    _vm._v("效率")
                                  ]),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.efficiency) + "%")
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("canvas", {
                            staticClass: "canvas-patient",
                            attrs: { id: "efficiency-chart" }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 mt-5" },
        [
          _c("b-col", [
            _c("h5", { staticClass: "semi-bold" }, [_vm._v("刷牙日志")])
          ]),
          _c("b-col", { attrs: { cols: "auto" } }, [_vm._v("Show")]),
          _c(
            "b-col",
            { staticClass: "pl-0 pr-0", attrs: { cols: "auto" } },
            [
              _c(
                "b-form-group",
                {
                  staticStyle: { "margin-bottom": "0!important" },
                  attrs: { id: "input-group-3", "label-for": "input-3" }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      size: "sm",
                      id: "input-3",
                      options: _vm.countwindows
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "auto" } }, [_vm._v("entries")])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 mt-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("b-table", {
                staticStyle: { "background-color": "white" },
                attrs: {
                  id: "my-table",
                  fields: _vm.fieldsactivity,
                  items: _vm.records,
                  hover: "",
                  "sort-icon-left": "",
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "tbody-tr-class": "table-row",
                  "per-page": _vm.perPage,
                  "current-page": _vm.currentPage,
                  busy: _vm.isBusyRecords
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "row-clicked": function(item, index, event) {
                    return _vm.onClickRowActivity(item)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-busy",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "text-center my-2",
                            staticStyle: { color: "#05c5ee" }
                          },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _c("strong", [_vm._v(" Loading...")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(rowbutton)",
                    fn: function(data) {
                      return [
                        _c(
                          "b-link",
                          {
                            staticClass: "semi-bold",
                            staticStyle: { color: "#05c5ee" },
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.onClickRowActivity(data.item)
                              }
                            }
                          },
                          [_vm._v("View")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "b-row",
                { staticClass: "mt-3 pl-4 pr-4", attrs: { "align-h": "end" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.recordcount,
                      "per-page": _vm.perPage,
                      "aria-controls": "my-table"
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-row", { staticClass: "mt-1" }, [
        _c("div", { staticClass: "col" }, [
          _c("h5", { staticClass: "semi-bold" }, [_vm._v("设备")])
        ])
      ]),
      _c("b-row", { staticClass: "mt-0" }, [
        _c(
          "div",
          {
            staticClass: "col",
            staticStyle: { color: "#939393", "font-size": "14px" }
          },
          [_vm._v("关于此用户的Tracker和Hub")]
        )
      ]),
      _c(
        "b-row",
        { staticClass: "mt-4 ml-0 mr-0 pb-4 mb-0" },
        [
          _c("b-table", {
            staticStyle: { "background-color": "white" },
            attrs: {
              id: "device-table",
              fields: _vm.fieldsdevice,
              items: _vm.devicelist,
              hover: "",
              "sort-icon-left": "",
              busy: _vm.isBusy2
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-center my-2",
                        staticStyle: { color: "#05c5ee" }
                      },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v(" Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(equipment)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getDeviceImage(data.item))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(deviceid)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getDeviceID(data.item))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(createdtime)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getDeviceDate(data.item.createdtime)
                        )
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(temp)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getDeviceTemp(data.item))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(fwversion)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getFWVersion(data.item))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(lastupdatedtime)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.getDeviceDate(data.item.lastupdatedtime)
                        )
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(battery)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getBatteryHTML(data.item))
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.devicelist.length == 0
        ? _c("b-row", { staticClass: "mt-0 ml-0 mr-0 pb-4" }, [
            _vm._v(
              "This profile, " +
                _vm._s(_vm.getDisplayName()) +
                ", has no equipment registered."
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }