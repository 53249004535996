<template>
  <div>
    <nav-header></nav-header>
    <b-overlay :show="show" rounded="md">
      <div class="main-container">
        <div class="row" style="margin: 0">
          <div class="col-md-1 py-2 nav-panel" style="padding: 0">
            <div class="row mt-3" style="margin: 0; height: 80%">
              <div class="col" style="padding: 0">
                <b-nav vertical fixed="top">
                  <b-nav-item class="nav-item" to="/overview" active>
                    <b-row
                      align-h="center"
                      style="font-size: 18px; color: white"
                    >
                      <b-icon icon="house-door"></b-icon>
                    </b-row>
                    <b-row align-h="center">
                      <p class="verticllabel">纵览</p>
                    </b-row>
                  </b-nav-item>
                  <b-nav-item class="nav-item" to="/patients" active>
                    <b-row
                      align-h="center"
                      style="font-size: 18px; color: white"
                    >
                      <b-icon icon="people"></b-icon>
                    </b-row>
                    <b-row align-h="center">
                      <p class="verticllabel">病人</p>
                    </b-row>
                  </b-nav-item>
                  <b-nav-item class="nav-item" to="/orders">
                    <b-row
                      align-h="center"
                      style="font-size: 18px; color: white"
                    >
                      <b-icon icon="graph-up"></b-icon>
                    </b-row>
                    <b-row align-h="center">
                      <p class="verticllabel">订单</p>
                    </b-row>
                  </b-nav-item>
                  <b-nav-item class="nav-item" to="/insights">
                    <b-row
                      align-h="center"
                      style="font-size: 18px; color: white"
                    >
                      <b-icon-eye></b-icon-eye>
                    </b-row>
                    <b-row align-h="center">
                      <p class="verticllabel">趋势</p>
                    </b-row>
                  </b-nav-item>
                  <b-nav-item class="nav-item" to="/invite">
                    <b-row
                      align-h="center"
                      style="font-size: 18px; color: white"
                    >
                      <b-icon icon="envelope-open"></b-icon>
                    </b-row>
                    <b-row align-h="center">
                      <p class="verticllabel">邀请</p>
                    </b-row>
                  </b-nav-item>
                </b-nav>
              </div>
            </div>
            <div class="row align-items-end pb-5" style="margin: 0">
              <div class="col" style="padding: 0">
                <b-nav vertical>
                  <b-nav-item class="nav-item" to="/settings">
                    <b-row
                      align-h="center"
                      style="font-size: 18px; color: white"
                    >
                      <b-icon-gear></b-icon-gear>
                    </b-row>
                    <b-row align-h="center">
                      <p class="verticllabel">设置</p>
                    </b-row>
                  </b-nav-item>
                  <b-nav-item class="nav-item" @click.prevent="showLogoutModal">
                    <b-row
                      align-h="center"
                      style="font-size: 18px; color: white"
                    >
                      <b-icon-box-arrow-right></b-icon-box-arrow-right>
                    </b-row>
                    <b-row align-h="center">
                      <p class="verticllabel">登出</p>
                    </b-row>
                  </b-nav-item>
                </b-nav>
              </div>
            </div>
          </div>
          <div class="col-md offset-md-1" style="padding: 0">
            <router-view
              class="children-container"
              :parentready="parentready"
            ></router-view>
          </div>
        </div>
      </div>

      <modal
        title="Logout"
        confirmText="Confirm"
        btnType="3"
        modalType="middle"
        v-bind:showModal="isShowLogoutModal"
        v-on:submit="onLogout"
        v-on:cancel="isShowLogoutModal = false"
      >
        <template v-slot:body>
          <p>确定登出?</p>
        </template>
      </modal>
    </b-overlay>
  </div>
</template>

<script>
//import storage from "./../storage";
import cognito from "./../cognito";
import NavHeader from "./../components/NavHeader";
import Modal from "./../components/Modal";
import { getProviderInfo } from "../api/provider";
import storage from "./../storage";

export default {
  name: "index",
  components: {
    NavHeader,
    Modal,
  },
  data() {
    return {
      idtoken: "",
      show: false,
      idtokenExpire: "",
      isShowLogoutModal: false,
      parentready: false,
    };
  },
  mounted() {
    console.log("index page mounted");
    /*
    var context = this;
    cognito.getCognitoUserSession(function(session) {
      context.idtokenExpire =
        (session.idToken.payload.exp - Date.now() / 1000).toFixed(0) +
        " sec. Auto refresh is enabled.";
      context.idtoken = session.idToken.jwtToken;
    });
    */
    var phone = "";
    this.show = true;
    var context = this;
    var provider = storage.getProvider();
    var key = "";
    if (provider != null) {
      phone = provider.providerphone;
      key = provider.providerkey
    }
    if (phone.length == 0) {
      context.show = false;
      window.location.href = "/#/login";
    }

    getProviderInfo(phone, key)
      .then((res) => {
        context.show = false;
        console.log("getProviderInfo complete");
        if (res.data.providerkey) {
          this.$store.dispatch("saveUserName", res.data.providername);
          storage.saveProvider(res.data);
          if (
            res.data.providername &&
            res.data.providerlastname &&
            res.data.providerofficename &&
            res.data.providerofficeaddress1 &&
            res.data.providerofficephone &&
            res.data.providerofficezipcode &&
            res.data.providerofficename
          ) {
            console.log("redirect to main");
            this.parentready = true;
          } else {
            if (storage.getItem("wizardshown") != "0") {
              //return;
            }
            console.log("info incomplete:" + res.data.providerkey);
            console.log(res.data);
            window.location.href = "/#/createprofile";
            storage.setItem("wizardshown", 1);
          }
        } else {
          if (storage.getItem("wizardshown") != "0") {
            return;
          }
          // No providerKey go to createprofile page
          window.location.href = "/#/createprofile";
          storage.setItem("wizardshown", 1);
        }
      })
      .catch(function () {
        context.show = false;
      });
  },
  methods: {
    onLogout: function () {
      this.isShowLogoutModal = false;
      cognito.logoutCognitoUser();
      console.log("log out successful");

    },
    showLogoutModal() {
      this.isShowLogoutModal = true;
    },
  },
};
</script>

<style lang="scss">
@import "./../assets/scss/base.scss";
@import "./../assets/scss/mixin.scss";
@import "./../assets/scss/config.scss";
.main-container {
  .nav-panel {
    position: fixed;
    top: 49px;
    bottom: 0;
    background-color: $colorBackground;
    img {
      margin-left: 16px;
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }
}

figure {
  figcaption {
    font-family: ibm-plex-san;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.16px;
    color: #ffffff;
    text-align: center;
    margin-top: 8px;
  }
  margin-bottom: 8 !important;
}

.children-container {
  padding-top: 49px;
  height: 100% !important;
  min-height: 100vh;
}

.verticllabel {
  color: white;
  font-size: 14px;
  text-align: center;
  padding-top: 2px;
}
</style>