<template>
  <b-container fluid style="background-color:#f3f3f3; height:100%">
    <b-modal
      id="modal-xl"
      ref="my-modal"
      centered
      title
      size="lg"
      body-class="pt-0"
      header-class="pb-0"
    >
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h2 class="semi-bold pl-3 pt-3">邀请病人</h2>
        <b-button size="lg" variant="outline" @click="close()">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <template v-slot:modal-footer="{}">
        <p></p>
      </template>
      <b-container fluid>
        <p
          class="my-4"
          style="font-size:14px"
        >生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。</p>
        <b-row class="pl-3 pr-3">
          <b-form @submit.prevent="onSubmit" style="width:100%">
            <div class="row d-flex mt-4">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="手机号 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                  description="11位手机号"
                >
                  <b-form-input v-model="form.email" type="number" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="姓 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                  description="家庭主要成员的姓"
                >
                  <b-form-input v-model="form.lastname" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="用户识别码 (可选)"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                  description="识别码为可选。帮助您关联用户在康迪贝尔和您其他系统中的相互关系"
                >
                  <b-form-input v-model="form.id" type="text"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <b-row class="pl-3 pr-3">
              <b-form-group label>
                <b-form-radio
                  v-model="selected"
                  name="some-radios"
                  value="0"
                  selected
                >生成邀请码，不发出短信</b-form-radio>
                <b-form-radio
                  v-model="selected"
                  name="some-radios"
                  value="1"
                >生成邀请码，并且短信通知用户</b-form-radio>
              </b-form-group>
            </b-row>

            <div class="row d-flex mt-2 justify-content-center">
              <div class="col-4">
                <b-button
                  class="blueButton"
                  type="submit"
                  variant="primary"
                  size="lg"
                  style="width: 100%;  font-size:16px"
                  @click="sendInvitation()"
                >{{buttontext}}</b-button>
              </div>
            </div>
          </b-form>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-xl2"
      ref="my-modal"
      centered
      title
      size="lg"
      body-class="pt-0"
      header-class="pb-0"
    >
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h2 class="semi-bold pl-3 pt-3">Invite a patient family</h2>
        <b-button size="lg" variant="outline" @click="close()">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <template v-slot:modal-footer="{}">
        <p></p>
      </template>
      <b-container fluid>
        <p
          class="my-4"
          style="font-size:14px"
        >Send an invitation code to the head of household to setup a family account. You will then be able to access records of all family members of this account.</p>

        <b-row class="mt-5 ml-1 mr-1 pt-3" style="background-color:#f4f4f4">
          <b-container>
            <b-row class="mt-2">
              <b-col cols="8">
                <p>
                  <b>Or import a list for batch invitation</b>
                </p>
                <p>1. File format must be in .csv format.</p>
                <p>
                  2. Each row must contain
                  <b>{email}</b>,
                  <b>{family name}</b>,
                  <b>{Family ID}</b>
                </p>
                <p>3. The three values in each row must be separated by a comma ","</p>
                <p>
                  4. Download a
                  <b-link href="#samplefile" style="color:#05c5ee">Sample File</b-link>
                </p>
              </b-col>
              <b-col>
                <b-form-file accept=".csv, .txt" v-model="file" class="mt-3" plain></b-form-file>
              </b-col>
            </b-row>
            <b-row class="mt-3 mb-4 justify-content-center">
              <b-col cols="auto">
                <b-button
                  class="blueButton"
                  type="submit"
                  variant="primary"
                  size="lg"
                  style="width: 100%;  font-size:16px"
                  @click="sendBatch()"
                >Upload and send batch invitations</b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-invite-main"
      centered
      title
      size="xl"
      body-class="pt-0"
      no-stacking
      header-class="pb-0"
    >
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h3
          class="semi-bold pl-3 pt-3"
        >邀请一个家庭</h3>
        <br />
        <b-button size="lg" variant="outline" @click="close()">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <template v-slot:modal-footer="{}">
        <p></p>
      </template>
      <b-container fluid>
        
        <b-row class="pl-3 pr-3 mt-2">
          <p style="font-size:12px; color:#393939; text-align:justify">
            生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。
          </p>
        </b-row>

        <b-row class="mt-5">
          <b-col>
            <b-row align-h="center">
              <b-img src="/imgs/patient@3x.png" width="64px" height="64px"></b-img>
            </b-row>
            <b-row align-h="center" class="mt-3">
              <h5>添加客人</h5>
            </b-row>
            <b-row align-h="center" class="mt-1">
              <b-button
                class="blueButton"
                type="submit"
                variant="primary"
                size="lg"
                style="font-size:16px"
                v-b-modal.modal-xl
              >邀请家庭</b-button>
            </b-row>
          </b-col>

          <b-col>
            <b-row align-h="center">
              <b-img src="/imgs/file@3x.png" width="64px" height="64px"></b-img>
            </b-row>
            <b-row align-h="center" class="mt-3">
              <h5>从csv文件导入</h5>
            </b-row>
            <b-row align-h="center" class="mt-1">
              <b-button
                class="blueButton"
                type="submit"
                variant="primary"
                size="lg"
                style="font-size:16px"
                v-b-modal.modal-xl2
              >批次邀请</b-button>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-row class="pt-5">
      <b-col>
        <h3 class="semi-bold mb-0">我的用户</h3>
        <p style="font-size:1rem; padding-top:5px">{{patientcount}} 个用户</p>
      </b-col>
      <b-col cols="auto">
        <div class="input-group mb-3">
          <b-form-input
            size="lg"
            type="text"
            class="form-control"
            placeholder="搜索关键字"
            style="background-color:#ced4da"
            v-model="filterinput"
            debounce="800"
          />
          <div class="input-group-append">
            <b-button
              variant="outline-info"
              class
              size="lg"
              style="background-color:#ced4da; border:1px solid #ced4da;"
              @click="onSearch"
            >
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="input-group-append">
            <b-button
              variant="outline-info"
              class
              size="lg"
              style="background-color:#05c5ee; border:1px solid #05c5ee; color:white; "
              v-b-modal.modal-invite-main
            >
              邀请客人
              <b-icon icon="plus" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3 pl-1 pr-1" align-h="start">
      <b-col cols="auto">
        <p class="semi-bold" style="font-size:14px">打分:</p>
      </b-col>
      <b-col cols="auto">
        <b-img src="/imgs/frequency@3x.png" width="20px" height="20px" style="margin-right:5px"></b-img>次数
      </b-col>
      <b-col cols="auto">
        <b-img src="/imgs/efficiency@3x.png" width="20px" height="20px" style="margin-right:5px"></b-img>效率
      </b-col>
      <b-col cols="auto">
        <b-img src="/imgs/completeness@3x.png" width="20px" height="20px" style="margin-right:5px"></b-img>覆盖
      </b-col>
      <b-col cols="auto">
        <b-img src="/imgs/duration@3x.png" width="20px" height="20px" style="margin-right:5px"></b-img>时长
      </b-col>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:black; border:2px solid black; color: white;"
          v-if="allpatients"
        >All patients</b-button>

        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:white; border:2px solid #e0e0e0; color:#e0e0e0; "
          v-if="!allpatients"
          @click="chooseAllPatients"
        >All patients</b-button>

        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:white; border:2px solid #ffd7d9; color:#ffd7d9; "
          v-if="!poorpatients"
          @click="choosePatientsPoor"
        >{{poorpatientscount}}</b-button>
        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:#c92a2a; border:2px solid #c92a2a; color:white; "
          v-if="poorpatients"
        >{{poorpatientscount}}</b-button>

        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:white; border:2px solid #ffec99; color:#ffec99 "
          v-if="!fairpatients"
          @click="choosePatientsFair"
        >{{fairpatientscount}}</b-button>

        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:#e67700; border:2px solid #e67700; color:white; "
          v-if="fairpatients"
        >{{fairpatientscount}}</b-button>

        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:white; border:2px solid #a7f0ba; color:#a7f0ba; "
          v-if="!greatpatients"
          @click="choosePatientsGreat"
        >{{greatpatientscount}}</b-button>

        <b-button
          pill
          class="legendbutton"
          size="lg"
          style="background-color:#2b8a3e; border:2px solid #2b8a3e; color:white; "
          v-if="greatpatients"
        >{{greatpatientscount}}</b-button>
      </b-col>

      <b-col cols="auto">
        <b-form-group id="input-group-3" label-for="input-3">
          <b-form-select
            size="lg"
            id="input-3"
            v-model="timewindow"
            :options="timewindows"
            style="border-radius:30px; border:2px solid #05c5ee; width:150px"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3 pl-4 pr-4">
      <b-table
        id="my-table"
        ref="tableref"
        :fields="fields"
        :items="profilelist"
        hover
        style="background-color: white;"
        sort-icon-left
        tbody-tr-class="table-row"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-compare="customSort"
        :busy="isBusy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        @filtered="onFiltered"
        :filterIncludedFields="filterOn"
        @row-clicked="(item, index, event) => onClickRow(item)"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2" style="color:#05c5ee">
            <b-spinner class="align-middle"></b-spinner>
            <strong>&nbsp;Loading...</strong>
          </div>
        </template>

        <!-- A custom formatted data column cell -->
        <template v-slot:cell(composedname)="data">
          <span v-html="getNameHTML(data.item)"></span>
        </template>

        <template v-slot:cell(lastactivitytime)="data">
          <span v-html="getLastActivityHTML(data.value)"></span>
        </template>

        <template v-slot:cell(score)="data">
          <span v-html="getScoreHTML(data.item)"></span>
        </template>
        <template v-slot:cell(iconlabels)="data">
          <span v-html="getProblemAreas(data.item)"></span>
        </template>

        <template v-slot:cell(userid)="data">
          <b-button
            variant="outline-primary"
            class
            size="md"
            style="background-color:transparent; border:0px solid #05c5ee; color:#05c5ee;"
            @click="onClickRow(data.item)"
          >
            <span v-html="data.item.userid"></span>
          </b-button>
        </template>
      </b-table>
    </b-row>
    <b-row class="pl-4 pr-4 mt-2" style="float:right">
      <b-pagination
        v-model="currentPage"
        :total-rows="patientcount"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </b-row>

    <b-row v-if="patientcount == 0" style="display:none">
      <b-container
        style="background-color: white; margin-left:0; margin-right:0; width:100%; max-width: 100%"
      >
        <b-row align-h="center" class="mt-3">
          <b-icon-envelope-open style="width:60px;height:60px;color: #05c5ee;"></b-icon-envelope-open>
        </b-row>

        <b-row align-h="center" class="mt-4">
          <h3 class="semi-bold mb-0">Invite Patients</h3>
        </b-row>
        <b-row align-h="center" class="mt-2">No patient data</b-row>

        <b-row class="mt-4" align-h="center">
          <b-button
            class="blueButton"
            variant="primary"
            size="lg"
            style="font-size:16px;"
            v-b-modal.modal-invite-main
          >Invite Patient</b-button>
        </b-row>

        <b-row class="mt-4" align-h="center">
          <p
            style="text-align:center; width:70%"
          >Tips: Generate an invitation code and send it to patients you want to invite. Instruct the patients to download Truthbrush app to complete the redemption process. This can be done quickly and easily while patients are visiting your office.</p>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import { getProfileList } from "../api/profile";
import { sendInvitation } from "../api/invitation.js";
import storage from "./../storage";
export default {
  name: "my-patients",
  data() {
    return {
      patientcount: 0,
      allpatients: true,
      poorpatients: false,
      fairpatients: false,
      greatpatients: false,
      poorpatientscount: 0,
      fairpatientscount: 0,
      greatpatientscount: 0,
      timewindow: "最近4周",
      timewindows: ["最近4周", "最近2周", "最近7天"],
      profilelist: [],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      sortBy: "score",
      sortDesc: true,
      filter: "",
      filterinput: "",
      threshold: 0.8,
      filterOn: ["userid", "firstname", "externallastname"],

      //for invitation button
      form: {
        email: "",
        lastname: "",
        id: ""
      },
      file: null,
      selected: 0,
      buttontext: "生成邀请码",

      fields: [
        {
          key: "composedname",
          label: "用户",
          sortable: true,
          thStyle: "font-size:14px"
        },

        {
          key: "userid",
          label: "爱牙精灵账户",
          sortable: true,
          thStyle: "font-size:14px"
        },
        /*
        {
          key: "lastactivitytime",
          label: "Last Activity",
          sortable: true,
          thStyle: "font-size:14px"
        },
        */
        {
          key: "score",
          label: "分数",
          sortable: true,
          thStyle: "font-size:14px"
        },
        {
          key: "iconlabels",
          label: "刷牙问题",
          sortable: false,
          thStyle: "font-size:14px"
        }
        /*
        {
          key: "rowbutton",
          label: "Profile Info",
          sortable: false,
          thStyle: "font-size:14px"
        }
        */
      ]
    };
  },
  watch: {
    parentready: function() {
      this.isBusy = true;
      if (this.parentready === true) {
        getProfileList(storage.getProvider()).then(res => {
          this.$store.state.profiles = res.data;
          this.profilelist = res.data;

          this.isBusy = false;
          this.patientcount = this.profilelist.length;
          this.updateCount();
        });
      }
    },
    selected: function() {
      if (this.selected == 0) {
        this.buttontext = "生成邀请码";
      } else {
        this.buttontext = "生成邀请码并发出";
      }
    },
    filterinput: function() {
      this.isBusy = true;
      let context = this;
      setTimeout(function() {
        context.filter = context.filterinput;
      }, 100);
    },
    timewindow: function() {
      this.isBusy = true;
      let context = this;
      this.$refs.tableref.refresh();
      setTimeout(function() {
        context.isBusy = false;
      }, 1000);
    }
  },
  props: {
    parentready: Boolean
  },
  computed: {
    emailTemplate() {
      return "Date\n\nDear Patient, (BEST TO USE PATIENT NAME)\n\n[DENTAL PRACTICE NAME] considers it a privilege to provide you and your family with the highest standard of dental care and we are always looking for ways to better serve you. We would like to take this opportunity to share with you an exciting new technology that enhances our ability to assist you and your child with developing more effective brushing habits in between visits. This innovative tool helps families track and improve daily brushing habits. We provide you with a “Truthbrush Tracker” the size of a button that fits comfortably on any toothbrush.  The tracker  transmits brushing data to you and our office. The data includes the frequency, duration, and thoroughness of brushing events and provides easy to understand analytics on an app that you download. The data that is collected over time provides parents and our office an invaluable tool which can be used to encourage behavior change in a positive way.\n\nAs dentists, we know that good brushing habits are an important key to prevention of periodontal disease and  tooth decay.  Sadly, these diseases  impact half of all children in the U.S., according to the latest figures by the Centers for Disease Control. Now, with the help of Truthbrush Tracker, we can for the first time monitor your child’s brushing in between visits and provide you with real time relevant feedback and strategies we know help with good habit building. This technology offers us a new way to work together to optimize your child’s brushing habits.  To take advantage of the Trushbrush tracker simply reply to this email and let us know the number of family members that you want to have trackers. We will send trackers and instructions to setup. As always, we thank you for entrusting us with your dental care!\n\nSincerely, [DENTIST NAME]";
    }
  },
  methods: {
    copyText() {
      document.getElementById("textarea-large").select();
      document.execCommand("copy");
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    },
    onSubmit() {},
    updateCount() {
      console.log('update')
      if (this.profilelist == null) {
        this.poorpatientscount = 0;
        this.fairpatientscount = 0;
        this.greatpatientscount = 0;
      }
      var countpoor = 0;
      var countgreat = 0;
      var countfair = 0;

      for (var i = 0; i < this.profilelist.length; i++) {
        var ranking = this.profilelist[i].info;
        if (ranking == null) continue;
        var obj = JSON.parse(ranking);
        //console.log(this.profilelist[i].userid+" "+ranking)
        if (obj != null && obj.ranking != null) {
          if (obj.ranking < 0.5) {
            countpoor++;
          } else if (obj.ranking >= 0.5 && obj.ranking <= 0.8) {
            countfair++;
          } else {
            countgreat++;
          }
        }
      }
      this.poorpatientscount = countpoor;
      this.fairpatientscount = countfair;
      this.greatpatientscount = countgreat;
    },
    sendInvitation() {
      let m_invitation = {};
      m_invitation.externalid = this.form.id;
      m_invitation.lastname = this.form.lastname;
      m_invitation.email = this.form.email.toLowerCase();
      m_invitation.status = "PENDING";
      m_invitation.providerid = storage.getProvider().providerid;

      if (!this.form.lastname || !this.form.email) {
        return;
      }

      let context = this;
      let loader = this.$loading.show();
      sendInvitation(m_invitation, this.selected == 1)
        .then(function() {
          context.$refs["my-modal"].hide();
          loader.hide();
        })
        .catch(function() {
          loader.hide();
        });
    },
    chooseAllPatients() {
      this.allpatients = true;
      this.poorpatients = false;
      this.fairpatients = false;
      this.greatpatients = false;
      this.profilelist = this.$store.state.profiles;
      this.patientcount = this.profilelist.length;
    },
    choosePatientsPoor() {
      this.allpatients = false;
      this.poorpatients = true;
      this.fairpatients = false;
      this.greatpatients = false;
      this.profilelist = this.$store.state.profiles.filter(function(row) {
        var ranking = row.info;
        if (ranking == null) return true;
        var obj = JSON.parse(ranking);
        if (obj != null && obj.ranking != null) {
          if (obj.ranking < 0.5) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      this.patientcount = this.profilelist.length;
    },
    choosePatientsFair() {
      this.allpatients = false;
      this.poorpatients = false;
      this.fairpatients = true;
      this.greatpatients = false;
      this.profilelist = this.$store.state.profiles.filter(function(row) {
        var ranking = row.info;
        if (ranking == null) return false;
        var obj = JSON.parse(ranking);
        if (obj != null && obj.ranking != null) {
          if (obj.ranking >= 0.5 && obj.ranking <= 0.8) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      this.patientcount = this.profilelist.length;
    },
    choosePatientsGreat() {
      this.allpatients = false;
      this.poorpatients = false;
      this.fairpatients = false;
      this.greatpatients = true;

      this.profilelist = this.$store.state.profiles.filter(function(row) {
        var ranking = row.info;
        if (ranking == null) return false;
        var obj = JSON.parse(ranking);
        if (obj != null && obj.ranking != null) {
          if (obj.ranking > 0.8) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      this.patientcount = this.profilelist.length;
    },
    onClickRow(row) {
      console.log("clicked:" + row.userid);
      this.$store.state.selectedprofile = row;
      window.location.href = "/#/singlepatient";
    },
    onSearch: function() {
      if (this.filter == this.filterinput) {
        return;
      }
      console.log("start filter");

      this.isBusy = true;
      let context = this;
      setTimeout(function() {
        context.filter = context.filterinput;
      }, 100);

      console.log("start filter2");
    },
    onFiltered() {
      console.log("filtered");
      this.isBusy = false;
    },
    customSort(a, b, index, isDesc) {
      if (index == "score") {
        let x = this.getScore(a);
        let y = this.getScore(b);

        if (isDesc) {
          return x < y ? -1 : 1;
        } else {
          return x >= y ? 1 : -1;
        }
      } else if (index == "composedname") {
        let x = this.getNameHTML(a);
        let y = this.getNameHTML(b);

        if (isDesc) {
          return x < y ? -1 : 1;
        } else {
          return x >= y ? 1 : -1;
        }
      } else {
        if (isDesc) {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? 1 : -1;
        }
      }
    },
    getNameHTML(row) {
      var lastname = row.externallastname;
      var firstname = row.externalfirstname;
      if (firstname == null || firstname == "") {
        firstname = row.firstname;
      }
      var name = "";
      if (lastname && firstname) {
        name = lastname + ", " + firstname;
      } else if (lastname) {
        name = lastname + ", ";
      } else if (firstname) {
        name = firstname;
      }
      if (name == null || name == "") {
        return "<p style='color:#c6c6c6'>N/A</p>";
      } else {
        return name;
      }
    },
    visitPatient(profileid) {
      var profile = null;
      for (
        var i = 0;
        this.$store.state.profiles != null &&
        i < this.$store.state.profiles.length;
        i++
      ) {
        if (this.$store.state.profiles[i].profileid == profileid) {
          profile = this.$store.state.profiles[i];
        }
      }
      if (profile != null) {
        this.$store.state.selectedprofile = profile;
        window.location.href = "/#/singlepatient";
      }
    },
    getUserIDHTML(row) {
      return (
        "<a href='javascript:visitPatient(\"" +
        row.profileid +
        "\")' style='color:#05c5ee' class='semi-bold'>" +
        row.userid +
        "</a>"
      );
    },
    getLastActivityHTML(timestamp) {
      var date = new Date(timestamp);
      return date.toLocaleString();
    },
    getScore(item) {
      var info = item.info;
      if (info == null) return 0;
      var obj = JSON.parse(info);
      return (parseFloat(obj.ranking) * 100).toFixed(0);
    },
    getScoreHTML(item) {
      var score = 0;
       var info = item.info;
      if (info != null){
        var obj = JSON.parse(info);
        score = parseFloat(obj.ranking);
      }
      if(score< 0.5){
        return "<div style='font-size:20px; color:#fc5a5a'>"+(score*100).toFixed(0)+"</div>";
      }else if(score< 0.8){
        return "<div style='font-size:20px; color:#e6841e'>"+(score*100).toFixed(0)+"</div>";
      }else{
        return "<div style='font-size:20px; color:#3dd598'>"+(score*100).toFixed(0)+"</div>";
      }
    },
    getProblemAreas(row) {
      var obj = {
        countscore: 0,
        durationscore: 0,
        quadrantscore: 0,
        efficiencyscore: 0
      };

      if (row.info != null) {
        obj = JSON.parse(row.info);
      }

      let str = "<b-row>";
      if (obj.countscore < 0.6) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-frequency-alert@2x.png" width="32px" height="32px"></img>';
      }else  if (obj.countscore < 0.85) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-frequency-fair@2x.png" width="32px" height="32px"></img>';
      }else{
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-frequency-great@2x.png" width="32px" height="32px"></img>';
      }

      if (obj.durationscore < 0.5) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-duration-alert@2x.png" width="32px" height="32px"></img>';
      }else  if (obj.durationscore < 0.75) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-duration-fair@2x.png" width="32px" height="32px"></img>';
      }else{
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-duration-great@2x.png" width="32px" height="32px"></img>';
      }

      if (obj.quadrantscore <= 0.51) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-completeness-alert@2x.png" width="32px" height="32px"></img>';
      }else if (obj.quadrantscore < 0.8) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-completeness-fair@2x.png" width="32px" height="32px"></img>';
      }else{
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-completeness-great@2x.png" width="32px" height="32px"></img>';
      }

      if (obj.efficiencyscore < 0.4) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-efficiency-alert@2x.png" width="32px" height="32px"></img>';
      }else if (obj.efficiencyscore < 0.7) {
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-efficiency-fair@2x.png" width="32px" height="32px"></img>';
      }else{
        str +=
          '<img class="pr-1" center src="/imgs/icons-problem-efficiency-great@2x.png" width="32px" height="32px"></img>';
      }

      return str + "</b-row>";
    },
    getWarningIcon(obj) {
      let warning = 0;
      if (obj.countscore < this.threshold) {
        warning++;
      } else if (obj.durationscore < this.threshold) {
        warning++;
      } else if (obj.quadrantscore < this.threshold) {
        warning++;
      } else if (obj.efficiencyscore < this.threshold) {
        warning++;
      }
      if (warning > 0) {
        return true;
      }
      return false;
    }
  },
  mounted: function() {
    console.log("mypatient mounted:"+new Date().getTime());
    var provider = storage.getProvider();
    if (provider != null) {
      this.isBusy = true;
      getProfileList(provider).then(res => {
        console.log("get profile return:"+new Date().getTime());
        this.$store.state.profiles = res.data;
        this.profilelist = res.data;
        this.isBusy = false;
        this.patientcount = this.profilelist.length;
        this.updateCount();

        let category = this.$route.query.category;
        console.log('c:'+category);
        if(category == 'poor'){
          this.choosePatientsPoor();
        }else if(category == 'fair'){
          this.choosePatientsFair();
        }else if(category == 'good'){
          this.choosePatientsGreat();
        }
      });
    }
  }
};
</script>

<style>
.table-row {
  font-size: 14px !important;
  border-top-color: #05c5ee;
  border-bottom-color: #05c5ee;
  color: #393939;
}
.page-item.active .page-link {
  background-color: #05c5ee !important;
  border-color: #05c5ee !important;
  color: white !important;
}

.page-link {
  color: #05c5ee !important;
}

.legendbutton {
  margin-left: 5px;
  margin-right: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>