var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticStyle: { "background-color": "#f3f3f3", height: "100%" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 pt-3" },
        [
          _c("b-breadcrumb", {
            staticStyle: { color: "#05c5ee" },
            attrs: { items: _vm.breadcrumbitems() }
          })
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 pt-0", attrs: { "align-v": "center" } },
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("div", {
              staticClass: "circle-avatar profileicon",
              style: {
                "background-image": "url(" + _vm.getprofileimageurl() + ")"
              }
            })
          ]),
          _c("b-col", { attrs: { cols: "auto pl-0" } }, [
            _c(
              "h2",
              { staticClass: "bold", staticStyle: { "margin-bottom": "0" } },
              [_vm._v(_vm._s(_vm.getDisplayName()))]
            )
          ]),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticStyle: { "border-color": "#05c5ee" },
                  attrs: {
                    size: "lg",
                    variant: "outline-info",
                    href: "/#/singlepatientedit"
                  }
                },
                [_vm._v("配置用户")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-2 pr-2 pt-5 ml-0" },
        [
          _c(
            "b-col",
            { staticStyle: { "min-width": "250px" }, attrs: { cols: "3" } },
            [
              _c(
                "b-row",
                { staticClass: "calendar-panel" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pl-0 pr-0", attrs: { cols: "12" } },
                    [
                      _c("b-calendar", {
                        attrs: {
                          block: "",
                          locale: "en-US",
                          "date-format-options": {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                            weekday: "short"
                          },
                          "today-variant": "info",
                          "date-info-fn": _vm.dateClass,
                          "date-disabled-fn": _vm.dateDisabled,
                          "hide-header": "",
                          "label-help": ""
                        },
                        on: { context: _vm.onContext },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        {
                          staticClass: "pb-3 pl-3 pt-3",
                          attrs: { "align-h": "center" }
                        },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "legendbox",
                              staticStyle: {
                                color: "#e0797d",
                                "background-color": "#e0797d"
                              }
                            },
                            [_vm._v("o")]
                          ),
                          _vm._v("None "),
                          _c(
                            "b-badge",
                            {
                              staticClass: "legendbox",
                              staticStyle: {
                                color: "rgb(255,193,7)",
                                "background-color": "rgb(255,193,7)",
                                "margin-left": "10px"
                              }
                            },
                            [_vm._v("o")]
                          ),
                          _vm._v("Low "),
                          _c(
                            "b-badge",
                            {
                              staticClass: "legendbox",
                              staticStyle: {
                                color: "#d5f2f8",
                                "background-color": "#d5f2f8",
                                "margin-left": "10px"
                              }
                            },
                            [_vm._v("o")]
                          ),
                          _vm._v("Today "),
                          _c(
                            "b-badge",
                            {
                              staticClass: "legendbox",
                              staticStyle: {
                                color: "#0062cc",
                                "background-color": "#0062cc",
                                "margin-left": "10px"
                              }
                            },
                            [_vm._v("o")]
                          ),
                          _vm._v("Selected ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-row", { staticClass: "mt-5" }, [
                _c("h3", [_c("b", [_vm._v("事件")])])
              ]),
              _vm.dailyactivitylist.length > 0
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("b-table", {
                        staticStyle: { "background-color": "white" },
                        attrs: {
                          id: "daily-activity-table",
                          fields: _vm.fields,
                          items: _vm.dailyactivitylist,
                          "tbody-tr-class": _vm.rowClass,
                          hover: "",
                          "sort-icon-left": "",
                          "thead-class": "hidden_header"
                        },
                        on: {
                          "row-clicked": function(item, index, event) {
                            return _vm.onClickRow(item)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "table-busy",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center my-2",
                                      staticStyle: { color: "#05c5ee" }
                                    },
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle"
                                      }),
                                      _c("strong", [_vm._v(" Loading...")])
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(createdtime)",
                              fn: function() {
                                return [
                                  _c("span", { staticClass: "text-info" }, [
                                    _vm._v("Activities on " + _vm._s(_vm.value))
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "cell(createdtime)",
                              fn: function(data) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getActivityTimeName(data.item)
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2832166814
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dailyactivitylist.length == 0
                ? _c(
                    "b-row",
                    { staticClass: "mt-5", attrs: { "align-h": "center" } },
                    [_vm._v(" No activity was recorded on the selected date. ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "metrictab",
                  attrs: { "content-class": "mt-0" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex"
                  }
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "刷牙质量",
                        "title-link-class": _vm.tabClass(),
                        active: ""
                      }
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "ml-0 mr-0 pt-0" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pt-4 pb-5",
                              staticStyle: { "background-color": "white" },
                              attrs: { cols: "6" }
                            },
                            [
                              _c("b-row", { staticClass: "pl-5" }, [
                                _c("h5", [_c("b", [_vm._v("时间")])])
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "mb-2 mt-4 ml-5 mr-5" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0 pr-0 pb-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "-15px",
                                            left: "0",
                                            "padding-left": "0%",
                                            color: "#05c5ee"
                                          },
                                          attrs: { id: "startmarker" }
                                        },
                                        [_vm._v("Start")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "-15px",
                                            right: "0",
                                            "padding-right": "0%",
                                            color: "#05c5ee"
                                          },
                                          attrs: { id: "endmarker" }
                                        },
                                        [_vm._v("End")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0 pr-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "b-progress",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            height: "15px"
                                          },
                                          attrs: { max: _vm.max }
                                        },
                                        [
                                          _c("b-progress-bar", {
                                            staticStyle: {
                                              "background-color": "#efefef"
                                            },
                                            attrs: { value: _vm.getProgress(0) }
                                          }),
                                          _c("b-progress-bar", {
                                            staticStyle: {
                                              "background-color": "#2ebfde"
                                            },
                                            attrs: { value: _vm.getProgress(1) }
                                          }),
                                          _c("b-progress-bar", {
                                            staticStyle: {
                                              "background-color": "#efefef"
                                            },
                                            attrs: { value: _vm.getProgress(2) }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0 pr-0 pt-1",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "#6f6f6f" } },
                                        [_vm._v("开始")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0 pr-0 pt-1",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#6f6f6f",
                                            float: "right"
                                          }
                                        },
                                        [_vm._v("结束")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "mb-2 mt-4 ml-5 mr-5 pb-4" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstyle",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v(_vm._s(_vm.getData(0)) + "s")]
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstylesm",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v("运动时间")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstyle",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v(_vm._s(_vm.getData(1)) + "s")]
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstylesm",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v("刷牙时间")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstyle",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v(_vm._s(_vm.getData(2)) + "%")]
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstylesm",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v("动作效率")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "pl-5 pt-4",
                                  staticStyle: {
                                    "border-top": "1px solid #f2f2f2"
                                  }
                                },
                                [_c("h5", [_c("b", [_vm._v("技术动作")])])]
                              ),
                              _c(
                                "b-row",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { "align-h": "center" }
                                },
                                [
                                  _c("canvas", {
                                    staticStyle: {
                                      "max-width": "480px",
                                      "max-height": "240px"
                                    },
                                    attrs: { id: "doughnutChart" }
                                  })
                                ]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "mb-2 mt-4 ml-5 mr-5" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstyle",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v(_vm._s(_vm.getData(3)) + "%")]
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstylesm",
                                          attrs: { "align-h": "center" }
                                        },
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "legendbox",
                                              staticStyle: {
                                                color: "#13aabf",
                                                "background-color": "#13aabf"
                                              }
                                            },
                                            [_vm._v("o")]
                                          ),
                                          _vm._v("转圈 ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstyle",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v(_vm._s(_vm.getData(4)) + "%")]
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstylesm",
                                          attrs: { "align-h": "center" }
                                        },
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "legendbox",
                                              staticStyle: {
                                                color: "#22c998",
                                                "background-color": "#22c998"
                                              }
                                            },
                                            [_vm._v("o")]
                                          ),
                                          _vm._v("左右 ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstyle",
                                          attrs: { "align-h": "center" }
                                        },
                                        [_vm._v(_vm._s(_vm.getData(5)) + "%")]
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "metricsstylesm",
                                          attrs: { "align-h": "center" }
                                        },
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "legendbox",
                                              staticStyle: {
                                                color: "#50d066",
                                                "background-color": "#50d066"
                                              }
                                            },
                                            [_vm._v("o")]
                                          ),
                                          _vm._v("上下 ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pt-4 pb-5",
                              staticStyle: {
                                "background-color": "white",
                                "border-left": "1px solid #f2f2f2"
                              },
                              attrs: { cols: "6" }
                            },
                            [
                              _c("b-row", { staticClass: "pl-5 pb-3" }, [
                                _c("h5", [_c("b", [_vm._v("刷牙不足位置")])])
                              ]),
                              _c("b-row", {
                                staticStyle: { "min-height": "400px" },
                                attrs: { id: "pb2" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "运动数据",
                        "title-link-class": _vm.tabClass()
                      }
                    },
                    [
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.loader2, rounded: "lg" } },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "ml-0 mr-0 pb-5 mb-5",
                              staticStyle: { background: "white" }
                            },
                            [
                              _c(
                                "b-col",
                                { staticClass: "pl-0 pr-0" },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0 mr-0 pl-5 pt-5" },
                                    [_c("h5", [_c("b", [_vm._v("低频数据")])])]
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0 mr-0 pl-5 pt-4 pr-5" },
                                    [
                                      _c("b-col", [
                                        _c("canvas", {
                                          attrs: { id: "movementchart" }
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0 mr-0 pl-5 pt-5" },
                                    [_c("h5", [_c("b", [_vm._v("高频数据")])])]
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-0 mr-0 pl-5 pt-4 pr-5" },
                                    [
                                      _c("b-col", [
                                        _c("canvas", {
                                          attrs: { id: "highfrequencychart" }
                                        })
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }