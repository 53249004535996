var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticStyle: { "background-color": "#f3f3f3", height: "100%" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            id: "modal-xl",
            centered: "",
            title: "",
            size: "lg",
            "body-class": "pt-0",
            "header-class": "pb-0"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("h2", { staticClass: "semi-bold pl-3 pt-3" }, [
                    _vm._v("邀请病人")
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "lg", variant: "outline" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x", "aria-hidden": "true" }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "modal-footer",
              fn: function(ref) {
                return [_c("p")]
              }
            }
          ])
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "p",
                { staticClass: "my-4", staticStyle: { "font-size": "14px" } },
                [
                  _vm._v(
                    "生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。"
                  )
                ]
              ),
              _c(
                "b-row",
                { staticClass: "pl-3 pr-3" },
                [
                  _c(
                    "b-form",
                    {
                      staticStyle: { width: "100%" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmit($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row d-flex mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "手机号 *",
                                  "label-class": "form-label",
                                  "label-for": "input-1",
                                  description: "11位手机号"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "number", required: "" },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "姓 *",
                                  "label-class": "form-label",
                                  "label-for": "input-1",
                                  description: "家庭主要成员的姓"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.lastname,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "lastname", $$v)
                                    },
                                    expression: "form.lastname"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "用户识别码 (可选)",
                                  "label-class": "form-label",
                                  "label-for": "input-1",
                                  description:
                                    "识别码为可选。帮助您关联用户在康迪贝尔和您其他系统中的相互关系"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.form.id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "id", $$v)
                                    },
                                    expression: "form.id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "pl-3 pr-3" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  attrs: {
                                    name: "some-radios",
                                    value: "0",
                                    selected: ""
                                  },
                                  model: {
                                    value: _vm.selected,
                                    callback: function($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected"
                                  }
                                },
                                [_vm._v("生成邀请码，不发出短信")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  attrs: { name: "some-radios", value: "1" },
                                  model: {
                                    value: _vm.selected,
                                    callback: function($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected"
                                  }
                                },
                                [_vm._v("生成邀请码，并且短信通知用户")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row d-flex mt-2 justify-content-center"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "blueButton",
                                  staticStyle: {
                                    width: "100%",
                                    "font-size": "16px"
                                  },
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "lg"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendInvitation()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.buttontext))]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            id: "modal-xl2",
            centered: "",
            title: "",
            size: "lg",
            "body-class": "pt-0",
            "header-class": "pb-0"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("h2", { staticClass: "semi-bold pl-3 pt-3" }, [
                    _vm._v("Invite a patient family")
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "lg", variant: "outline" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x", "aria-hidden": "true" }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "modal-footer",
              fn: function(ref) {
                return [_c("p")]
              }
            }
          ])
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "p",
                { staticClass: "my-4", staticStyle: { "font-size": "14px" } },
                [
                  _vm._v(
                    "Send an invitation code to the head of household to setup a family account. You will then be able to access records of all family members of this account."
                  )
                ]
              ),
              _c(
                "b-row",
                {
                  staticClass: "mt-5 ml-1 mr-1 pt-3",
                  staticStyle: { "background-color": "#f4f4f4" }
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c("b-col", { attrs: { cols: "8" } }, [
                            _c("p", [
                              _c("b", [
                                _vm._v("Or import a list for batch invitation")
                              ])
                            ]),
                            _c("p", [
                              _vm._v("1. File format must be in .csv format.")
                            ]),
                            _c("p", [
                              _vm._v(" 2. Each row must contain "),
                              _c("b", [_vm._v("{email}")]),
                              _vm._v(", "),
                              _c("b", [_vm._v("{family name}")]),
                              _vm._v(", "),
                              _c("b", [_vm._v("{Family ID}")])
                            ]),
                            _c("p", [
                              _vm._v(
                                '3. The three values in each row must be separated by a comma ","'
                              )
                            ]),
                            _c(
                              "p",
                              [
                                _vm._v(" 4. Download a "),
                                _c(
                                  "b-link",
                                  {
                                    staticStyle: { color: "#05c5ee" },
                                    attrs: { href: "#samplefile" }
                                  },
                                  [_vm._v("Sample File")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "b-col",
                            [
                              _c("b-form-file", {
                                staticClass: "mt-3",
                                attrs: { accept: ".csv, .txt", plain: "" },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3 mb-4 justify-content-center" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "blueButton",
                                  staticStyle: {
                                    width: "100%",
                                    "font-size": "16px"
                                  },
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "lg"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendBatch()
                                    }
                                  }
                                },
                                [_vm._v("Upload and send batch invitations")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-invite-main",
            centered: "",
            title: "",
            size: "xl",
            "body-class": "pt-0",
            "no-stacking": "",
            "header-class": "pb-0"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("h3", { staticClass: "semi-bold pl-3 pt-3" }, [
                    _vm._v("邀请一个家庭")
                  ]),
                  _c("br"),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "lg", variant: "outline" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x", "aria-hidden": "true" }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "modal-footer",
              fn: function(ref) {
                return [_c("p")]
              }
            }
          ])
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c("b-row", { staticClass: "pl-3 pr-3 mt-2" }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "12px",
                      color: "#393939",
                      "text-align": "justify"
                    }
                  },
                  [
                    _vm._v(
                      " 生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。 "
                    )
                  ]
                )
              ]),
              _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/imgs/patient@3x.png",
                              width: "64px",
                              height: "64px"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3", attrs: { "align-h": "center" } },
                        [_c("h5", [_vm._v("添加客人")])]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1", attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-xl",
                                  modifiers: { "modal-xl": true }
                                }
                              ],
                              staticClass: "blueButton",
                              staticStyle: { "font-size": "16px" },
                              attrs: {
                                type: "submit",
                                variant: "primary",
                                size: "lg"
                              }
                            },
                            [_vm._v("邀请家庭")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/imgs/file@3x.png",
                              width: "64px",
                              height: "64px"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3", attrs: { "align-h": "center" } },
                        [_c("h5", [_vm._v("从csv文件导入")])]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1", attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-xl2",
                                  modifiers: { "modal-xl2": true }
                                }
                              ],
                              staticClass: "blueButton",
                              staticStyle: { "font-size": "16px" },
                              attrs: {
                                type: "submit",
                                variant: "primary",
                                size: "lg"
                              }
                            },
                            [_vm._v("批次邀请")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-5" },
        [
          _c("b-col", [
            _c("h3", { staticClass: "semi-bold mb-0" }, [_vm._v("我的用户")]),
            _c(
              "p",
              { staticStyle: { "font-size": "1rem", "padding-top": "5px" } },
              [_vm._v(_vm._s(_vm.patientcount) + " 个用户")]
            )
          ]),
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c(
              "div",
              { staticClass: "input-group mb-3" },
              [
                _c("b-form-input", {
                  staticClass: "form-control",
                  staticStyle: { "background-color": "#ced4da" },
                  attrs: {
                    size: "lg",
                    type: "text",
                    placeholder: "搜索关键字",
                    debounce: "800"
                  },
                  model: {
                    value: _vm.filterinput,
                    callback: function($$v) {
                      _vm.filterinput = $$v
                    },
                    expression: "filterinput"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "input-group-append" },
                  [
                    _c(
                      "b-button",
                      {
                        staticStyle: {
                          "background-color": "#ced4da",
                          border: "1px solid #ced4da"
                        },
                        attrs: { variant: "outline-info", size: "lg" },
                        on: { click: _vm.onSearch }
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "search", "aria-hidden": "true" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "input-group-append" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-invite-main",
                            modifiers: { "modal-invite-main": true }
                          }
                        ],
                        staticStyle: {
                          "background-color": "#05c5ee",
                          border: "1px solid #05c5ee",
                          color: "white"
                        },
                        attrs: { variant: "outline-info", size: "lg" }
                      },
                      [
                        _vm._v(" 邀请客人 "),
                        _c("b-icon", {
                          attrs: { icon: "plus", "aria-hidden": "true" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3 pl-1 pr-1", attrs: { "align-h": "start" } },
        [
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c(
              "p",
              {
                staticClass: "semi-bold",
                staticStyle: { "font-size": "14px" }
              },
              [_vm._v("打分:")]
            )
          ]),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c("b-img", {
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  src: "/imgs/frequency@3x.png",
                  width: "20px",
                  height: "20px"
                }
              }),
              _vm._v("次数 ")
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c("b-img", {
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  src: "/imgs/efficiency@3x.png",
                  width: "20px",
                  height: "20px"
                }
              }),
              _vm._v("效率 ")
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c("b-img", {
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  src: "/imgs/completeness@3x.png",
                  width: "20px",
                  height: "20px"
                }
              }),
              _vm._v("覆盖 ")
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c("b-img", {
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  src: "/imgs/duration@3x.png",
                  width: "20px",
                  height: "20px"
                }
              }),
              _vm._v("时长 ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-3" },
        [
          _c(
            "b-col",
            [
              _vm.allpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "black",
                        border: "2px solid black",
                        color: "white"
                      },
                      attrs: { pill: "", size: "lg" }
                    },
                    [_vm._v("All patients")]
                  )
                : _vm._e(),
              !_vm.allpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "white",
                        border: "2px solid #e0e0e0",
                        color: "#e0e0e0"
                      },
                      attrs: { pill: "", size: "lg" },
                      on: { click: _vm.chooseAllPatients }
                    },
                    [_vm._v("All patients")]
                  )
                : _vm._e(),
              !_vm.poorpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "white",
                        border: "2px solid #ffd7d9",
                        color: "#ffd7d9"
                      },
                      attrs: { pill: "", size: "lg" },
                      on: { click: _vm.choosePatientsPoor }
                    },
                    [_vm._v(_vm._s(_vm.poorpatientscount))]
                  )
                : _vm._e(),
              _vm.poorpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "#c92a2a",
                        border: "2px solid #c92a2a",
                        color: "white"
                      },
                      attrs: { pill: "", size: "lg" }
                    },
                    [_vm._v(_vm._s(_vm.poorpatientscount))]
                  )
                : _vm._e(),
              !_vm.fairpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "white",
                        border: "2px solid #ffec99",
                        color: "#ffec99"
                      },
                      attrs: { pill: "", size: "lg" },
                      on: { click: _vm.choosePatientsFair }
                    },
                    [_vm._v(_vm._s(_vm.fairpatientscount))]
                  )
                : _vm._e(),
              _vm.fairpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "#e67700",
                        border: "2px solid #e67700",
                        color: "white"
                      },
                      attrs: { pill: "", size: "lg" }
                    },
                    [_vm._v(_vm._s(_vm.fairpatientscount))]
                  )
                : _vm._e(),
              !_vm.greatpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "white",
                        border: "2px solid #a7f0ba",
                        color: "#a7f0ba"
                      },
                      attrs: { pill: "", size: "lg" },
                      on: { click: _vm.choosePatientsGreat }
                    },
                    [_vm._v(_vm._s(_vm.greatpatientscount))]
                  )
                : _vm._e(),
              _vm.greatpatients
                ? _c(
                    "b-button",
                    {
                      staticClass: "legendbutton",
                      staticStyle: {
                        "background-color": "#2b8a3e",
                        border: "2px solid #2b8a3e",
                        color: "white"
                      },
                      attrs: { pill: "", size: "lg" }
                    },
                    [_vm._v(_vm._s(_vm.greatpatientscount))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "b-form-group",
                { attrs: { id: "input-group-3", "label-for": "input-3" } },
                [
                  _c("b-form-select", {
                    staticStyle: {
                      "border-radius": "30px",
                      border: "2px solid #05c5ee",
                      width: "150px"
                    },
                    attrs: {
                      size: "lg",
                      id: "input-3",
                      options: _vm.timewindows
                    },
                    model: {
                      value: _vm.timewindow,
                      callback: function($$v) {
                        _vm.timewindow = $$v
                      },
                      expression: "timewindow"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3 pl-4 pr-4" },
        [
          _c("b-table", {
            ref: "tableref",
            staticStyle: { "background-color": "white" },
            attrs: {
              id: "my-table",
              fields: _vm.fields,
              items: _vm.profilelist,
              hover: "",
              "sort-icon-left": "",
              "tbody-tr-class": "table-row",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "sort-compare": _vm.customSort,
              busy: _vm.isBusy,
              "per-page": _vm.perPage,
              "current-page": _vm.currentPage,
              filter: _vm.filter,
              filterIncludedFields: _vm.filterOn
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              },
              filtered: _vm.onFiltered,
              "row-clicked": function(item, index, event) {
                return _vm.onClickRow(item)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-center my-2",
                        staticStyle: { color: "#05c5ee" }
                      },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v(" Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(composedname)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getNameHTML(data.item))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(lastactivitytime)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getLastActivityHTML(data.value))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(score)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getScoreHTML(data.item))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(iconlabels)",
                fn: function(data) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getProblemAreas(data.item))
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(userid)",
                fn: function(data) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticStyle: {
                          "background-color": "transparent",
                          border: "0px solid #05c5ee",
                          color: "#05c5ee"
                        },
                        attrs: { variant: "outline-primary", size: "md" },
                        on: {
                          click: function($event) {
                            return _vm.onClickRow(data.item)
                          }
                        }
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(data.item.userid) }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pl-4 pr-4 mt-2", staticStyle: { float: "right" } },
        [
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.patientcount,
              "per-page": _vm.perPage,
              "aria-controls": "my-table"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _vm.patientcount == 0
        ? _c(
            "b-row",
            { staticStyle: { display: "none" } },
            [
              _c(
                "b-container",
                {
                  staticStyle: {
                    "background-color": "white",
                    "margin-left": "0",
                    "margin-right": "0",
                    width: "100%",
                    "max-width": "100%"
                  }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-3", attrs: { "align-h": "center" } },
                    [
                      _c("b-icon-envelope-open", {
                        staticStyle: {
                          width: "60px",
                          height: "60px",
                          color: "#05c5ee"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4", attrs: { "align-h": "center" } },
                    [
                      _c("h3", { staticClass: "semi-bold mb-0" }, [
                        _vm._v("Invite Patients")
                      ])
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2", attrs: { "align-h": "center" } },
                    [_vm._v("No patient data")]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4", attrs: { "align-h": "center" } },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.modal-invite-main",
                              modifiers: { "modal-invite-main": true }
                            }
                          ],
                          staticClass: "blueButton",
                          staticStyle: { "font-size": "16px" },
                          attrs: { variant: "primary", size: "lg" }
                        },
                        [_vm._v("Invite Patient")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-4", attrs: { "align-h": "center" } },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: { "text-align": "center", width: "70%" }
                        },
                        [
                          _vm._v(
                            "Tips: Generate an invitation code and send it to patients you want to invite. Instruct the patients to download Truthbrush app to complete the redemption process. This can be done quickly and easily while patients are visiting your office."
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }