<template>
  <transition name="slide">
    <div class="custom-modal" v-show="showModal">
      <div class="mask"></div>
      <div class="custom-modal-dialog">
        <div class="custom-modal-header">
          <span>{{title}}</span>
          <a href="javascript:;" class="icon-close" v-on:click="$emit('cancel')"></a>
        </div>
        <div class="custom-modal-body">
          <slot name="body"></slot>
        </div>
        <div class="custom-modal-footer">
          <a href="javascript:;" class="custom-btn" v-if="btnType==1" v-on:click="$emit('submit')">{{confirmText}}</a>
          <a href="javascript:;" class="custom-btn" v-if="btnType==2" v-on:click="$emit('cancel')">{{cancelText}}</a>
          <div class="custom-btn-group" v-if="btnType==3">
            <a href="javascript:;" class="custom-btn" v-on:click="$emit('submit')">{{confirmText}}</a>
            <a href="javascript:;" class="custom-btn custom-btn-secondary" v-on:click="$emit('cancel')">{{cancelText}}</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "modal",
  props: {
    // ModalType：small, middle, large, form
    modalType: {
      type: String,
      default: "form"
    },
    title: String,
    // Btn Type: 1: Confirm Only 2: Cancel Only 3: Both
    btnType: String,
    confirmText: {
      type: String,
      default: "Confirm"
    },
    cancelText: {
      type: String,
      default: "Cancel"
    },
    showModal: Boolean
  }
};
</script>
<style lang="scss">
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import "./../assets/scss/modal.scss";
</style>
