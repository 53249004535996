let baseURL
switch (process.env.NODE_ENV) {
    case 'development':
        baseURL = 'https://prod.api.truthbrush.cn'
        break
    case 'production':
        baseURL = 'https://prod.api.truthbrush.cn'
        break
    default:
        baseURL = 'https://prod.api.truthbrush.cn'
        break
}

export default {
    baseURL
}