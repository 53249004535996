import http from './http'


const prefix = '/auth'

export function auth1(phone) {
  let url = prefix + "/provider/" + phone
  return http.post(url, null, {headers: {
    'Authorization': "123213"
  }})
}

export function auth2(phone, sms) {
  let url = prefix + "/provider/confirm/" + phone + '/' + sms
  return http.post(url, null, {headers: {
    'Authorization': "123213"
  }})
}
