// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Inter/Inter-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Inter/Inter-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Inter/Inter-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Inter/Inter-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: 'ibm-plex-san';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n\r\n@font-face {\r\n  font-family: 'ibm-plex-san-medium';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n}\r\n\r\n@font-face {\r\n  font-family: 'ibm-plex-san-semi-bold';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\r\n}\r\n\r\n@font-face {\r\n  font-family: 'ibm-plex-san-bold';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\r\n}\r\n\r\n\r\n* {\r\n  font-family: 'ibm-plex-san';\r\n  font-size: 14px;\r\n}\r\n\r\n.medium {\r\n  font-family: 'ibm-plex-san-medium';\r\n}\r\n\r\n.semi-bold {\r\n  font-family: 'ibm-plex-san-semi-bold';\r\n}\r\n\r\n.bold {\r\n  font-family: 'ibm-plex-san-bold';\r\n}", ""]);
// Exports
module.exports = exports;
