<template>
    <b-container fluid style="background-color:#f3f3f3; height:100%">
      <b-row class="pl-2 pr-2 pt-3">
        <b-breadcrumb :items="breadcrumbitems()" style="color:#05c5ee"></b-breadcrumb>
      </b-row>
      <b-row class="pl-2 pr-2 pt-0" align-v="center">
        <b-col cols="auto">
          <div
            class="circle-avatar profileicon"
            v-bind:style="{ 'background-image': 'url(' + getprofileimageurl() + ')' }"
          ></div>
        </b-col>
        <b-col cols="auto pl-0">
          <h2 class="bold" style="margin-bottom:0">{{getDisplayName()}}</h2>
        </b-col>
        <b-col>
          <b-button
            size="lg"
            variant="outline-info"
            style="border-color:#05c5ee;"
            href="/#/singlepatientedit"
          >配置用户</b-button>
        </b-col>
      </b-row>

      <b-row class="pl-2 pr-2 pt-5 ml-0">
        <b-col cols="3" style='min-width:250px'>
          <b-row class="calendar-panel">
            <b-col cols="12" class="pl-0 pr-0">
              <b-calendar
                block
                @context="onContext"
                v-model="value"
                locale="en-US"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                today-variant="info"
                :date-info-fn="dateClass"
                :date-disabled-fn="dateDisabled"
                hide-header
                label-help
              ></b-calendar>
            </b-col>
            <b-col>
              <b-row class="pb-3 pl-3 pt-3" align-h="center">
                <b-badge class="legendbox" style="color:#e0797d; background-color:#e0797d">o</b-badge>None
                <b-badge
                  class="legendbox"
                  style="color:rgb(255,193,7); background-color:rgb(255,193,7); margin-left:10px;"
                >o</b-badge>Low
                <b-badge
                  class="legendbox"
                  style="color:#d5f2f8; background-color:#d5f2f8; margin-left:10px;"
                >o</b-badge>Today
                <b-badge
                  class="legendbox"
                  style="color:#0062cc; background-color:#0062cc; margin-left:10px;"
                >o</b-badge>Selected
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <h3><b>事件</b></h3>
          </b-row>
          <b-row class="mt-1" v-if='dailyactivitylist.length>0'>
            <b-table
              id="daily-activity-table"
              :fields="fields"
              :items="dailyactivitylist"
              :tbody-tr-class="rowClass"
              hover
              style="background-color: white;"
              sort-icon-left
              thead-class="hidden_header"
              @row-clicked="(item, index, event) => onClickRow(item)"
            >
              <template v-slot:table-busy>
                <div class="text-center my-2" style="color:#05c5ee">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>&nbsp;Loading...</strong>
                </div>
              </template>
              <template v-slot:head(createdtime)>
                <span class="text-info">Activities on {{ value }}</span>
              </template>

              <template v-slot:cell(createdtime)="data">
                <span v-html="getActivityTimeName(data.item)"></span>
              </template>
            </b-table>
          </b-row>
          <b-row class="mt-5" v-if='dailyactivitylist.length==0' align-h="center">
            No activity was recorded on the selected date.
          </b-row>
        </b-col>
        <b-col cols="9">
          <b-tabs content-class="mt-0" class="metrictab" v-model="tabIndex">
            <b-tab title="刷牙质量" :title-link-class="tabClass()" active>
              <b-row class="ml-0 mr-0 pt-0">
                <b-col cols="6" style="background-color:white" class="pt-4 pb-5">
                  <b-row class="pl-5">
                    <h5>
                      <b>时间</b>
                    </h5>
                  </b-row>
                  <b-row class="mb-2 mt-4 ml-5 mr-5">
                    <b-col cols="12" class="pl-0 pr-0 pb-3">
                      <div
                        id="startmarker"
                        style="position:absolute; top: -15px; left: 0; padding-left:0%; color:#05c5ee"
                      >Start</div>
                      <div
                        id="endmarker"
                        style="position:absolute; top: -15px; right:0; padding-right:0%; color:#05c5ee"
                      >End</div>
                    </b-col>
                    <b-col cols="12" class="pl-0 pr-0">
                      <b-progress :max="max" style="width:100%; height: 15px">
                        <b-progress-bar style="background-color: #efefef" :value="getProgress(0)"></b-progress-bar>
                        <b-progress-bar style="background-color: #2ebfde" :value="getProgress(1)"></b-progress-bar>
                        <b-progress-bar style="background-color: #efefef" :value="getProgress(2)"></b-progress-bar>
                      </b-progress>
                    </b-col>
                    <b-col cols="6" class="pl-0 pr-0 pt-1">
                      <div style="color:#6f6f6f">开始</div>
                    </b-col>
                    <b-col cols="6" class="pl-0 pr-0 pt-1">
                      <div style="color:#6f6f6f; float:right">结束</div>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2 mt-4 ml-5 mr-5 pb-4">
                    <b-col>
                      <b-row align-h="center" class="metricsstyle">{{getData(0)}}s</b-row>
                      <b-row align-h="center" class="metricsstylesm">运动时间</b-row>
                    </b-col>
                    <b-col>
                      <b-row align-h="center" class="metricsstyle">{{getData(1)}}s</b-row>
                      <b-row align-h="center" class="metricsstylesm">刷牙时间</b-row>
                    </b-col>
                    <b-col>
                      <b-row align-h="center" class="metricsstyle">{{getData(2)}}%</b-row>
                      <b-row align-h="center" class="metricsstylesm">动作效率</b-row>
                    </b-col>
                  </b-row>

                  <b-row class="pl-5 pt-4" style="border-top: 1px solid #f2f2f2">
                    <h5>
                      <b>技术动作</b>
                    </h5>
                  </b-row>
                  <b-row style="width:100%" align-h="center">
                    <canvas id="doughnutChart" style="max-width:480px;max-height:240px"></canvas>
                  </b-row>
                  <b-row class="mb-2 mt-4 ml-5 mr-5">
                    <b-col>
                      <b-row align-h="center" class="metricsstyle">{{getData(3)}}%</b-row>
                      <b-row align-h="center" class="metricsstylesm">
                        <b-badge class="legendbox" style="color:#13aabf; background-color:#13aabf">o</b-badge>转圈
                      </b-row>
                    </b-col>
                    <b-col>
                      <b-row align-h="center" class="metricsstyle">{{getData(4)}}%</b-row>
                      <b-row align-h="center" class="metricsstylesm">
                        <b-badge class="legendbox" style="color:#22c998; background-color:#22c998">o</b-badge>左右
                      </b-row>
                    </b-col>
                    <b-col>
                      <b-row align-h="center" class="metricsstyle">{{getData(5)}}%</b-row>
                      <b-row align-h="center" class="metricsstylesm">
                        <b-badge class="legendbox" style="color:#50d066; background-color:#50d066">o</b-badge>上下
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="6"
                  class="pt-4 pb-5"
                  style="background-color:white; border-left: 1px solid #f2f2f2"
                >
                  <b-row class="pl-5 pb-3">
                    <h5>
                      <b>刷牙不足位置</b>
                    </h5>
                  </b-row>

                  <b-row id="pb2" style="min-height:400px;"></b-row>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="运动数据" :title-link-class="tabClass()">
              <b-overlay :show="loader2" rounded="lg">
                <b-row style="background:white" class="ml-0 mr-0 pb-5 mb-5">
                  <b-col class="pl-0 pr-0">
                    <b-row class="ml-0 mr-0 pl-5 pt-5">
                      <h5>
                        <b>低频数据</b>
                      </h5>
                    </b-row>
                    <b-row class="ml-0 mr-0 pl-5 pt-4 pr-5" style>
                      <b-col>
                        <canvas id="movementchart"></canvas>
                      </b-col>
                    </b-row>
                    <b-row class="ml-0 mr-0 pl-5 pt-5">
                      <h5>
                        <b>高频数据</b>
                      </h5>
                    </b-row>
                    <b-row class="ml-0 mr-0 pl-5 pt-4 pr-5">
                      <b-col>
                        <canvas id="highfrequencychart" style></canvas>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { getRecords } from "../api/record";
import Chart from "chart.js";

export default {
  name: "singleactivity",
  data() {
    return {
      loader2: false,
      value: "",
      context: null,
      max: 100,
      month: null,
      year: null,
      selectdate: null,
      selectedactivitylocal: null,
      monthlyrecords: null,
      dailyactivitylist: [],
      tabIndex: 0,
      doughnutChart: null,
      highfrequencychart: null,
      movementchart: null,
      fields: [
        {
          key: "createdtime",
          label: "",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        }
      ]
    };
  },
  watch: {
    tabIndex: function() {
      if (this.tabIndex == 1) {
        var context = this;
        context.loader2 = true;
        setTimeout(function() {
          context.plotMovementChart();
        }, 300);
      }
    },
    value: function() {
      //console.log("changed to:"+this.value);
      //this.updateValue();
      //this.tabIndex = 0;
    },
    context: function() {
      console.log("changedcontext:" + this.context);
      this.tabIndex = 0;
      var aa = this.context.activeYMD.split("-");
      var activemonth = parseInt(aa[1]);
      var activeyear = parseInt(aa[0]);
      if (
        this.month == null ||
        this.year == null ||
        this.year != activeyear ||
        this.month != activemonth
      ) {
        // need a fetch
        console.log('b')
        var startmonth = new Date(aa[0], parseInt(aa[1]) - 1, 1);
        var endmonth = new Date(aa[0], parseInt(aa[1]), 1);
        var context = this;
        var datestring = new Date(
          this.$store.state.selectedactivity.createdtime
        );
        this.getRecordsCall(
          this.$store.state.selectedprofile.profileid,
          startmonth.toISOString(),
          endmonth.toISOString(),
          function() {
            if (context.value == "") {
              var bb = datestring.toLocaleDateString().split("/");
              context.value = bb[2] + "-" + bb[0] + "-" + bb[1];
              context.context.activeYMD = context.value;
            } else {
              context.value = context.context.activeYMD;
            }
            context.updateValue();
          }
        );
      } else {
        console.log('c')
        this.updateValue();
      }
    }
  },
  computed: {},
  methods: {
    tabClass() {
      return "tabclass";
    },
    plotMovementChart() {
      this.loader2 = true;
      if (
        this.selectedactivitylocal == null ||
        this.selectedactivitylocal.info == null
      ) {
        return;
      }
      var xmlHttp = new XMLHttpRequest();
      var context = this;
      xmlHttp.onreadystatechange = function() {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          var file = JSON.parse(xmlHttp.responseText);
          context.displayHighLowChart(file.lowdata, file.highdata);
          context.loader2 = false;
        }
      };
      console.log(this.selectedactivitylocal.datafileurl)
      xmlHttp.open("GET", this.selectedactivitylocal.datafileurl, true); // true for asynchronous
      xmlHttp.send(null);
    },
    displayHighLowChart(data_low, data_high) {
      if (data_high != null) {
        //		line
        var ctxL = document
          .getElementById("highfrequencychart")
          .getContext("2d");

        var points = data_high.split(";");
        var s1 = [],
          s2 = [],
          s3 = [];
        var dates = [];
        for (var i = 0; i < points.length; i++) {
          var s = points[i];
          s1[i] = s.split(",")[0];
          s2[i] = s.split(",")[1];
          s3[i] = s.split(",")[2];
          dates[i] = i;
        }

        if (this.highfrequencychart != null) {
          this.highfrequencychart.destroy();
        }

        this.highfrequencychart = new Chart(ctxL, {
          type: "line",

          data: {
            labels: dates,
            datasets: [
              {
                //label: "My First dataset",
                label: "X Motion Signals",

                data: s1,
                borderColor: ["rgba(200, 99, 132, .7)"],
                borderWidth: 2
              },
              {
                //label: "My First dataset",
                label: "Y Motion Signals",

                data: s2,
                borderColor: ["#A4DE02"],
                borderWidth: 2
              },
              {
                //label: "My First dataset",
                label: "Z Motion Signals",

                data: s3,
                borderColor: ["blue"],
                borderWidth: 2
              }
            ]
          },
          options: {
            
            animation: {
              duration: 0 // general animation time
            },
            hover: {
              animationDuration: 0 // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            tooltips: {
              enabled: false
            },
            responsive: true,
            elements: {
              point: {
                radius: 0
              },
              line: {
                tension: 0 // disables bezier curves
              }
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    labelString: "1/10 seconds",
                    display: false
                  }
                }
              ],
              yAxes: [
                {
                  scaleLabel: {
                    labelString: "Motion",
                    display: false
                  }
                }
              ]
            }
          }
        });
      }

      if (data_low != null) {
        //		line
        ctxL = document.getElementById("movementchart").getContext("2d");

        points = data_low.split(";");
        (s1 = []), (s2 = []), (s3 = []);
        dates = [];
        for (i = 0; i < points.length; i++) {
          s = points[i];
          s1[i] = s.split(",")[0];
          s2[i] = s.split(",")[1];
          s3[i] = s.split(",")[2];
          dates[i] = i;
        }

        if (this.movementchart != null) {
          this.movementchart.destroy();
        }

        this.movementchart = new Chart(ctxL, {
          type: "line",

          data: {
            labels: dates,
            datasets: [
              {
                //label: "My First dataset",
                label: "X Motion Signals",

                data: s1,
                borderColor: ["rgba(200, 99, 132, .7)"],
                borderWidth: 2
              },
              {
                //label: "My First dataset",
                label: "Y Motion Signals",

                data: s2,
                borderColor: ["#A4DE02"],
                borderWidth: 2
              },
              {
                //label: "My First dataset",
                label: "Z Motion Signals",

                data: s3,
                borderColor: ["blue"],
                borderWidth: 2
              }
            ]
          },
          options: {
            animation: {
              duration: 0 // general animation time
            },
            hover: {
              animationDuration: 0 // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            tooltips: {
              enabled: false
            },
            onClick: (evt, item) => {
              if (item.length == 0) return;
              //var index = item[0]._index;
              //console.log(s1[index] + "," + s2[index] + "," + s3[index]);
            },
            responsive: true,
            elements: {
              point: {
                radius: 0
              },
              line: {
                tension: 0 // disables bezier curves
              }
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    labelString: "1/10 seconds",
                    display: false
                  }
                }
              ],
              yAxes: [
                {
                  scaleLabel: {
                    labelString: "Motion",
                    display: false
                  }
                }
              ]
            }
          }
        });
      }
    },
    getData(index) {
      if (
        this.selectedactivitylocal == null ||
        this.selectedactivitylocal.info == null
      )
        return 0;
      var info = JSON.parse(this.selectedactivitylocal.info);
      if (index == 0) {
        return (info.loglength / 10).toFixed(0);
      } else if (index == 1) {
        return (info.brushinglength / 10).toFixed(0);
      } else if (index == 2) {
        return (parseFloat(info.efficiencyscore) * 100).toFixed(0);
      } else if (index == 3) {
        return (parseFloat(info.circular) * 100).toFixed(0);
      } else if (index == 4) {
        return (parseFloat(info.leftright) * 100).toFixed(0);
      } else if (index == 5) {
        return (parseFloat(info.updown) * 100).toFixed(0);
      }
    },
    getProgress(index) {
      if (
        this.selectedactivitylocal == null ||
        this.selectedactivitylocal.info == null
      )
        return 0;
      var info = JSON.parse(this.selectedactivitylocal.info);

      if (index == 0) {
        var ratio = parseFloat(info.start) / parseFloat(info.loglength);
        document.getElementById("startmarker").style["padding-left"] =
          ratio * 100 + "%";
        document.getElementById("startmarker").innerHTML =
          (info.start / 10).toFixed(0) + "s";
        return ratio * 100;
      } else if (index == 1) {
        return (
          (parseFloat(info.brushinglength) / parseFloat(info.loglength)) * 100
        );
      } else {
        ratio = 1 - parseFloat(info.end) / parseFloat(info.loglength);
        document.getElementById("endmarker").style["padding-right"] =
          ratio * 100 + "%";
        document.getElementById("endmarker").innerHTML =
          (info.end / 10).toFixed(0) + "s";
        return ratio * 100;
      }
    },
    getTableHeader(row) {
      return row.createdtime;
    },
    onClickRow(row) {
      console.log("clicked:" + row);
      //this.loader = true;
      this.selectedactivitylocal = row;
      this.updateQuadrant();
      this.updatepiechart();
      //this.plotMovementChart();
      this.tabIndex = 0;
    },
    getActivityTimeName(row) {
      var d = new Date(row.createdtime);
      return d.toLocaleTimeString() + ", " + d.toDateString();
    },
    updatepiechart() {
      var ctx = document.getElementById("doughnutChart");
      if (this.doughnutChart) {
        this.doughnutChart.destroy();
      }

      var pieOptions = {
        events: false,
        legend: {
          display: false
        },
        animation: {
          duration: 500,
          easing: "easeOutQuart",
          onComplete: function() {
            var ctx = this.chart.ctx;
            ctx.font = Chart.helpers.fontString(
              16,
              "normal",
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function(dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                var model =
                    dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                  total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                  mid_radius =
                    model.innerRadius +
                    (model.outerRadius - model.innerRadius) / 2,
                  start_angle = model.startAngle,
                  end_angle = model.endAngle,
                  mid_angle = start_angle + (end_angle - start_angle) / 2;

                var x = mid_radius * Math.cos(mid_angle);
                var y = mid_radius * Math.sin(mid_angle);

                ctx.fillStyle = "#fff";
                if (i == 3) {
                  // Darker text color for lighter background
                  ctx.fillStyle = "#444";
                }
                var percent =
                  String(Math.round((dataset.data[i] / total) * 100)) + "%";
                //Don't Display If Legend is hide or value is 0
                if (dataset.data[i] != 0) {
                  //ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                  // Display percent in another line, line break doesn't work for fillText
                  ctx.fillText(percent, model.x + x, model.y + y + 15);
                }
              }
            });
          }
        }
      };

      this.doughnutChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [this.getData(3), this.getData(4), this.getData(5)],
              backgroundColor: ["#13aabf", "#22c998", "#50d066"]
            }
          ],
          labels: ["Circular", "Side-to-Side", "Up-Down"]
        },
        options: pieOptions
      });
    },
    updateValue() {
      console.log("updatevalue");
      if (this.context.selectedYMD == null || this.context.selectedYMD == "") {
        return;
      }
      console.log("updatevalue 1");
      var aa = this.context.selectedYMD.split("-");
      //this.value = aa[2] + "-" + aa[0] + "-" + aa[1];
      var startofday = new Date(aa[0], parseInt(aa[1]) - 1, aa[2]);
      var endofday = new Date(aa[0], parseInt(aa[1]) - 1, parseInt(aa[2]) + 1);
      this.dailyactivitylist = [];
      for (
        var i = 0;
        this.monthlyrecords != null && i < this.monthlyrecords.length;
        i++
      ) {
        var d = new Date(this.monthlyrecords[i].createdtime);
        if (
          d.getTime() > startofday.getTime() &&
          d.getTime() < endofday.getTime()
        ) {
          this.dailyactivitylist.push(this.monthlyrecords[i]);
        }
      }

      if (this.dailyactivitylist != null) {
        this.selectedactivitylocal = this.dailyactivitylist[0];
      }

      this.updateQuadrant();
      this.updatepiechart();
      //this.plotMovementChart();
    },
    getRecordsCall(profileid, start, end, callback) {
      let loader = this.$loading.show();
      var context = this;
      console.log("getrecords http");
      getRecords(profileid, start, end).then(res => {
        var d = new Date(start);
        context.year = d.getFullYear();
        context.month = d.getMonth() + 1;
        loader.hide();
        context.monthlyrecords = res.data;
        if (callback) {
          callback(context);
        }
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (
        this.selectedactivitylocal != null &&
        item.createdtime === this.selectedactivitylocal.createdtime
      )
        return "table-selected-activity";
      else return "table-row-activity";
    },
    dateDisabled(ymd, date) {
      var now = new Date();
      if (date.getTime() > now.getTime()) return true;

      var profilecreationdate = new Date(
        this.$store.state.selectedprofile.createdtime
      );
      if (date.getTime() < profilecreationdate.getTime()) return true;
      return false;
    },
    updateQuadrant() {
      document.getElementById("pb2").style.background =
        "url(/imgs/image-teeth-whole-with-label@3x.png) center no-repeat";

      if (this.selectedactivitylocal == null) return;
      var info = JSON.parse(this.selectedactivitylocal.info);
      if (info == null) return;
      var color = info.quadrantColor;
      var background = "";

      if (color.substring(0,1)=='r') {
        background += "url(/imgs/image-teeth-upper-right-outer@3x.png) center no-repeat,";
      }
      if (color.substring(1,2)=='r') {
        background += "url(/imgs/image-teeth-upper-left-outer@3x.png) center no-repeat,";
      }
      if (color.substring(2,3)=='r') {
        background += "url(/imgs/image-teeth-lower-right-outer@3x.png) center no-repeat,";
      }
      if (color.substring(3,4)=='r') {
        background += "url(/imgs/image-teeth-lower-left-outer@3x.png) center no-repeat,";
      }
      
      
      if (color.substring(4,5)=='r') {
         background +=  ("url(/imgs/image-teeth-upper-right-inner@3x.png) center no-repeat,");
      }
      if (color.substring(5,6)=='r') {
         background +=  ("url(/imgs/image-teeth-upper-left-inner@3x.png) center no-repeat,");
      }
      if (color.substring(6,7)=='r') {
         background +=  ("url(/imgs/image-teeth-lower-right-inner@3x.png) center no-repeat,");
      }
      if (color.substring(7,8)=='r') {
         background +=  ("url(/imgs/image-teeth-lower-left-inner@3x.png) center no-repeat,");
      }

      background += "url(/imgs/image-teeth-whole-with-label@3x.png) center no-repeat";

      document.getElementById("pb2").style.background = background;
    },
    dateClass(ymd, date) {
      if (this.value == null || this.value == "") return "";
      var now = new Date();
      if (date.getTime() > now.getTime()) return "";

      var profilecreationdate = new Date(
        this.$store.state.selectedprofile.createdtime
      );
      if (date.getTime() < profilecreationdate.getTime()) return "";

      var currentmonth = this.value.split("-")[1];
      var currentyear = this.value.split("-")[0];
      if (
        date.getFullYear() != parseInt(currentyear) ||
        date.getMonth() != parseInt(currentmonth) - 1
      ) {
        return "";
      }
      if (
        date.getDate() == now.getDate() &&
        date.getMonth() == now.getMonth()
      ) {
        return "todayclass";
      }

      var startofday = new Date(
        date.getFullYear(),
        parseInt(date.getMonth()),
        date.getDate()
      );
      var endofday = new Date(
        date.getFullYear(),
        parseInt(date.getMonth()),
        parseInt(date.getDate()) + 1
      );
      var count = 0;
      for (
        var i = 0;
        this.monthlyrecords != null && i < this.monthlyrecords.length;
        i++
      ) {
        var d = new Date(this.monthlyrecords[i].createdtime);
        if (
          d.getTime() > startofday.getTime() &&
          d.getTime() < endofday.getTime()
        ) {
          count++;
        }
      }
      if (count == 1) {
        return "warningclass";
      } else if (count == 0) {
        return "zeroclass";
      } else {
        return "regularday";
      }
    },
    getTodayStyle() {
      return;
    },
    onContext(ctx) {
      this.context = ctx;
    },
    getDisplayName() {
      return this.getNameHTML(this.$store.state.selectedprofile);
    },
    getprofileimageurl() {
      let profile = this.$store.state.selectedprofile;
      if (profile.avatarid != null) {
        return profile.avatarid;
      }
      return "/imgs/defaultuser@3x.png";
    },
    getProfileName() {
      let profile = this.$store.state.selectedprofile;
      if (profile != null) {
        if (profile.externalfirstname != null) {
          return profile.externalfirstname;
        } else if (profile.firstname != null) {
          return profile.firstname;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getNameHTML(row) {
      var lastname = row.externallastname;
      var firstname = row.externalfirstname;
      if (firstname == null || firstname == "") {
        firstname = row.firstname;
      }
      var name = "";
      if (lastname && firstname) {
        name = lastname + ", " + firstname;
      } else if (lastname) {
        name = lastname + ", ";
      } else if (firstname) {
        name = firstname;
      }
      if (name == null || name == "") {
        return "<p style='color:#c6c6c6'>N/A</p>";
      } else {
        return name;
      }
    },
    breadcrumbitems() {
      var name = this.getNameHTML(this.$store.state.selectedprofile);
      var array = [
        {
          text: "我的用户",
          href: "#/patients"
        },
        {
          text: name,
          href: "#/singlepatient"
        },
        {
          text: "刷牙日志",
          href: "#",
          active: true
        }
      ];
      return array;
    }
  },
  mounted() {
    console.log("mounted");
    if (
      this.$store.state.selectedactivity == null ||
      this.$store.state.selectedprofile == null
    ) {
      window.location.href = "/";
    }
    //var datestring = new Date(this.$store.state.selectedactivity.createdtime);
    //var aa = datestring.toLocaleDateString().split("/");
    //this.value = aa[2]+'-'+aa[0]+'-'+aa[1]
  }
};
</script>

<style>
.todayclass {
  border-radius: 20px !important;
  background-color: #d5f2f8;
  clip-path: circle(12px at center);
}

.warningclass {
  border-radius: 20px !important;
  background-color: rgb(255, 193, 7);
  clip-path: circle(12px at center);
}

.zeroclass {
  border-radius: 20px !important;
  background-color: #e0797d;
  clip-path: circle(12px at center);
}

.btn-light {
  background-color: white !important;
}
.table-row-activity {
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #e0e0e0;
  font-size: 18px;
}

.table-row-activity td {
  border-top: 3px solid white !important;
}

.table-selected-activity {
  background-color: white;
}
.table-row-activity span {
  font-size: 16px;
}
.table-selected-activity span {
  font-size: 16px;
}
.text-info {
  font-size: 16px;
}

.b-calendar-inner .form-control {
  border: 0 !important;
}

.b-calendar-grid-help.border-top {
  border: 0 !important;
}

.b-calendar-grid-weekdays.border-bottom {
  border: 0 !important;
  padding-bottom: 5px;
}

.calendar-panel {
  background-color: white;
  padding-top: 15px;
  border: 0px solid #2ebfde;
}

.redsqaure {
  width: 16px;
  height: 16px;
  background-color: #e0797d;
}

.hidden_header {
  display: none;
}
.metricsstyle {
  color: #161616;
  font-size: 22px;
}

.metricsstylesm {
  color: #393939;
  font-size: 14px;
}

.legendbox {
  padding-right: 6px !important;
  margin-top:2px;
  margin-bottom:2px;
  margin-right: 7px;
  border-radius: 0 !important;
}

#pb2 {
  background-size: auto 100% !important;
  height: 90%;
}

.metrictab .nav-link {
  border: 0 !important;
  color: #495057 !important;
}

.metrictab .nav-tabs {
  border: 0 !important;
}

#daily-activity-table td {
  border: 0 !important;
}

.tabclass {
  font-size: 16px;
  padding-bottom: 10px !important;
}
</style>
