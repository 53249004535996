import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index.vue'
import Login from '../pages/login.vue'
import Signup from '../pages/signup.vue'
import Forget from '../pages/forgetpassword.vue'
import Patients from '../pages/myPatients.vue'
import Orders from '../pages/myOrders.vue'
import Insights from '../pages/insights.vue'
import Settings from '../pages/settings.vue'
import CreateProfile from '../pages/createProfile.vue'
import Singlepatient from '../pages/singlepatient.vue'
import Singlepatientedit from '../pages/singlepatientedit.vue'
import Activity from '../pages/activity.vue'
import Myinvite from '../pages/myinvite.vue'
import Overview from '../pages/overview.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/patients',
    component: Index,
    children: [
      {
        path: 'patients',
        name: 'patients',
        component: Patients,
      },
      {
        path: 'overview',
        name: 'overview',
        component: Overview,
      },
      {
        path: 'orders',
        name: 'orders',
        component: Orders,
      },
      {
        path: 'insights',
        name: 'insights',
        component: Insights,
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: 'singlepatient',
        name: 'singlepatient',
        component: Singlepatient,
      },
      {
        path: 'singlepatientedit',
        name: 'singlepatientedit',
        component: Singlepatientedit,
      },
      {
        path: 'activity',
        name: 'activity',
        component: Activity,
      },
      {
        path: 'invite',
        name: 'myinvite',
        component: Myinvite,
      }
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/forget',
    name: 'forget',
    component: Forget
  },
  {
    path: '/createprofile',
    name: 'createprofile',
    component: CreateProfile
  },
]

const router = new VueRouter({
  routes
})



export default router
