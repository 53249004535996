<template>
  <b-container fluid style="background-color:#f3f3f3; height:100%">
    <b-row class="pl-2 pr-2 pt-3">
      <b-breadcrumb :items="breadcrumbitems" style="color:#05c5ee"></b-breadcrumb>
    </b-row>

    <b-row class="pl-2 pr-2 pt-2">
      <b-col cols="auto">
        <h5 class="semi-bold">家庭成员</h5>
      </b-col>
    </b-row>
    <b-row class="mt-0 pl-2">
      <div
        class="col"
        style="color:#939393;font-size:14px"
      >关于所有家庭成员的信息</div>
    </b-row>
    <b-row class="pl-2 pr-2 mt-2">
      <b-col cols="12">
        <b-table
          id="my-table"
          :fields="fields"
          :items="profilelist"
          hover
          style="background-color: white;"
          sort-icon-left
          :busy="isBusy"
          :tbody-tr-class="rowClass"
          @row-clicked="(item, index, event) => onClickRow(item)"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2" style="color:#05c5ee">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(composedname)="data">
            <span v-html="getNameHTML(data.item)"></span>
          </template>
          <template v-slot:cell(status)>
            <span style="color:#189940">Active</span>
          </template>

          <template v-slot:cell(sex)="data">
            <span v-html="getSexHTML(data.item)"></span>
          </template>
          <template v-slot:cell(dob)="data">
            <span v-html="getDobHTML(data.item)"></span>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row class="pl-2 pr-2 pt-5" align-v="center">
      <b-col cols="auto">
        <div
          class="circle-avatar profileicon"
          v-bind:style="{ 'background-image': 'url(' + getprofileimageurl() + ')' }"
        ></div>
      </b-col>
      <b-col cols="auto pl-3">
        <b-row>
          <h2 class="bold" style="margin-bottom:0">{{getDisplayName()}}</h2>
        </b-row>
        <b-row>
          <h5 class="bold" style="margin-bottom:0; padding-top:5px;">{{displaylocation}}</h5>
        </b-row>
      </b-col>
      <b-col>
        <b-button
          size="lg"
          variant="outline-info"
          style="border-color:#05c5ee;"
          href="/#/singlepatientedit"
        >配置用户</b-button>
      </b-col>
      <b-col cols="auto">
        <b-form-group id="input-group-3" label-for="input-3">
          <b-form-select
            size="lg"
            id="input-3"
            v-model="timewindow"
            :options="timewindows"
            style="border-radius:30px; border:2px solid #05c5ee; width:150px"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="pl-2 pr-2 mt-4">
      <b-col cols="6">
        <h5 class="semi-bold mb-2">概况</h5>
      </b-col>
      <b-col cols="6">
        <h5 class="semi-bold mb-2">数据</h5>
      </b-col>
    </b-row>
    <b-row class="pl-2 pr-2 pt-2">
      <b-col xl="6">
        <b-container style="background-color:white; border: 1px solid #e6eaee;" class="pb-4 pt-2">
          <b-row class="pt-4 ml-0 mr-0 pb-3" style="border-bottom: 1px solid #e6eaee">
            <b-col cols="auto" class="pl-0">
              <h5>在最近{{windowdayTitle}}周, {{getDisplayName()}} ...</h5>
            </b-col>
            <b-col>
              <b-icon
                icon="question-circle"
                font-scale="1.5"
                style="color:gray;float:right"
                id="questionmark"
              ></b-icon>
              <b-popover target="questionmark" triggers="hover" placement="top">
                <template v-slot:title>刷牙质量平分</template>
                <b-container>
                  <b-row class="pt-2">
                    <p style="display:inline">
                      <b>次数:</b>&nbsp; 每天刷牙两次天数的比例
                    </p>
                  </b-row>
                  <b-row class="pt-2">
                    <p style="display:inline">
                      <b>时长:</b> 平均每次刷牙时间。刷牙前的准备时间和后的清洁时间不在统计之内
                    </p>
                  </b-row>
                  <b-row class="pt-2">
                    <p style="display:inline">
                      <b>覆盖:</b>&nbsp; 是否覆盖了每个区域。某个区域长期的覆盖不足将被展现出来
                    </p>
                  </b-row>
                  <b-row class="pt-2">
                    <p style="display:inline">
                      <b>效率:</b>&nbsp; 刷牙中是否专心，能否一气呵成的刷完牙
                    </p>
                  </b-row>
                </b-container>
              </b-popover>
            </b-col>
          </b-row>
          <b-row style="height:100%" class="pl-3 pt-2">
            <b-col class="pt-3 pb-3">
              <b-row class="pl-3 pt-0">
                <h5 class="semi-bold mb-2">分类</h5>
              </b-row>

              <b-row class="pl-3 pt-3 mt-1">
                <b-col cols="2" class="pl-0 pr-0">
                  <b-img
                    src="/imgs/frequency@3x.png"
                    alt="Center image"
                    style="width:70%; max-width:48px"
                  ></b-img>
                </b-col>
                <b-col class="pl-0">
                  <b-row class="ml-0">
                    <b-col class="pl-0">
                      <h5 class="semi-bold">次数</h5>
                    </b-col>
                    <b-col class="pl-0">
                      <b-badge
                        variant="danger"
                        style="font-size:12px;display:table-cell"
                        v-if="frequencypaul < 60"
                      >Low</b-badge>
                      <b-badge
                        variant="warning"
                        style="font-size:12px;display:table-cell"
                        v-else-if="frequencypaul <= 85 && frequencypaul >= 60"
                      >Fair</b-badge>
                      <b-badge
                        variant="success"
                        style="font-size:12px;display:table-cell"
                        v-else-if="frequencypaul > 85"
                      >Good</b-badge>
                    </b-col>
                  </b-row>
                  <b-row class="ml-0">{{frequencypaul}}% - 的日子刷了两次牙</b-row>
                </b-col>
              </b-row>

              <b-row class="pl-3 pt-3 mt-3">
                <b-col cols="2" class="pl-0 pr-0">
                  <b-img
                    src="/imgs/duration@3x.png"
                    alt="Center image"
                    style="width:70%; max-width:48px"
                  ></b-img>
                </b-col>
                <b-col class="pl-0">
                  <b-row class="ml-0">
                    <b-col class="pl-0">
                      <h5 class="semi-bold">时长</h5>
                    </b-col>
                    <b-col class="pl-0">
                      <b-badge
                        variant="danger"
                        style="font-size:12px;display:table-cell"
                        v-if="averageduration < 60"
                      >Low</b-badge>
                      <b-badge
                        variant="warning"
                        style="font-size:12px;display:table-cell"
                        v-else-if="averageduration >= 60 && averageduration < 90"
                      >Fair</b-badge>
                      <b-badge
                        variant="success"
                        style="font-size:12px;display:table-cell"
                        v-else-if="averageduration >= 90"
                      >Good</b-badge>
                    </b-col>
                  </b-row>
                  <b-row class="ml-0">{{averageduration}}s 平均时长</b-row>
                </b-col>
              </b-row>

              <b-row class="pl-3 pt-3 mt-3">
                <b-col cols="2" class="pl-0 pr-0">
                  <b-img
                    src="/imgs/completeness@3x.png"
                    alt="Center image"
                    style="width:70%; max-width:48px"
                  ></b-img>
                </b-col>
                <b-col class="pl-0">
                  <b-row class="ml-0">
                    <b-col class="pl-0">
                      <h5 class="semi-bold">覆盖</h5>
                    </b-col>
                    <b-col class="pl-0">
                      <b-badge
                        variant="danger"
                        style="font-size:12px;display:table-cell"
                        v-if="completeness < 51"
                      >Low</b-badge>
                      <b-badge
                        variant="warning"
                        style="font-size:12px;display:table-cell"
                        v-else-if="completeness < 80"
                      >Fair</b-badge>
                      <b-badge
                        variant="success"
                        style="font-size:12px;display:table-cell"
                        v-else-if="completeness <= 100"
                      >Good</b-badge>
                    </b-col>
                  </b-row>
                  <b-row class="ml-0">{{completeness}}% 的内外上下牙齿区域</b-row>
                </b-col>
              </b-row>
              <b-row class="pl-3 pt-3 mt-3">
                <b-col cols="2" class="pl-0 pr-0">
                  <b-img
                    src="/imgs/efficiency@3x.png"
                    alt="Center image"
                    style="width:70%; max-width:48px"
                  ></b-img>
                </b-col>
                <b-col class="pl-0">
                  <b-row class="ml-0">
                    <b-col class="pl-0">
                      <h5 class="semi-bold mb-2">效率</h5>
                    </b-col>
                    <b-col class="pl-0">
                      <b-badge
                        variant="danger"
                        style="font-size:12px;display:table-cell"
                        v-if="efficiency < 40"
                      >Low</b-badge>
                      <b-badge
                        variant="warning"
                        style="font-size:12px;display:table-cell"
                        v-else-if="efficiency >= 40 && efficiency <70"
                      >Fair</b-badge>
                      <b-badge
                        variant="success"
                        style="font-size:12px;display:table-cell"
                        v-else-if="efficiency >= 70"
                      >Good</b-badge>
                    </b-col>
                  </b-row>
                  <b-row class="ml-0">{{efficiency}}% 总体运动时间</b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6" align-v="center">
              <b-row id="pb" align-h="center" style="align-items: center;">
                <p class="mb-0" style="text-align:center">红色部分需要多刷</p>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col xl="6">
        <b-container style="background-color:white; height:100% " class="pl-0 pr-0">
          <b-tabs content-class="mt-3" fill class="charttabview">
            <b-tab active>
              <template v-slot:title>
                <h5 class="semi-bold">次数</h5>
                <strong>{{(frequencypaul*1).toFixed(0)}}%</strong>
              </template>
              <canvas id="frequency-chart" class="canvas-patient"></canvas>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <h5 class="semi-bold">时长</h5>
                <strong>{{averageduration}}s</strong>
              </template>
              <canvas id="duration-chart" class="canvas-patient"></canvas>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <h5 class="semi-bold">覆盖</h5>
                <strong>{{completeness}}%</strong>
              </template>
              <canvas id="completeness-chart" class="canvas-patient"></canvas>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <h5 class="semi-bold">效率</h5>
                <strong>{{efficiency}}%</strong>
              </template>
              <canvas id="efficiency-chart" class="canvas-patient"></canvas>
            </b-tab>
          </b-tabs>
        </b-container>
      </b-col>
    </b-row>

    <b-row class="pl-2 pr-2 mt-5">
      <b-col>
        <h5 class="semi-bold">刷牙日志</h5>
      </b-col>
      <b-col cols="auto">Show</b-col>
      <b-col cols="auto" class="pl-0 pr-0">
        <b-form-group id="input-group-3" label-for="input-3" style="margin-bottom:0!important">
          <b-form-select size="sm" id="input-3" v-model="perPage" :options="countwindows"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="auto">entries</b-col>
    </b-row>
    <b-row class="pl-2 pr-2 mt-2">
      <b-col cols="12">
        <b-table
          id="my-table"
          :fields="fieldsactivity"
          :items="records"
          hover
          style="background-color: white;"
          sort-icon-left
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          tbody-tr-class="table-row"
          :per-page="perPage"
          :current-page="currentPage"
          :busy="isBusyRecords"
          @row-clicked="(item, index, event) => onClickRowActivity(item)"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2" style="color:#05c5ee">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(rowbutton)="data">
            <b-link
              href="#"
              style="color:#05c5ee;"
              class="semi-bold"
              @click="onClickRowActivity(data.item)"
            >View</b-link>
          </template>
        </b-table>
        <b-row class="mt-3 pl-4 pr-4" align-h="end">
          <b-pagination
            v-model="currentPage"
            :total-rows="recordcount"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <div class="col">
        <h5 class="semi-bold">设备</h5>
      </div>
    </b-row>
    <b-row class="mt-0">
      <div
        class="col"
        style="color:#939393;font-size:14px"
      >关于此用户的Tracker和Hub</div>
    </b-row>

    <b-row class="mt-4 ml-0 mr-0 pb-4 mb-0">
      <b-table
        id="device-table"
        :fields="fieldsdevice"
        :items="devicelist"
        hover
        style="background-color: white;"
        sort-icon-left
        :busy="isBusy2"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2" style="color:#05c5ee">
            <b-spinner class="align-middle"></b-spinner>
            <strong>&nbsp;Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(equipment)="data">
          <span v-html="getDeviceImage(data.item)"></span>
        </template>
        <template v-slot:cell(deviceid)="data">
          <span v-html="getDeviceID(data.item)"></span>
        </template>
        <template v-slot:cell(createdtime)="data">
          <span v-html="getDeviceDate(data.item.createdtime)"></span>
        </template>
        <template v-slot:cell(temp)="data">
          <span v-html="getDeviceTemp(data.item)"></span>
        </template>
        <template v-slot:cell(fwversion)="data">
          <span v-html="getFWVersion(data.item)"></span>
        </template>
        <template v-slot:cell(lastupdatedtime)="data">
          <span v-html="getDeviceDate(data.item.lastupdatedtime)"></span>
        </template>
        <template v-slot:cell(battery)="data">
          <span v-html="getBatteryHTML(data.item)"></span>
        </template>
      </b-table>
    </b-row>
    <b-row
      class="mt-0 ml-0 mr-0 pb-4"
      v-if="devicelist.length == 0"
    >This profile, {{getDisplayName()}}, has no equipment registered.</b-row>
  </b-container>
</template>

<script>
import { getProfileVacation } from "../api/profile";
import { getProfileListByUserId } from "../api/profile";
import { getRecords } from "../api/record";
import { getDeviceList } from "../api/device";
import { getCustomer } from "../api/customer";
import Chart from "chart.js";
import storage from "./../storage";

export default {
  name: "singlepatient",
  data() {
    return {
      performancescore: 0,
      averageduration: 0,
      frequency: 0,
      frequencypaul: 0 /* number 0-100 */,
      completeness: "Perfect coverage of all quadrants.",
      efficiency: 0,
      perPage: 10,
      displaylocation: "",
      countwindows: [10, 20, 50, 100],
      currentPage: 1,
      recordcount: 0,
      frequencychart: null,
      durationchart: null,
      efficiencychart: null,
      completenesschart: null,
      devicelist: [],
      isBusy2: false,
      fieldsdevice: [
        {
          key: "equipment",
          label: "设备",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "devicetype",
          label: "种类",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "temp",
          label: "温度",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "fwversion",
          label: "Firmware",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "deviceid",
          label: "硬件ID",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "createdtime",
          label: "注册时间",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "lastupdatedtime",
          label: "最后通话",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "battery",
          label: "电池",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        }
      ],
      breadcrumbitems: [
        {
          text: "我的用户",
          href: "#/patients"
        },
        {
          text: "信息",
          href: "#",
          active: true
        }
      ],
      sortBy: "createdtime",
      sortDesc: true,
      timewindow: "最近 4 周",
      timewindows: ["最近 4 周"],
      profilelist: [],
      records: [],
      chart: {
        durations: [],
        logtimes: [],
        durationlabel: [],
        frequencyam: [],
        frequencypm: [],
        frequencyvacation: [],
        completeness: [],
        efficiency: []
      },
      isBusy: false,
      isBusyRecords: false,
      fieldsactivity: [
        {
          key: "createdtimedisplay",
          label: "时间戳",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "duration",
          label: "刷牙时长",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "completeness",
          label: "覆盖率",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "efficiency",
          label: "效率",
          sortable: true,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "rowbutton",
          label: "",
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        }
      ],
      fields: [
        {
          key: "composedname",
          label: "用户名",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "userid",
          label: "爱牙精灵账户",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "sex",
          label: "性别",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "dob",
          label: "生日",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "toothbrushtype",
          label: "牙刷类型",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        },
        {
          key: "status",
          label: "状态",
          sortable: false,
          thStyle: "font-size:14px; background-color: #e0e0e0;"
        }
      ]
    };
  },
  props: {
    parentready: Boolean
  },
  watch: {
    parentready: function() {
      console.log("single patient watcher parent ready");
      this.getFamilyProfileList();
    }
  },
  computed: {
    windowdayTitle() {
      return this.windowday();
    },
    userid() {
      let profile = this.$store.state.selectedprofile;
      if (profile == null) {
        window.location.href = "/#/";
        return;
      }
      let name = "Patient";
      if (profile.externallastname != null) {
        name =
          (profile.externalfirstname != null ? profile.externalfirstname : "") +
          " " +
          profile.externallastname;
      } else if (profile.firstname != null) {
        name =
          profile.firstname +
          " " +
          (profile.lastname != null ? profile.lastname : "");
      }
      return name;
    }
  },
  methods: {
    windowday() {
      var arr = this.timewindow.split(" ");
      return parseInt(arr[1]);
    },
    getprofileimageurl() {
      let profile = this.$store.state.selectedprofile;
      if (profile.avatarid != null) {
        return profile.avatarid;
      }
      return "/imgs/defaultuser@3x.png";
    },
    getProfileName() {
      let profile = this.$store.state.selectedprofile;
      if (profile != null) {
        if (profile.externalfirstname != null) {
          return profile.externalfirstname;
        } else if (profile.firstname != null) {
          return profile.firstname;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getFamilyProfileList() {
      this.isBusy = true;
      var provider = storage.getProvider()
      if (
        provider == null ||
        this.$store.state.selectedprofile == null
      ) {
        window.location.href = "/";
      }

      let providerid = provider.providerid;
      let userid = this.$store.state.selectedprofile.userid;
      let context = this;
      getProfileListByUserId(providerid, userid).then(res => {
        context.profilelist = res.data;
        context.isBusy = false;
      });
    },
    onClickRow(row) {
      console.log("clicked:" + row);
      this.$store.state.selectedprofile = row;
      this.getRecords();
      this.getDeviceTable();

      let profile = this.$store.state.selectedprofile;
      let info = JSON.parse(profile.info);

      this.frequencypaul = (info.countscore * 100).toFixed(0);
      this.averageduration = (info.durationscore * 120).toFixed(0);
      this.completeness = (info.quadrantscore * 100).toFixed(0);
      this.efficiency = (info.efficiencyscore * 100).toFixed(0);
    },
    getRecords() {
      let profile = this.$store.state.selectedprofile;
      if (profile == null) {
        window.location.href = "/";
      }
      // hard code 28 day window for now: TODO
      var date = new Date();
      date.setDate(date.getDate() - 28);
      let context = this;
      this.isBusyRecords = true;

      let threshold2 = 0.07;
      context.records = [];
      //let loader = this.$loading.show();
      var timezone = profile.timezone;
      if (timezone == null) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      getRecords(profile.profileid, date.toISOString(), null).then(res => {
        //loader.hide();
        context.recordcount = res.data.length;
        console.log("get records:" + context.recordcount);
        context.isBusyRecords = false;
        context.chart.durations = [];
        context.chart.logtimes = [];
        context.chart.durationlabel = [];
        context.chart.efficiency = [];
        context.chart.completeness = [];

        let durationmap = {};
        let logtimemap = {};
        let frequencymap = {};
        let frequencyammap = {};
        let frequencypmmap = {};

        let efficiencymap = {};
        let completenessmap = {};
        let lastduration = -1;
        let lastlogtime = -1;
        let lastfrequency = -1;
        let lastfrequencyam = -1;
        let lastfrequencypm = -1;

        let lastefficiency = -1;
        let lastcompleteness = -1;
        let lastindex = null;
        //var dateFormat = require("dateformat");

        for (var i = 0; i < res.data.length; i++) {
          var obj = {};
          let time = res.data[i].createdtime;

          let date = new Date(time);
          //console.log(date.toLocaleString())
          obj.createdtimedisplay = date.toLocaleString("en-US", {
            timeZone: timezone
          });
          obj.createdtime = time;
          let info = JSON.parse(res.data[i].info);

          obj.completeness = (info.quadrantscore * 100).toFixed(0) + "%";
          obj.duration = (info.brushinglength / 10).toFixed(0) + " sec";
          obj.brushingcount = 1;
          obj.efficiency = (info.efficiencyscore * 100).toFixed(0) + "%";
          obj.profileid = res.data[i].profileid;
          context.records.push(obj);

          /* charts */

          // "12/2/2020, 1:23:04 AM"
          var parts = obj.createdtimedisplay.split(",");
          var mon = ("0" + parts[0].trim().split("/")[0]).slice(-2);
          var day = ("0" + parts[0].trim().split("/")[1]).slice(-2);
          var hour = parts[1].trim().split(':')[0];
          hour = parseInt(hour);
          let dateindex = mon + "/" + day;

          var ampm = parts[1].trim().split(" ");
          let isam = ampm[1].trim().toUpperCase() == "AM";
          if(isam && (hour < 3 || hour == 12)){
            isam = false;
            // special treatment for < 3am
            date.setTime(date.getTime() - 3600 * 1000 * 3 - 1);
            var newtimeddisplay = date.toLocaleString("en-US", {
              timeZone: timezone
            });
            parts = newtimeddisplay.split(",");
            mon = ("0" + parts[0].trim().split("/")[0]).slice(-2);
            day = ("0" + parts[0].trim().split("/")[1]).slice(-2);
            dateindex = mon + "/" + day;
          }
          if (dateindex != lastindex) {
            if (lastduration >= 0 && lastindex != null) {
              //context.chart.durations.push(lastduration.toFixed(0));
              //context.chart.logtimes.push(lastlogtime.toFixed(0));
              //context.chart.durationlabel.push(lastindex);
              logtimemap[lastindex] = (lastlogtime / lastfrequency).toFixed(0);
              durationmap[lastindex] = (lastduration / lastfrequency).toFixed(
                0
              );
              frequencymap[lastindex] = lastfrequency;
              frequencyammap[lastindex] = lastfrequencyam;
              frequencypmmap[lastindex] = lastfrequencypm;
              completenessmap[lastindex] = (
                lastcompleteness / lastfrequency
              ).toFixed(1);

              efficiencymap[lastindex] = (
                lastefficiency / lastfrequency
              ).toFixed(1);
            }

            lastduration = info.brushinglength / 10;
            lastlogtime = info.loglength / 10;
            lastfrequency = 1;
            if (isam == true) {
              lastfrequencyam = 1;
              lastfrequencypm = 0;
            } else {
              lastfrequencypm = 1;
              lastfrequencyam = 0;
            }

            lastefficiency = info.efficiencyscore * 100;
            lastcompleteness = info.quadrantscore * 100;
            lastindex = dateindex;
          } else {
            lastduration += info.brushinglength / 10;
            lastlogtime += info.loglength / 10;
            lastfrequency += 1;
            if (isam == true) {
              lastfrequencyam += 1;
            } else {
              lastfrequencypm += 1;
            }
            lastefficiency += info.efficiencyscore * 100;
            lastcompleteness += info.quadrantscore * 100;
          }
        }

        /* chart last row */
        if (lastduration >= 0 && lastindex != null) {
          //context.chart.durations.push(lastduration);
          //context.chart.logtimes.push(lastlogtime);
          //context.chart.durationlabel.push(lastindex);
          logtimemap[lastindex] = (lastlogtime / lastfrequency).toFixed(0);
          durationmap[lastindex] = (lastduration / lastfrequency).toFixed(0);
          frequencymap[lastindex] = lastfrequency;
          frequencyammap[lastindex] = lastfrequencyam;
          frequencypmmap[lastindex] = lastfrequencypm;
          completenessmap[lastindex] = (
            lastcompleteness / lastfrequency
          ).toFixed(1);
          efficiencymap[lastindex] = (lastefficiency / lastfrequency).toFixed(
            1
          );
        }
        //post processing for duration

        context.chart.durations = [];
        context.chart.logtimes = [];
        context.chart.frequencyam = [];
        context.chart.frequencypm = [];
        context.chart.completeness = [];
        context.chart.efficiency = [];
        context.chart.frequencyvacation = [];

        /* retrieve vacation and then process for chart */
        date = new Date();
        var localdatestr = date.toLocaleString("en-US", { timeZone: timezone });
        parts = localdatestr.split(",");
        let month = ("0" + parts[0].trim().split("/")[0]).slice(-2);
        let year = ("0000" + parts[0].trim().split("/")[2]).slice(-4);
        var month1 = year + month;

        date = new Date();
        date.setDate(date.getDate() - 27);
        localdatestr = date.toLocaleString("en-US", { timeZone: timezone });
        parts = localdatestr.split(",");
        month = ("0" + parts[0].trim().split("/")[0]).slice(-2);
        year = ("0000" + parts[0].trim().split("/")[2]).slice(-4);
        var month2 = year + month;
        var queryyearmonth = month1;
        if (month1 != month2) {
          queryyearmonth = queryyearmonth + ";" + month2;
        }

        getProfileVacation(
          this.$store.state.selectedprofile,
          queryyearmonth
        ).then(res => {
          var yearmonth = null;
          if (res.data != null) {
            yearmonth = res.data.trim().split(";");
            console.log(yearmonth);
          }

          for (i = 27; i >= 0; i--) {
            let date = new Date();
            date.setDate(date.getDate() - i);

            var localdatestr = date.toLocaleString("en-US", {
              timeZone: timezone
            });
            parts = localdatestr.split(",");
            mon = ("0" + parts[0].trim().split("/")[0]).slice(-2);
            day = ("0" + parts[0].trim().split("/")[1]).slice(-2);
            year = ("0000" + parts[0].trim().split("/")[2]).slice(-4);
            var isVacation = this.isVacation(year + mon, day, res.data);
            if (isVacation) {
              context.chart.frequencyvacation.push(2);
            } else {
              context.chart.frequencyvacation.push(0);
            }
            let index = mon + "/" + day;
            context.chart.durationlabel.push(index);
            if (durationmap[index] == null) {
              context.chart.durations.push(0);
              context.chart.logtimes.push(0);
              context.chart.frequencyam.push(0);
              context.chart.frequencypm.push(0);
              context.chart.completeness.push(0);
              context.chart.efficiency.push(0);
            } else {
              context.chart.durations.push(durationmap[index]);
              context.chart.logtimes.push(logtimemap[index]);
              if (isVacation) {
                context.chart.frequencyam.push(0);
                context.chart.frequencypm.push(0);
              } else {
                context.chart.frequencyam.push(frequencyammap[index]);
                context.chart.frequencypm.push(frequencypmmap[index]);
              }
              context.chart.completeness.push(completenessmap[index]);
              context.chart.efficiency.push(efficiencymap[index]);
            }
          }

          context.createDurationChart();
          context.createFrequencyChart();
          context.createCompletenessChart();
          context.createEfficiencyChart();
        });

        /* quadrant */
        //var sum = out_up_left + out_down_left + out_up_right + out_down_right + in_up_left + in_down_left + in_up_right + in_down_right;
        //sum = res.data.length * 8 - sum;
        let profile = this.$store.state.selectedprofile;
        let info = JSON.parse(profile.info);

        var out_up_left_r = 0;
        var out_down_left_r = 0;
        var out_up_right_r = 0;
        var out_down_right_r = 0;
        var in_up_left_r = 0;
        var in_down_left_r = 0;
        var in_up_right_r = 0;
        var in_down_right_r = 0;

        if (info.quadrantratio != null) {
          out_up_left_r = parseFloat(info.quadrantratio.split(",")[0]);
          out_up_right_r = parseFloat(info.quadrantratio.split(",")[1]);
          out_down_left_r = parseFloat(info.quadrantratio.split(",")[2]);
          out_down_right_r = parseFloat(info.quadrantratio.split(",")[3]);
          in_up_left_r = parseFloat(info.quadrantratio.split(",")[4]);
          in_up_right_r = parseFloat(info.quadrantratio.split(",")[5]);
          in_down_left_r = parseFloat(info.quadrantratio.split(",")[6]);
          in_down_right_r = parseFloat(info.quadrantratio.split(",")[7]);
        }

        var background = "";
        if (out_up_left_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-upper-right-outer@3x.png) center no-repeat,";
        }
        if (out_down_left_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-lower-right-outer@3x.png) center no-repeat,";
        }
        if (out_up_right_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-upper-left-outer@3x.png) center no-repeat,";
        }
        if (out_down_right_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-lower-left-outer@3x.png) center no-repeat,";
        }
        if (in_up_left_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-upper-right-inner@3x.png) center no-repeat,";
        }
        if (in_down_left_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-lower-right-inner@3x.png) center no-repeat,";
        }
        if (in_up_right_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-upper-left-inner@3x.png) center no-repeat,";
        }
        if (in_down_right_r < threshold2) {
          background +=
            "url(/imgs/image-teeth-lower-left-inner@3x.png) center no-repeat,";
        }
        background +=
          "url(/imgs/image-teeth-whole-with-label@3x.png) center no-repeat";

        //console.log("bg:" + background);
        document.getElementById("pb").style.background = background;
      });
    },
    isVacation(key, day, map) {
      day = parseInt(day);
      if (map == null) return false;
      var yearmonth = map.trim().split(";");
      for (var i = 0; i < yearmonth.length; i++) {
        var row = yearmonth[i].trim();
        if (row.length == 0) continue;
        var token = row.split("=");
        if (token[1].trim() == key.trim()) {
          var binarystr = token[2].trim();
          return binarystr.substring(day - 1, day) == "1";
        }
      }
    },
    getSexHTML(row) {
      if (row.sex && row.sex == "f") {
        return "Female";
      } else {
        return "Male";
      }
    },
    getDobHTML(row) {
      if (row.birthday && row.birthmonth && row.birthyear) {
        var m = "";
        switch (row.birthmonth) {
          case 1:
            m = "Jan";
            break;
          case 2:
            m = "Feb";
            break;
          case 3:
            m = "Mar";
            break;
          case 4:
            m = "Apr";
            break;
          case 5:
            m = "May";
            break;
          case 6:
            m = "Jun";
            break;
          case 7:
            m = "Jul";
            break;
          case 8:
            m = "Aug";
            break;
          case 9:
            m = "Sep";
            break;
          case 10:
            m = "Oct";
            break;
          case 11:
            m = "Nov";
            break;
          case 12:
            m = "Dec";
            break;
        }
        return m + "/" + ("0" + row.birthday).slice(-2) + "/" + row.birthyear;
      } else {
        return "N/A";
      }
    },
    getDisplayName() {
      return this.getNameHTML(this.$store.state.selectedprofile);
    },
    getTimeZone() {
      var timezone = this.$store.state.selectedprofile.timezone;
      if (timezone == null) {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return timezone;
    },
    getNameHTML(row) {
      var lastname = row.externallastname;
      var firstname = row.externalfirstname;
      if (firstname == null || firstname == "") {
        firstname = row.firstname;
      }
      var name = "";
      if (lastname && firstname) {
        name = lastname + ", " + firstname;
      } else if (lastname) {
        name = lastname + ", ";
      } else if (firstname) {
        name = firstname;
      }
      if (name == null || name == "") {
        return "<p style='color:#c6c6c6'>N/A</p>";
      } else {
        return name;
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      let profile = this.$store.state.selectedprofile;
      if (profile != null && item.profileid === profile.profileid)
        return "table-selected";
      else return "table-row";
    },
    onClickRowActivity(item) {
      this.$store.state.selectedactivity = item;
      window.location.href = "/#/activity";
    },
    hideQuadrants() {
      /*
      document.getElementById("in_up_right").style.display = "none";
      document.getElementById("in_up_left").style.display = "none";
      document.getElementById("in_down_right").style.display = "none";
      document.getElementById("in_down_left").style.display = "none";
      document.getElementById("out_up_right").style.display = "none";
      document.getElementById("out_up_left").style.display = "none";
      document.getElementById("out_down_right").style.display = "none";
      document.getElementById("out_down_left").style.display = "none";
      */
    },
    createDurationChart() {
      var ctx = document.getElementById("duration-chart");
      if (this.durationchart) {
        this.durationchart.destroy();
      }
      this.durationchart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.chart.durationlabel,
          datasets: [
            {
              label: "Brush Time (sec)",
              data: this.chart.durations,
              backgroundColor: ["rgba(255, 99, 132, 0.2)"],
              borderColor: ["rgba(255, 99, 132, 1)"],
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });
    },
    createFrequencyChart() {
      var ctx = document.getElementById("frequency-chart");
      if (this.frequencychart) {
        this.frequencychart.destroy();
      }
      this.frequencychart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.chart.durationlabel,
          datasets: [
            {
              label: "AM",
              data: this.chart.frequencyam,
              backgroundColor: "rgba(5, 197, 238, 0.4)",
              borderColor: "rgba(5, 197, 238)",
              borderWidth: 1
            },
            {
              label: "PM",
              data: this.chart.frequencypm,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1
            },
            {
              label: "Away",
              data: this.chart.frequencyvacation,
              backgroundColor: "#e0e0e0",
              borderColor: "#e0e0e0",
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                   stepSize: 1
                },
                precision: 1,
               
                stacked: true
              }
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true
                },
                stacked: true
              }
            ]
          }
        }
      });
    },
    createCompletenessChart() {
      var ctx = document.getElementById("completeness-chart");
      if (this.completenesschart) {
        this.completenesschart.destroy();
      }
      this.completenesschart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.chart.durationlabel,
          datasets: [
            {
              label: "Coverage",
              data: this.chart.completeness,
              backgroundColor: "rgba(255, 99, 132, 0.4)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });
    },
    createEfficiencyChart() {
      var ctx = document.getElementById("efficiency-chart");
      if (this.efficiencychart) {
        this.efficiencychart.destroy();
      }
      this.efficiencychart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.chart.durationlabel,
          datasets: [
            {
              label: "Efficiency",
              data: this.chart.efficiency,
              backgroundColor: "rgba(255, 99, 132, 0.4)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });
    },
    getFWVersion(row) {
      if (row.fwversion) {
        var version = parseInt(row.fwversion, 16);
        return version;
      } else {
        return "--";
      }
    },
    getDeviceID(row) {
      return row.deviceid.slice(-4);
    },
    getDeviceImage(row) {
      //var icon = row.devicedecoration
      var url = "";
      if (row.devicetype == "HUB") {
        url = "hub@3x.png";
      } else {
        if (row.devicedecoration == "DEFAULT") {
          url = "imageDeviceTrackerDefault@3x.png";
        } else if (row.devicedecoration == "FLAMINGO") {
          url = "imageDeviceTrackerFlamingo@3x.png";
        } else if (row.devicedecoration == "UNICORN") {
          url = "imageDeviceTrackerUnicorn@3x.png";
        } else if (row.devicedecoration == "PEACE") {
          url = "imageDeviceTrackerPeace@3x.png";
        } else if (row.devicedecoration == "ASTRONAUT") {
          url = "imageDeviceTrackerAstronaut@3x.png";
        } else if (row.devicedecoration == "CAR") {
          url = "imageDeviceTrackerCar@3x.png";
        } else if (row.devicedecoration == "PEACOCK") {
          url = "imageDeviceTrackerPeacock@3x.png";
        } else if (row.devicedecoration == "ORANGE") {
          url = "imageDeviceTrackerOrange@3x.png";
        } else if (row.devicedecoration == "DINOSAUR") {
          url = "imageDeviceTrackerDinosaur@3x.png";
        } else if (row.devicedecoration == "HEART") {
          url = "imageDeviceTrackerHeart@3x.png";
        } else {
          url = "imageDeviceTrackerDefault@3x.png";
        }
      }
      //console.log(url);
      var html =
        "<img src='/imgs/" +
        url +
        "' style='width:60px; max-width:90px'></img>";

      return html;
    },
    getDeviceDate(time) {
      var date = new Date(time);
      return date.toLocaleString();
    },
    getBatteryHTML(row) {
      if (row.battery) {
        return (parseInt(row.battery, 16) / 1000.0).toFixed(2) + "v";
      } else {
        return "--";
      }
    },
    getDeviceTemp(row) {
      var tempinc = parseFloat(row.temperatureinc);
      var offset = parseFloat(row.temperatureoffset);
      var tempinf = (tempinc * 9) / 5 + 32 + offset;
      if (isNaN(tempinf)) {
        return "";
      }
      return tempinf.toFixed(0) + "&#8457;";
    },
    getDeviceTable() {
      let providerid = storage.getProvider().providerid;
      let userid = this.$store.state.selectedprofile.userid;
      var context = this;
      context.isBusy2 = true;
      var profile = this.$store.state.selectedprofile;
      getDeviceList(providerid, userid)
        .then(res => {
          context.isBusy2 = false;
          context.devicelist = res.data.filter(
            elem =>
              elem.profileid == profile.profileid || elem.devicetype == "HUB"
          );
        })
        .catch(function() {
          context.loader = false;
        });
    },
    getCustomer() {
      getCustomer(
        storage.getProvider().providerid,
        this.$store.state.selectedprofile.userid
      ).then(res => {
        console.log(res);
        if (res.data.location != null) {
          var obj = JSON.parse(res.data.location);
          if (obj.city != null) {
            if(obj.region != null){
              this.displaylocation = obj.city + "/" + obj.region;
            }else if(obj.region_name!=null){
              this.displaylocation = obj.city + "/" + obj.region_name;
            }
          }
        }
      });
    }
  },

  mounted: function() {
    console.log("single patient mounted");
    this.getFamilyProfileList();
    this.hideQuadrants();
    this.getRecords();
    this.getDeviceTable();
    this.getCustomer();

    let profile = this.$store.state.selectedprofile;
    let info = JSON.parse(profile.info);

    this.frequencypaul = (info.countscore * 100).toFixed(0);
    this.averageduration = (info.durationscore * 120).toFixed(0);
    this.completeness = (info.quadrantscore * 100).toFixed(0);
    this.efficiency = (info.efficiencyscore * 100).toFixed(0);
  }
};
</script>

<style>
.breadcrumb-item a {
  color: #05c5ee;
}

.breadcrumb-item {
  font-size: 14px;
}

.breadcrumb {
  background-color: transparent !important;
}

.profileicon {
  width: 48px;
  height: 48px;
}
.charttabview .nav-link {
  background-color: #e0e0e0;
  color: #161616;
}
.charttabview .nav-link.active {
  background-color: white;
  color: #161616;
}
.table-selected {
  background-color: rgba(5, 197, 238, 0.1);
}

.quadrantclass {
  height: 100%;
  max-height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#pb {
  background-size: auto 100% !important;
  height: 100%;
}

.canvas-patient {
  max-height: 350px;
}
</style>