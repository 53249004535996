import http from './http'


const prefix = '/provider'

export function getProviderInfo(phone, providerkey) {

    let url = prefix + '/phone/' + phone


    return http.get(url, {
        headers: {
            'Authorization': providerkey
        }
    })
}


export function updateProvider(m_provider) {
    let url = prefix + "/" + m_provider.providerid
    return http.post(url, m_provider, {
        headers: {
            'Authorization': m_provider.providerkey
        }
    })
}

export function uploadProviderImage(m_providerimage) {
    let url = prefix + "/image"
    return http.post(url, m_providerimage, {})
}