// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.jumbotron[data-v-c34a5a8e] {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-position: center top;\r\n  background-repeat: no-repeat;\r\n  margin-bottom: 0px !important;\r\n  background-size: 100% auto;\r\n  background-color: white;\n}\n.welcome-container[data-v-c34a5a8e] {\r\n  background-color: rgb(249, 253, 254);\r\n  border-radius: 5px 5px;\r\n  padding-top: 80px;\r\n  padding-left: 40px;\r\n  padding-right: 40px;\n}\r\n", ""]);
// Exports
module.exports = exports;
