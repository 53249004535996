var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", { attrs: { show: _vm.loader, rounded: "md" } }, [
    _c(
      "div",
      { staticClass: "jumbotron d-flex align-items-center min-vh-100" },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "col-sm login-container",
                staticStyle: { "max-width": "600px" }
              },
              [
                _c("div", { staticClass: "row headerBlueBackground" }, [
                  _c("div", { staticClass: "col-sm" }, [
                    _c(
                      "div",
                      { staticClass: "row mt-3" },
                      [
                        _c("b-img", {
                          attrs: {
                            center: "",
                            src: require("../assets/image/truthbrush-icon.png"),
                            alt: "Center image",
                            width: "80px",
                            height: "80px"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row", staticStyle: { color: "white" } },
                      [
                        _c("b-img", {
                          attrs: {
                            center: "",
                            src: require("../assets/image/candibell-truthbrush.png"),
                            alt: "Center image",
                            width: "154px",
                            height: "56px"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row mb-3 mt-3",
                        staticStyle: { color: "white" }
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "text-center w-100",
                            staticStyle: { "font-size": "0.8em" }
                          },
                          [
                            _vm._v(
                              " Improve patient care by focusing on prevention. "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row mt-4" }, [
                  _c("div", { staticClass: "col-sm login-container" }, [
                    _c(
                      "div",
                      { staticClass: "row d-flex justify-content-center" },
                      [
                        _c("h2", { staticClass: "semi-bold" }, [
                          _vm._v("牙医端入口")
                        ])
                      ]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      {
                        staticClass:
                          "row pl-4 pr-4 d-flex justify-content-center"
                      },
                      [
                        _vm.show
                          ? _c(
                              "b-form",
                              {
                                staticClass: "w-75",
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.onSubmit($event)
                                  },
                                  reset: _vm.onReset
                                }
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "text-left",
                                    attrs: {
                                      id: "input-group-1",
                                      label: "手机号",
                                      "label-for": "input-1",
                                      description: ""
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "input-1",
                                        type: "number",
                                        required: "",
                                        placeholder: "请输入11位手机号"
                                      },
                                      model: {
                                        value: _vm.form.phone,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "phone", $$v)
                                        },
                                        expression: "form.phone"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "ml-0" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mb-3 blueButton",
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.getsms()
                                          }
                                        }
                                      },
                                      [_vm._v("获得验证码")]
                                    ),
                                    _vm.countdown > 0
                                      ? _c("div", { staticClass: "pl-5" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.countdown) +
                                              "秒后才可以重新发送 "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                !_vm.stage1
                                  ? _c(
                                      "b-form-group",
                                      {
                                        staticClass: "text-left",
                                        attrs: {
                                          id: "input-group-2",
                                          label: "验证码",
                                          "label-for": "input-2",
                                          description:
                                            "安全登录. Your ID and credential are strongly encrypted and protected by TLS/SSL. "
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "number",
                                            id: "input-2",
                                            placeholder: "验证码"
                                          },
                                          model: {
                                            value: _vm.form.code,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "code", $$v)
                                            },
                                            expression: "form.code"
                                          }
                                        }),
                                        _c(
                                          "b-form-invalid-feedback",
                                          { attrs: { state: _vm.check } },
                                          [_vm._v(_vm._s(_vm.error))]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.stage1
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "blueButton",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.signin()
                                          }
                                        }
                                      },
                                      [_vm._v("登入")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center mt-2" },
                      [
                        _c("a", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.error))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row justify-content-center mt-2",
                        attrs: { hidden: "" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "aquaBlue",
                            attrs: { href: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.onSignup($event)
                              }
                            }
                          },
                          [_vm._v("New to Candibell? Create a free account.")]
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        false
          ? _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm" }),
                _c("div", { staticClass: "col-sm" })
              ])
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }