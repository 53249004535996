<template>
  <div class="jumbotron d-flex align-items-center min-vh-100">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center" v-if="step1">
        <div class="col-sm welcome-container" style="max-width:668px">
          <div class="row d-flex justify-content-center">
            <h1
              class="semi-bold text-center"
            >Success! You are now part of the Candibell TruthBrush network.</h1>
          </div>

          <div class="row d-flex justify-content-center text-center ml-5 mr-5 mt-5">
            The TruthBrush is a new preventative care tool that allows you to provide the best in oral care. Use the TruthBrush to monitor and coach your patients brush habits. Remember to check your email for patient monitoring requests.
            <br />
            <br />Candibell platform offers information for dentists on structuring a preventative care program. Contact Candibell today to acquire TruthBrush products for your patients at:
            <br />
            <a href="https://www.candibell.cn">https://www.candibell.cn</a>
          </div>

          <div class="row d-flex justify-content-center ml-5 mr-5 mt-5">
            <b-img
              center
              src='imgs/qrcode.png'
              width="120px"
              height="120px"
            ></b-img>
          </div>
          <div class="row d-flex justify-content-center ml-5 mr-5 mt-5">
            <div class="col">
              <a href='https://apps.apple.com/us/app/truthbrush/id1521192393'>
              <b-img
                right
                :src="require('../assets/image/ios-store.png')"
                fluid
                alt="Fluid image"
                width="121px"
                height="36px"
              ></b-img>
              </a>
            </div>
            <div class="col">
              <a href='https://play.google.com/store/apps/details?id=com.candibell.brush'>
              <b-img
                left
                :src="require('../assets/image/google-play.png')"
                fluid
                alt="Fluid image"
                width="121px"
                height="36px"
              ></b-img>
              </a>
            </div>
          </div>

          <div
            class="row d-flex justify-content-center text-center ml-5 mr-5 mt-5"
          >Download the TruthBrush application today to view your listing and have your patients experience the TruthBrush!</div>

          <div class="row d-flex justify-content-center text-center ml-5 mr-5 mt-5 mb-5">
            <b-button
              class="blueButton"
              type="submit"
              variant="primary"
              style="width: 100%; height:40px; font-size:16px"
              @click="getstart()"
            >注册牙医信息</b-button>
          </div>
        </div>
      </div>

      <div class="row d-flex justify-content-center" v-if="!step1">
        <div class="col-sm welcome-container" style="max-width:668px">
          <b-form @submit.prevent="onSubmit" @reset="onReset">
            <div class="row d-flex">
              <div class="col">
                <h2 class="semi-bold">基础信息</h2>
              </div>
            </div>
            <div class="row d-flex" style="width:80%">
              <div
                class="col"
                style="color:#939393;font-size:14px"
              >您的信息将会显示在Candibell的手机App中，以便于您的病人浏览您的主页和与您分享数据</div>
            </div>

            <div class="row d-flex mt-4">
              
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="姓 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.lastname" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="名字 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.firstname" type="text" required></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group class="text-left" label="称呼 *" style="font-size: 12px !important;">
                  <b-form-select v-model="form.title" type="text" :options="titles" required></b-form-select>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-left" label="牙医类 *" style="font-size: 12px !important;">
                  <b-form-select v-model="form.type" type="text" :options="types" required></b-form-select>
                </b-form-group>
              </div>
            </div>

           

            <div class="row d-flex mt-5">
              <div class="col">
                <h2 class="semi-bold">工作信息</h2>
              </div>
            </div>
            <div class="row d-flex" style="width:80%">
              <div
                class="col"
                style="color:#939393;font-size:14px"
              >请填写您的工作信息，这些信息将让客人更加方便的找到您</div>
            </div>

            <b-overlay :show="loader2" rounded="lg">
              <div class="row d-flex pt-4 pb-4 align-items-end">
                <div class="col-3">
                  <div
                    class="circle-avatar"
                    v-bind:style="{ 'background-image': 'url(' + getprofileimageurl() + ')' }"
                  ></div>
                </div>
                <div class="col pb-3">
                  <b-form-file
                    accept=".jpg, .png, .gif"
                    class="mt-3"
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="请选择和添加头像..."
                    drop-placeholder="Drop image here..."
                  ></b-form-file>
                </div>
              </div>
            </b-overlay>

            <div class="row d-flex mt-4">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="诊所名称 *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.practicename" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col" hidden>
                <b-form-group class="text-left" label="Email *" style="font-size: 12px !important;">
                  <b-form-input v-model="form.provideremail" type="email" readonly></b-form-input>
                </b-form-group>
              </div>
            </div>

            

            <div class="row d-flex mt-2">
              
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="地址1 *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.address1" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="地址2 (可选)"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.address2" type="text"></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group class="text-left" label="城市 *" style="font-size: 12px !important;">
                  <b-form-input v-model="form.city" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-left" label="省/直辖市/自治区 *" style="font-size: 12px !important;">
                 <b-form-input v-model="form.state" type="text" required></b-form-input>
                </b-form-group>
              </div>
              
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="邮政编码 *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.zipcode" type="number" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="手机号码 (11位) *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.phone" type="text" required></b-form-input>
                </b-form-group>
              </div>
              
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="网站 (可选)"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.website" type="text"></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="执照 # (可选)"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.license" type="text"></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="描述"
                  style="font-size: 12px !important;"
                  description="请简单描述您的牙医诊所，医院，以及您的服务或者特长"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="form.description"
                    placeholder
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2 pb-4 justify-content-end">
              <div class="col-2">
                <b-button
                  variant="outline-primary"
                  size="lg"
                  style="border-color:#05c5ee;color:#05c5ee"
                  @click="cancel()"
                >取消</b-button>
              </div>
              <div class="col-4">
                <b-button
                  class="blueButton"
                  type="submit"
                  variant="primary"
                  size="lg"
                  style="width: 100%;  font-size:16px"
                >保存</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <canvas id="canvas" style="display:none"></canvas>
  </div>
</template>

<script>
import { updateProvider, uploadProviderImage } from "../api/provider";
import storage from "./../storage";
export default {
  name: "create-prfile",
  computed: {},
  data() {
    return {
      loader2: false,
      step1: true,
      file: null,
      form: {
        firstname: "",
        lastname: "",
        prefix: "",
        suffix: "",
        practicename: "",
        provideremail: "",
        license: "",
        address1: "",
        address2: "",
        state: "",
        zipcode: "",
        phone: "",
        website: "",
        description: "",
        type: "家庭牙医",
        title: "先生",
        providerid: "",
        provideravatar: ""
      },
      types: [
        "家庭牙医",
        "儿童牙医",
        "牙医助理",
        "整形外科牙医",
        "美容牙医",
      ],
      titles: [
        "医生",
        "医师",
        "助理",
        "牙医",
        "教授",
        "女士",
        "先生",
      ],
      states: [
        "AK - Alaska",
        "AL - Alabama",
        "AR - Arkansas",
        "AS - American Samoa",
        "AZ - Arizona",
        "CA - California",
        "CO - Colorado",
        "CT - Connecticut",
        "DC - District of Columbia",
        "DE - Delaware",
        "FL - Florida",
        "GA - Georgia",
        "GU - Guam",
        "HI - Hawaii",
        "IA - Iowa",
        "ID - Idaho",
        "IL - Illinois",
        "IN - Indiana",
        "KS - Kansas",
        "KY - Kentucky",
        "LA - Louisiana",
        "MA - Massachusetts",
        "MD - Maryland",
        "ME - Maine",
        "MI - Michigan",
        "MN - Minnesota",
        "MO - Missouri",
        "MS - Mississippi",
        "MT - Montana",
        "NC - North Carolina",
        "ND - North Dakota",
        "NE - Nebraska",
        "NH - New Hampshire",
        "NJ - New Jersey",
        "NM - New Mexico",
        "NV - Nevada",
        "NY - New York",
        "OH - Ohio",
        "OK - Oklahoma",
        "OR - Oregon",
        "PA - Pennsylvania",
        "PR - Puerto Rico",
        "RI - Rhode Island",
        "SC - South Carolina",
        "SD - South Dakota",
        "TN - Tennessee",
        "TX - Texas",
        "UT - Utah",
        "VA - Virginia",
        "VI - Virgin Islands",
        "VT - Vermont",
        "WA - Washington",
        "WI - Wisconsin",
        "WV - West Virginia",
        "WY - Wyoming"
      ]
    };
  },
  mounted() {
    let provider = storage.getProvider();
    if (provider) {
      this.form.firstname = provider.providername;
      this.form.lastname = provider.providerlastname;
      this.form.prefix = provider.providerprefix;
      this.form.suffix = provider.providersuffix;
      this.form.practicename = provider.providerofficename;
      this.form.provideremail = provider.provideremail;
      this.form.license = provider.providerlicense;
      this.form.address1 = provider.providerofficeaddress1;
      this.form.address2 = provider.providerofficeaddress2;
      this.form.state = provider.providerofficestate;
      this.form.zipcode = provider.providerofficezipcode;
      this.form.phone = provider.providerofficephone;
      this.form.city = provider.providerofficecity;
      this.form.website = provider.providerwebsite;
      this.form.description = provider.providerdescription;
      this.form.type = provider.providercaption;
      this.form.title = provider.providertitle;
      this.form.providerid = provider.providerid;
      this.form.provideravatar = provider.provideravatar;
      this.form.phone = provider.providerphone;
      if(this.form.phone!=null && this.form.phone.length>0){
        if(provider.providerphone.includes('-')){
          this.form.phone = provider.providerphone.split('-')[1];
        }
      }
    }
  },
  watch: {
    file: function() {
      let size = this.file.size;
      if (size > 20000 * 1000) {
        alert("File size too large. Please use an image less than 20Mb.");
        return;
      }
      var obj = {};
      obj.extention = this.file.name.split(".").pop();
      var fr = new FileReader();
      let context = this;
      this.loader2 = true;
      fr.onload = function() {
        var img = new Image();
        img.onload = function() {
          var canvas = document.getElementById("canvas");
          canvas.width = 200;
          canvas.height = (canvas.width * img.height) / img.width;
          canvas
            .getContext("2d")
            .drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            function(blob) {
              var fr2 = new FileReader();
              fr2.onload = function() {
                var data = fr2.result;
                var array = new Int8Array(data);
                var str = Array.from(array, function(byte) {
                  return ("0" + (byte & 0xff).toString(16)).slice(-2);
                }).join("");
                obj.binaystring = str;
                uploadProviderImage(obj)
                  .then(res => {
                    console.log(res);
                    context.form.provideravatar = res.data;
                    context.loader2 = false;
                  })
                  .catch(function(res) {
                    console.log(res);
                    context.loader2 = false;
                  });
              };
              fr2.readAsArrayBuffer(blob);
            },
            "image/jpeg",
            0.9
          );
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(this.file);
    }
  },
  methods: {
    getstart() {
      this.step1 = false;
    },
    getprofileimageurl() {
      if (this.form.provideravatar == null || this.form.provideravatar == "") {
        return require("../assets/image/dentist-profile-icon@3x.png");
      } else {
        return this.form.provideravatar;
      }
    },
    onReset() {},
    cancel() {
      this.step1 = true;
      this.$router.push("/");
    },
    onSubmit() {
      let obj = {};
      obj.providername = this.form.firstname;
      obj.providerlastname = this.form.lastname;
      obj.providertitle = this.form.title;
      obj.providerprefix = this.form.prefix;
      obj.providersuffix = this.form.suffix;
      obj.providerlicense = this.form.license;
      obj.providerdescription = this.form.description;
      obj.provideremail = this.form.provideremail;
      obj.providerwebsite = this.form.website;
      obj.providerofficename = this.form.practicename;
      obj.providerofficeaddress1 = this.form.address1;
      obj.providerofficeaddress2 = this.form.address2;
      obj.providerofficephone = this.form.phone;
      obj.providerofficecity = this.form.city;
      obj.providerofficestate = this.form.state;
      obj.providerofficezipcode = this.form.zipcode;
      obj.providercaption = this.form.type;
      obj.providerid = this.form.providerid;
      obj.provideravatar = this.form.provideravatar;
      obj.providerphone = "86-"+this.form.phone.trim();
      let loader = this.$loading.show();
      updateProvider(obj)
        .then(res => {
          console.log(res);
          this.step1 = true;
          storage.saveProvider( res.data);
          loader.hide();
          this.$router.push("/");
        })
        .catch(function() {
          loader.hide();
        });
    }
  }
};
</script>

<style scoped>
.jumbotron {
  background-image: url(../assets/image/bg.png);
  background-position: center top;
  background-repeat: no-repeat;
  margin-bottom: 0px !important;
  background-size: 100% auto;
  background-color: white;
}

.welcome-container {
  background-color: rgb(249, 253, 254);
  border-radius: 5px 5px;
  padding-top: 80px;
  padding-left: 40px;
  padding-right: 40px;
}
</style>

<style>
.form-group label {
  color: #393939;
  font-size: 12px !important;
}

.form-control-file {
  font-size: 12px;
}

.circle-avatar {
  /* make it responsive */
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
  /* div height to be the same as width*/
  padding-top: 100%;

  /* make it a circle */
  border-radius: 50%;

  /* Centering on image`s center*/
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;

  /* it makes the clue thing, takes smaller dimension to fill div */
  background-size: cover;

  /* it is optional, for making this div centered in parent*/
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
