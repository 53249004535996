<template>
  <b-overlay :show="loader" rounded="md">
    <div class="jumbotron d-flex align-items-center min-vh-100">
      <div class="container-fluid">
        <div class="row d-flex justify-content-center">
          <div class="col-sm login-container" style="max-width: 600px">
            <div class="row headerBlueBackground">
              <div class="col-sm">
                <div class="row mt-3">
                  <b-img
                    center
                    :src="require('../assets/image/truthbrush-icon.png')"
                    alt="Center image"
                    width="80px"
                    height="80px"
                  ></b-img>
                </div>
                <div class="row" style="color: white">
                  <b-img
                    center
                    :src="require('../assets/image/candibell-truthbrush.png')"
                    alt="Center image"
                    width="154px"
                    height="56px"
                  ></b-img>
                </div>
                <div class="row mb-3 mt-3" style="color: white">
                  <p class="text-center w-100" style="font-size: 0.8em">
                    Improve patient care by focusing on prevention.
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm login-container">
                <div class="row d-flex justify-content-center">
                  <h2 class="semi-bold">牙医端入口</h2>
                </div>
                <br />
                <div class="row pl-4 pr-4 d-flex justify-content-center">
                  <b-form
                    class="w-75"
                    @submit.prevent="onSubmit"
                    @reset="onReset"
                    v-if="show"
                  >
                    <b-form-group
                      class="text-left"
                      id="input-group-1"
                      label="手机号"
                      label-for="input-1"
                      description
                    >
                      <b-form-input
                        id="input-1"
                        v-model="form.phone"
                        type="number"
                        required
                        placeholder="请输入11位手机号"
                      ></b-form-input>
                    </b-form-group>
                    <b-row class="ml-0">
                      <b-button
                        type="submit"
                        variant="primary"
                        class="mb-3 blueButton"
                        @click="getsms()"
                        >获得验证码</b-button
                      >

                      <div class="pl-5" v-if="countdown > 0">
                        {{ countdown }}秒后才可以重新发送
                      </div>
                    </b-row>
                    <b-form-group
                      id="input-group-2"
                      class="text-left"
                      label="验证码"
                      label-for="input-2"
                      description="安全登录. Your ID and credential are strongly encrypted and protected by TLS/SSL. "
                      v-if="!stage1"
                    >
                      <b-form-input
                        type="number"
                        id="input-2"
                        v-model="form.code"
                        placeholder="验证码"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="check">{{
                        error
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-button
                      class="blueButton"
                      type="submit"
                      variant="primary"
                      style="width: 100%"
                      @click="signin()"
                      v-if="!stage1"
                      >登入</b-button
                    >
                  </b-form>
                </div>
                <div class="row justify-content-center mt-2">
                  <a class="" style="color: red">{{ error }}</a>
                </div>
                <div class="row justify-content-center mt-2" hidden>
                  <a class="aquaBlue" href @click.prevent="onSignup"
                    >New to Candibell? Create a free account.</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" v-if="false">
        <div class="row">
          <div class="col-sm"></div>

          <div class="col-sm"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import storage from "./../storage";
import { auth1, auth2 } from "../api/auth";

// reference: https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js
export default {
  name: "login",
  data() {
    return {
      show: true,
      loader: false,
      check: true,
      error: "",
      countdown: 0,
      stage1: true,
      form: {
        phone: "",
        code: "",
      },
      cognitoUser: null,
    };
  },

  computed: {
    getButtonName() {
      if (this.stage1 == true) {
        return "Log in";
      } else {
        return "Confirm Code";
      }
    },
  },

  mounted() {
    this.stage1 = true
  },

  methods: {
    getsms() {
      if (this.countdown > 0) {
        return;
      }
      var context = this;
      context.loader = true;
      context.error = "";
      var phone = this.form.phone;
      this.countdown = 15;
      setInterval(function () {
        if (context.countdown > 0) {
          context.countdown -= 1;
        }
      }, 1000);
      auth1("86-" + phone)
        .then((res) => {
          console.log(res);
          context.stage1 = false;
          context.loader = false;
        })
        .catch(function (err) {
          console.log(err);
          context.error = "手机号码错误。请输入11位手机号码";
          context.show = true;
          context.stage1 = true;
          context.loader = false;
        });
    },
    signin() {
      var context = this;
      context.show = true;
      context.loader = true;
      var phone = this.form.phone;
      var sms = this.form.code;
      auth2("86-" + phone, sms)
        .then((res) => {
          console.log(res);
          context.stage1 = false;
          storage.saveProvider(res.data);
          context.loader = false;
          window.location.href = "/#/patients";
        })
        .catch(function (err) {
          console.log(err);
          context.error = err;
          context.show = false;
          context.stage1 = true;
          context.loader = false;
        });
    },
    reseterrortext() {
      this.check = true;
      this.error = "";
    },
    onSubmit: function () {},
    onReset: function () {
      this.form.email = "";
      this.form.password = "";
    },
    onForget: function () {
      this.$router.push("/forget");
    },
    onSignup: function () {
      this.$router.push("/signup");
    },
  },
};
</script>

<style scoped>
.jumbotron {
  background-image: url(../assets/image/bg.png);
  background-position: fit;
  background-repeat: repeat-x;
  margin-bottom: 0;
}

.login-container {
  background-color: white;
  padding-bottom: 25px;
  border-radius: 5px;
}

.headerBlueBackground {
  background-color: #05c5ee;
  border-radius: 5px 5px 0 0;
}
.forgetpassword {
  margin-top: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>