var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loader, rounded: "lg" } },
    [
      _c(
        "b-container",
        {
          staticStyle: { "background-color": "#f3f3f3", height: "100%" },
          attrs: { fluid: "" }
        },
        [
          _c(
            "b-row",
            { staticClass: "pl-2 pr-2 pt-3" },
            [
              _c("b-breadcrumb", {
                staticStyle: { color: "#05c5ee" },
                attrs: { items: _vm.breadcrumbitems() }
              })
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2 pl-4 pr-4" },
            [
              _c(
                "b-form",
                {
                  staticStyle: { width: "100%" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    },
                    reset: _vm.onReset
                  }
                },
                [
                  _c("b-row", [
                    _c("div", { staticClass: "col" }, [
                      _c("h2", { staticClass: "semi-bold" }, [
                        _vm._v("病人基本信息")
                      ])
                    ])
                  ]),
                  _c("b-row", [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        staticStyle: { color: "#939393", "font-size": "14px" }
                      },
                      [_vm._v("Information about this patient.")]
                    )
                  ]),
                  _c(
                    "b-row",
                    {
                      staticClass: "mt-2 ml-0 mr-0 pt-3 pb-3",
                      staticStyle: {
                        "max-width": "820px",
                        "background-color": "white",
                        border: "solid 1px #e6eaee"
                      }
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "病人 ID",
                                "label-class": "form-label",
                                "label-for": "input-1",
                                description:
                                  "An ID or reference number used in your dental management system to uniquely identify this patient"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.form.externalpatientid,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "externalpatientid", $$v)
                                  },
                                  expression: "form.externalpatientid"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "姓",
                                "label-class": "form-label",
                                "label-for": "input-1",
                                description: "e.g. Smith"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "text", required: "" },
                                model: {
                                  value: _vm.form.externallastname,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "externallastname", $$v)
                                  },
                                  expression: "form.externallastname"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "名",
                                "label-class": "form-label",
                                "label-for": "input-1",
                                description: "e.g. Eric"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "text", required: "" },
                                model: {
                                  value: _vm.form.calculatedfirstname,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "calculatedfirstname",
                                      $$v
                                    )
                                  },
                                  expression: "form.calculatedfirstname"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-row", { staticClass: "mt-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        staticStyle: { color: "#939393", "font-size": "14px" }
                      },
                      [_vm._v("病人家庭信息")]
                    )
                  ]),
                  _c(
                    "b-row",
                    {
                      staticClass: "mt-2 ml-0 mr-0 pt-3 pb-3",
                      staticStyle: {
                        "max-width": "820px",
                        "background-color": "white",
                        border: "solid 1px #e6eaee"
                      }
                    },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "家庭 ID",
                                "label-class": "form-label",
                                "label-for": "input-1",
                                description: "这个ID将可以映射家庭中每个成员"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.customer.externalid,
                                  callback: function($$v) {
                                    _vm.$set(_vm.customer, "externalid", $$v)
                                  },
                                  expression: "customer.externalid"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "电话",
                                "label-class": "form-label",
                                "label-for": "input-1",
                                description: "家庭主成员的手机电话"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "tel" },
                                model: {
                                  value: _vm.customer.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.customer, "phone", $$v)
                                  },
                                  expression: "customer.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "地址",
                                "label-class": "form-label",
                                "label-for": "input-1",
                                description: "家庭地址"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.customer.shippingline1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.customer, "shippingline1", $$v)
                                  },
                                  expression: "customer.shippingline1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "城市",
                                "label-class": "form-label",
                                "label-for": "input-1"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.customer.shippingcity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.customer, "shippingcity", $$v)
                                  },
                                  expression: "customer.shippingcity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2 ml-0 mr-0 pt-3 pb-3" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "blueButton",
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            type: "submit",
                            variant: "primary",
                            size: "lg"
                          }
                        },
                        [_vm._v("存储")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-row", { staticClass: "mt-5" }, [
            _c("div", { staticClass: "col" }, [
              _c("h2", { staticClass: "semi-bold" }, [
                _vm._v("爱牙精灵 配置信息")
              ])
            ])
          ]),
          _c("b-row", [
            _c(
              "div",
              {
                staticClass: "col",
                staticStyle: { color: "#939393", "font-size": "14px" }
              },
              [_vm._v("Information from Truthbrush App")]
            )
          ]),
          _c(
            "b-form",
            {
              staticStyle: { width: "100%" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit2($event)
                },
                reset: _vm.onReset2
              }
            },
            [
              _c(
                "b-row",
                {
                  staticClass: "mt-3 mb-4 ml-0 mr-0 pt-3 pb-5",
                  staticStyle: {
                    "max-width": "820px",
                    "background-color": "white",
                    border: "solid 1px #e6eaee"
                  }
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "pt-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "auto" } }, [
                            _c("div", {
                              staticClass: "circle-avatar profileicon",
                              style: {
                                "background-image":
                                  "url(" + _vm.getprofileimageurl() + ")"
                              }
                            })
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "ml-0 pl-5",
                              attrs: { cols: "6", "align-self": "center" }
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "profilelabel" },
                                        [_vm._v("爱牙精灵 用户ID")]
                                      ),
                                      _c("b-row", [
                                        _vm._v(_vm._s(_vm.getprofileID()))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 ml-0 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("账号")
                      ]),
                      _c("b-row", { staticClass: "pt-2" }, [
                        _vm._v(_vm._s(_vm.getUserId))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 ml-0 pl-5", attrs: { cols: "6" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                label: "生日",
                                "label-class": "form-label",
                                "label-for": "input-1"
                              }
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "graybg",
                                attrs: { type: "date" },
                                model: {
                                  value: _vm.dob,
                                  callback: function($$v) {
                                    _vm.dob = $$v
                                  },
                                  expression: "dob"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-4 ml-0 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("性别")
                      ]),
                      _c("b-row", [_vm._v(_vm._s(_vm.getSexHTML))])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-4 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("状态")
                      ]),
                      _c("b-row", [_vm._v("Active")])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 ml-0 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("用户建立时间 (用户本地时间)")
                      ]),
                      _c("b-row", [_vm._v(_vm._s(_vm.getProfileCreateDate))])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("牙刷类型")
                      ]),
                      _c("b-row", [_vm._v(_vm._s(_vm.getBrushType))])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("左右手")
                      ]),
                      _c("b-row", [_vm._v(_vm._s(_vm.getHandedness()))])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("用户最新更新时间 (用户本地时间)")
                      ]),
                      _c("b-row", [_vm._v(_vm._s(_vm.getLastActivity()))])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 ml-0 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("时长定时器")
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "pt-2" },
                        [
                          _c("b-input-group", {
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c("b-form-select", {
                                      attrs: { options: _vm.buzzertimeroption },
                                      model: {
                                        value: _vm.buzzertimer,
                                        callback: function($$v) {
                                          _vm.buzzertimer = $$v
                                        },
                                        expression: "buzzertimer"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-5 pl-5 pr-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("是否通知刷牙事件")
                      ]),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                description:
                                  "如果是，当此用户每次刷完牙时，用户家长将通过app收到推送",
                                "label-class": "form-label",
                                "label-for": "input-1"
                              }
                            },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  name: "check-button",
                                  switch: "",
                                  variant: "primary",
                                  size: "lg"
                                },
                                model: {
                                  value: _vm.perconsumptionnotification,
                                  callback: function($$v) {
                                    _vm.perconsumptionnotification = $$v
                                  },
                                  expression: "perconsumptionnotification"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-3 pl-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("时区")
                      ]),
                      _c("b-row", { staticClass: "pt-2" }, [
                        _vm._v(_vm._s(_vm.getTimezone))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mt-3 pl-5 pr-5", attrs: { cols: "6" } },
                    [
                      _c("b-row", { staticClass: "profilelabel" }, [
                        _vm._v("忘记刷牙提醒")
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "pt-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              staticStyle: { "font-size": "12px !important" },
                              attrs: {
                                description:
                                  "当目标用户没有及时完成刷牙时，家长通过app收到推送通知。您可以设置早上和晚上一共两个时间点。",
                                "label-class": "form-label",
                                "label-for": "input-1"
                              }
                            },
                            [
                              _c("b-input-group", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend",
                                    fn: function() {
                                      return [
                                        _c("b-form-select", {
                                          attrs: { options: _vm.amhroptions },
                                          model: {
                                            value: _vm.amhr,
                                            callback: function($$v) {
                                              _vm.amhr = $$v
                                            },
                                            expression: "amhr"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c("b-form-select", {
                                          attrs: { options: _vm.pmhroptions },
                                          model: {
                                            value: _vm.pmhr,
                                            callback: function($$v) {
                                              _vm.pmhr = $$v
                                            },
                                            expression: "pmhr"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-0 ml-0 mr-0 pt-0 pb-5" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "blueButton",
                      staticStyle: { "font-size": "16px" },
                      attrs: { type: "submit", variant: "primary", size: "lg" }
                    },
                    [_vm._v("存储")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-row", { staticClass: "mt-5 d-none" }, [
            _c("div", { staticClass: "col" }, [
              _c("h2", { staticClass: "semi-bold" }, [
                _vm._v("Trackers and Hubs")
              ])
            ])
          ]),
          _c("b-row", { staticClass: "mt-0 d-none" }, [
            _c(
              "div",
              {
                staticClass: "col",
                staticStyle: { color: "#939393", "font-size": "14px" }
              },
              [_vm._v("Information about Truthbrush trackers and hubs.")]
            )
          ]),
          _c(
            "b-row",
            { staticClass: "mt-4 ml-0 mr-0 pb-4 mb-5 d-none" },
            [
              _c("b-table", {
                staticStyle: { "background-color": "white" },
                attrs: {
                  id: "device-table",
                  fields: _vm.fields,
                  items: _vm.devicelist,
                  hover: "",
                  "sort-icon-left": "",
                  busy: _vm.isBusy
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-busy",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "text-center my-2",
                            staticStyle: { color: "#05c5ee" }
                          },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _c("strong", [_vm._v(" Loading...")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(equipment)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getDeviceImage(data.item))
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(deviceid)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getDeviceID(data.item))
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(createdtime)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getDeviceDate(data.item.createdtime)
                            )
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(fwversion)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getFWVersion(data.item))
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(lastupdatedtime)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getDeviceDate(data.item.lastupdatedtime)
                            )
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(battery)",
                    fn: function(data) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getBatteryHTML(data.item))
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm.devicelist.length == 0
            ? _c("b-row", { staticClass: "mt-0 ml-0 mr-0 pb-4" }, [
                _vm._v("This patient has no equipment registered.")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }