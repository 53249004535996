import http from './http'


const prefix = '/profile'

export function getProfileList(m_provider) {
  let url = prefix + "/provider/" + m_provider.providerid
  return http.get(url, {})
}

export function setProfile(providerid, profile) {
  let url = prefix + "/provider/" + providerid + "/profile/"+ profile.profileid
  return http.post(url, profile, {})
}

export function getProfileListByUserId(providerid, userid) {
  let url = prefix + "/provider/" + providerid+"/user/"+encodeURIComponent(userid)
  return http.get(url, {})
}

export function getProfileVacation(profile, monthlist) {
  let url = prefix + "/"+profile.profileid+"/vacation/yearmonth/"+encodeURIComponent(monthlist)
  return http.get(url, {})
}