
const STORAGE_KEY_COGNITO_USER = 'cognitouser'
const STORAGE_KEY_USER_ID = 'id'
const STORAGE_KEY_USER_ID_TOKEN = 'idToken'
const STORAGE_KEY_PROVIDER = "candibellprovider"

export default {

    setCognitoUser(user) {

        console.log("setCognitoUser:" + user)
        localStorage.setItem(STORAGE_KEY_COGNITO_USER, JSON.stringify(user))
    },

    getCognitoUser() {
        return JSON.parse(localStorage.getItem(STORAGE_KEY_COGNITO_USER))
    },


    getProvider() {
        var obj = JSON.parse(localStorage.getItem(STORAGE_KEY_PROVIDER));
        if (obj == null || obj.providerkey == null) {
            window.location.href = "/#/login";
        }
        return obj;
    },

    saveProvider(provider) {
        localStorage.setItem(STORAGE_KEY_PROVIDER, JSON.stringify(provider));
    },

    clearProvider() {
        localStorage.setItem(STORAGE_KEY_PROVIDER, JSON.stringify(null));
    },

    setUserId(id) {
        this.setItem(STORAGE_KEY_USER_ID, id)
    },

    getUserId() {
        return this.getItem(STORAGE_KEY_USER_ID)
    },

    setUserIdToken(idToken) {
        this.setItem(STORAGE_KEY_USER_ID_TOKEN, idToken)
    },

    getUserIdToken() {
        return this.getItem(STORAGE_KEY_USER_ID_TOKEN)
    },

    logout() {
        window.localStorage.clear
    },

    setItem(key, value) {
        window.localStorage.setItem(key, value)
    },

    getItem(key) {
        return window.localStorage.getItem(key)
    },

}