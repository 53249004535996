import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
Vue.use(Vuex);

const state = {
  username: '',
  provider: null,
  profiles: null,
  selectedprofile: null,
  invitations: null,
  selectedactivity: null
}

export default new Vuex.Store({
  state,
  mutations,
  actions
});