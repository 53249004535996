<template>
    <b-container fluid style="background-color:#f4f4f4;">
      <div class="row pt-5 pl-3 pb-5">
        <div class="col-sm welcome-container" style="max-width:668px">
          <b-form @submit.prevent="onSubmit" @reset="onReset">
            <div class="row d-flex">
              <div class="col">
                <h2 class="semi-bold">基础信息</h2>
              </div>
            </div>
            <div class="row d-flex" style="width:80%">
              <div
                class="col"
                style="color:#939393;font-size:14px"
              >您的信息将会显示在Candibell的手机App中，以便于您的病人浏览您的主页和与您分享数据</div>
            </div>

            <div class="row d-flex mt-4">
              
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="姓 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.lastname" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="名字 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.firstname" type="text" required></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group class="text-left" label="称呼 *" style="font-size: 12px !important;">
                  <b-form-select v-model="form.title" type="text" :options="titles" required></b-form-select>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-left" label="牙医类型 *" style="font-size: 12px !important;">
                  <b-form-select v-model="form.type" type="text" :options="types" required></b-form-select>
                </b-form-group>
              </div>
            </div>


            <div class="row d-flex mt-5">
              <div class="col">
                <h2 class="semi-bold">工作信息</h2>
              </div>
            </div>
            <div class="row d-flex" style="width:80%">
              <div
                class="col"
                style="color:#939393;font-size:14px"
              >请填写您的工作信息，这些信息将让客人更加方便的找到您</div>
            </div>
            <b-overlay :show="loader2" rounded="lg">
              <div class="row d-flex pt-4 pb-4 align-items-end">
                <div class="col-3">
                  <div
                    class="circle-avatar"
                    v-bind:style="{ 'background-image': 'url(' + getprofileimageurl() + ')' }"
                  ></div>
                </div>
                <div class="col pb-3">
                  <b-form-file
                    accept=".jpg, .png, .gif"
                    class="mt-3"
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Choose a profile image and drop it here..."
                    drop-placeholder="Drop image here..."
                  ></b-form-file>
                </div>
              </div>
            </b-overlay>

            <div class="row d-flex mt-4">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="诊所名称 *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.practicename" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col" hidden>
                <b-form-group class="text-left" label="Email *" style="font-size: 12px !important;">
                  <b-form-input v-model="form.provideremail" type="email" readonly></b-form-input>
                </b-form-group>
              </div>
            </div>

            

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="地址 1 *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.address1" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="地址 2 (可选))"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.address2" type="text"></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group class="text-left" label="城市 *" style="font-size: 12px !important;">
                  <b-form-input v-model="form.city" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-left" label="直辖市/省/自治区 *" style="font-size: 12px !important;">
                <b-form-input v-model="form.state" type="text" required></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="邮政编码 *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.zipcode" type="number" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="手机 (11位) *"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.phone" type="text" required></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="网站 (可选)"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.website" type="text"></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="执照 # (可选)"
                  style="font-size: 12px !important;"
                >
                  <b-form-input v-model="form.license" type="text"></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="描述"
                  style="font-size: 12px !important;"
                  description="请简单描述您的牙医诊所，医院，以及您的服务或者特长"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="form.description"
                    placeholder
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex mt-2 justify-content-end">
              <div class="col-2">
                <b-button
                  variant="outline-primary"
                  size="lg"
                  style="border-color:#05c5ee;color:#05c5ee"
                  @click="cancel()"
                >取消</b-button>
              </div>
              <div class="col-4">
                <b-button
                  class="blueButton"
                  type="submit"
                  variant="primary"
                  size="lg"
                  style="width: 100%;  font-size:16px"
                >保存</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
      <canvas id="canvas" style="display:none"></canvas>
    </b-container>
    
</template>

<script>
import { updateProvider, uploadProviderImage } from "../api/provider";
import storage from "./../storage";
export default {
  name: "create-prfile",
  computed: {},
  data() {
    return {
      step1: true,
      file: null,
      loader2: false,
      form: {
        firstname: "",
        lastname: "",
        prefix: "",
        suffix: "",
        practicename: "",
        provideremail: "",
        license: "",
        address1: "",
        address2: "",
        state: "",
        zipcode: "",
        phone: "",
        website: "",
        description: "",
        type: "家庭牙医",
        title: "先生",
        providerid: "",
        provideravatar: ""
      },
      types: [
        "家庭牙医",
        "儿童牙医",
        "牙医助理",
        "整形外科牙医",
        "美容牙医",
      ],
      titles: [
        "医生",
        "医师",
        "助理",
        "牙医",
        "教授",
        "女士",
        "先生",
      ],
      states: [
        "AK",
        "AL",
        "AR",
        "AS",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "GU",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MA",
        "MD",
        "ME",
        "MI",
        "MN",
        "MO",
        "MS",
        "MT",
        "NC",
        "ND",
        "NE",
        "NH",
        "NJ",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VA",
        "VI",
        "VT",
        "WA",
        "WI",
        "WV",
        "WY"
      ]
    };
  },
  mounted() {
    let provider = storage.getProvider();
    if (provider) {
      console.log(provider)
      this.form.firstname = provider.providername;
      this.form.lastname = provider.providerlastname;
      this.form.prefix = provider.providerprefix;
      this.form.suffix = provider.providersuffix;
      this.form.practicename = provider.providerofficename;
      this.form.provideremail = provider.provideremail;
      this.form.license = provider.providerlicense;
      this.form.address1 = provider.providerofficeaddress1;
      this.form.address2 = provider.providerofficeaddress2;
      this.form.state = provider.providerofficestate;
      this.form.zipcode = provider.providerofficezipcode;
      this.form.city = provider.providerofficecity;
      this.form.website = provider.providerwebsite;
      this.form.description = provider.providerdescription;
      this.form.type = provider.providercaption;
      this.form.title = provider.providertitle;
      this.form.providerid = provider.providerid;
      this.form.provideravatar = provider.provideravatar;
      this.form.phone = provider.providerphone.split("-")[1]
    }
  },
  watch: {
    file: function() {
      let size = this.file.size;
      if (size > 20000 * 1000) {
        alert("File size too large. Please use an image less than 20Mb.");
        return;
      }
      var obj = {};
      obj.extention = this.file.name.split(".").pop();
      var fr = new FileReader();
      let context = this;
      this.loader2 = true;
      fr.onload = function() {
        var img = new Image();
        img.onload = function() {
          var canvas = document.getElementById("canvas");
          canvas.width = 200;
          canvas.height = (canvas.width * img.height) / img.width;
          canvas
            .getContext("2d")
            .drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            function(blob) {
              var fr2 = new FileReader();
              fr2.onload = function() {
                var data = fr2.result;
                var array = new Int8Array(data);
                var str = Array.from(array, function(byte) {
                  return ("0" + (byte & 0xff).toString(16)).slice(-2);
                }).join("");
                obj.binaystring = str;
                uploadProviderImage(obj)
                  .then(res => {
                    console.log(res);
                    context.form.provideravatar = res.data;
                    context.loader2 = false;
                  })
                  .catch(function() {
                    context.loader2 = false;
                  });
              };
              fr2.readAsArrayBuffer(blob);
            },
            "image/jpeg",
            0.9
          );
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(this.file);
    }
  },
  methods: {
    getstart() {
      this.step1 = false;
    },
    getprofileimageurl() {
      if (this.form.provideravatar == null || this.form.provideravatar == "") {
        return require("../assets/image/dentist-profile-icon@3x.png");
      } else {
        return this.form.provideravatar;
      }
    },
    onReset() {},
    cancel() {
      this.step1 = true;
      this.$router.push("/");
    },
    onSubmit() {
      let obj = {};
      obj.providername = this.form.firstname;
      obj.providerlastname = this.form.lastname;
      obj.providertitle = this.form.title;
      obj.providerprefix = this.form.prefix;
      obj.providersuffix = this.form.suffix;
      obj.providerlicense = this.form.license;
      obj.providerdescription = this.form.description;
      obj.provideremail = this.form.provideremail;
      obj.providerwebsite = this.form.website;
      obj.providerofficename = this.form.practicename;
      obj.providerofficeaddress1 = this.form.address1;
      obj.providerofficeaddress2 = this.form.address2;
      obj.providerofficephone = this.form.phone;
      obj.providerofficecity = this.form.city;
      obj.providerofficestate = this.form.state;
      obj.providerofficezipcode = this.form.zipcode;
      obj.providercaption = this.form.type;
      obj.providerid = this.form.providerid;
      obj.provideravatar = this.form.provideravatar;
      obj.providerphone = "86-"+this.form.phone.trim();
      let loader = this.$loading.show();
      updateProvider(obj).then(res => {
        console.log(res);
        this.step1 = true;
        storage.saveProvider(res.data)
        loader.hide()
        this.$router.push("/");
      });
    }
  }
};
</script>