<template>
  <div class="header">
    <b-navbar
      toggleable="lg"
      type="dark"
      fixed="top"
      variant="info"
      style=" background-color:#323643 !important;"
    >
      <b-navbar-brand href="#" style="margin-left:1vw">
        <b-img
          center
          :src="require('../assets/image/combined-shape@2x.png')"
          fluid
          alt="Fluid image"
          width="30px"
          height="30px"
        ></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" style="" class='brand'>爱牙精灵 牙医平台</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <div
              class="circle-avatar"
              v-bind:style="{ 'background-image': 'url(' + getimage() + ')' }"
            ></div>
          </b-nav-form>

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->

            <template v-slot:button-content>
              <span style="font-size:14px">{{username}}</span>
            </template>
            <b-dropdown-item href="#" @click='gotoSetting'>设置</b-dropdown-item>
            <b-dropdown-item href="#" @click="onLogout">登出</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import storage from "./../storage";
export default {
  name: "nav-header",
  data() {
    return {
      getimage() {
        let provider =storage.getProvider();
        if (provider && provider.provideravatar) {
          return provider.provideravatar;
        } else {
          return '/imgs/dentist-profile-icon@3x.png';
        }
      }
    };
  },
  computed: {
    username() {
      let provider = storage.getProvider();
      if (provider == null) {
        return "New User";
      } else {
        if (provider.providertitle && provider.providerlastname) {
          return provider.providerlastname+provider.providertitle;
        } else {
          return "New User";
        }
      }
    }
  },
  methods: {
    onLogout: function() {
      storage.clearProvider();
      console.log("log out successful");
      window.location.href = '/#/login'
    },
    gotoSetting: function() {
      window.location.href = '/#/settings'
    },
  }
};
</script>

<style scoped>
.navbar {
  background-color: #323643 !important;
}

.nav-link {
  color: white !important;
}

.circle-avatar {
  /* make it responsive */
  max-width: 100%;
  width: 100%;
  width: 35px;
  height: auto;
  display: block;
  /* div height to be the same as width*/
  padding-top: 100%;

  /* make it a circle */
  border-radius: 50%;

  /* Centering on image`s center*/
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;

  /* it makes the clue thing, takes smaller dimension to fill div */
  background-size: cover;

  /* it is optional, for making this div centered in parent*/
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.brand .nav-link {
font-size:18px; 
font-family:ibm-plex-san-medium;
}
</style>