var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav-header"),
      _c(
        "b-overlay",
        { attrs: { show: _vm.show, rounded: "md" } },
        [
          _c("div", { staticClass: "main-container" }, [
            _c("div", { staticClass: "row", staticStyle: { margin: "0" } }, [
              _c(
                "div",
                {
                  staticClass: "col-md-1 py-2 nav-panel",
                  staticStyle: { padding: "0" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row mt-3",
                      staticStyle: { margin: "0", height: "80%" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col", staticStyle: { padding: "0" } },
                        [
                          _c(
                            "b-nav",
                            { attrs: { vertical: "", fixed: "top" } },
                            [
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "nav-item",
                                  attrs: { to: "/overview", active: "" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "white"
                                      },
                                      attrs: { "align-h": "center" }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "house-door" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { attrs: { "align-h": "center" } },
                                    [
                                      _c("p", { staticClass: "verticllabel" }, [
                                        _vm._v("纵览")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "nav-item",
                                  attrs: { to: "/patients", active: "" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "white"
                                      },
                                      attrs: { "align-h": "center" }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "people" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { attrs: { "align-h": "center" } },
                                    [
                                      _c("p", { staticClass: "verticllabel" }, [
                                        _vm._v("病人")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "nav-item",
                                  attrs: { to: "/orders" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "white"
                                      },
                                      attrs: { "align-h": "center" }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "graph-up" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { attrs: { "align-h": "center" } },
                                    [
                                      _c("p", { staticClass: "verticllabel" }, [
                                        _vm._v("订单")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "nav-item",
                                  attrs: { to: "/insights" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "white"
                                      },
                                      attrs: { "align-h": "center" }
                                    },
                                    [_c("b-icon-eye")],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { attrs: { "align-h": "center" } },
                                    [
                                      _c("p", { staticClass: "verticllabel" }, [
                                        _vm._v("趋势")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "nav-item",
                                  attrs: { to: "/invite" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "white"
                                      },
                                      attrs: { "align-h": "center" }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "envelope-open" }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { attrs: { "align-h": "center" } },
                                    [
                                      _c("p", { staticClass: "verticllabel" }, [
                                        _vm._v("邀请")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "row align-items-end pb-5",
                      staticStyle: { margin: "0" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col", staticStyle: { padding: "0" } },
                        [
                          _c(
                            "b-nav",
                            { attrs: { vertical: "" } },
                            [
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "nav-item",
                                  attrs: { to: "/settings" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "white"
                                      },
                                      attrs: { "align-h": "center" }
                                    },
                                    [_c("b-icon-gear")],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { attrs: { "align-h": "center" } },
                                    [
                                      _c("p", { staticClass: "verticllabel" }, [
                                        _vm._v("设置")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  staticClass: "nav-item",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.showLogoutModal($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "white"
                                      },
                                      attrs: { "align-h": "center" }
                                    },
                                    [_c("b-icon-box-arrow-right")],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { attrs: { "align-h": "center" } },
                                    [
                                      _c("p", { staticClass: "verticllabel" }, [
                                        _vm._v("登出")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-md offset-md-1",
                  staticStyle: { padding: "0" }
                },
                [
                  _c("router-view", {
                    staticClass: "children-container",
                    attrs: { parentready: _vm.parentready }
                  })
                ],
                1
              )
            ])
          ]),
          _c("modal", {
            attrs: {
              title: "Logout",
              confirmText: "Confirm",
              btnType: "3",
              modalType: "middle",
              showModal: _vm.isShowLogoutModal
            },
            on: {
              submit: _vm.onLogout,
              cancel: function($event) {
                _vm.isShowLogoutModal = false
              }
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function() {
                  return [_c("p", [_vm._v("确定登出?")])]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }