<template>
  <div class="jumbotron d-flex align-items-center min-vh-100">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <div class="col-sm login-container" style="max-width:600px">
          <div class="row headerBlueBackground">
            <div class="col-sm">
              <div class="row mt-3">
                <b-img
                  center
                  :src="require('../assets/image/truthbrush-icon.png')"
                  alt="Center image"
                  width="80px"
                  height="80px"
                ></b-img>
              </div>
              <div class="row" style="color:white">
                <b-img
                  center
                  :src="require('../assets/image/candibell-truthbrush.png')"
                  alt="Center image"
                  width="154px"
                  height="56px"
                ></b-img>
              </div>
              <div class="row mb-3 mt-3" style="color:white">
                <p
                  class="text-center w-100"
                  style="font-size:0.8em;"
                >Improve patient care by focusing on prevention.</p>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center mt-4">
            <h2 class="semi-bold">Forget Password</h2>
          </div>
          <br />
          <div class="row pl-4 pr-4 d-flex justify-content-center">
            <b-form class="w-75" @submit.prevent="onSubmit" @reset="onReset">
              <b-form-group
                class="text-left"
                id="input-group-1"
                label="Your Email"
                label-for="input-1"
                v-if="stage1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter email"
                ></b-form-input>
                <b-form-invalid-feedback :state="check">{{error}}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                class="text-left"
                id="input-group-1"
                label="We sent a confirmation code to your email. Please type it below with your new password:"
                label-for="input-1"
                v-if="!stage1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.code"
                  type="text"
                  required
                  placeholder="Enter 6 digit code received"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label label-for="input-2" description v-if="!stage1">
                <b-form-input
                  type="password"
                  id="input-2"
                  v-model="form.password"
                  required
                  placeholder="Enter new password"
                ></b-form-input>
                <b-form-invalid-feedback :state="check">{{error}}</b-form-invalid-feedback>
              </b-form-group>

              <b-button
                class="blueButton"
                type="submit"
                variant="primary"
                style="width: 100%; margin-top:10px"
              >{{getButtonName}}</b-button>
              <div class="row justify-content-end mt-2 pr-4" v-if="!stage1">
                <a class="aquaBlue" href @click.prevent="onResend">Send code again</a>
              </div>
            </b-form>
          </div>

          <div class="row justify-content-center mt-3">
            <a
              class="aquaBlue"
              href
              @click.prevent="onSignup"
            >New to Candibell? Create a free account.</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import storage from "./../storage";
import cognito from "./../cognito";

// reference: https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js
export default {
  name: "login",
  data() {
    return {
      stage1: true,
      check: true,
      loader: null,
      error: "",
      form: {
        email: "",
        password: "",
        code: ""
      },
      cognitoUser: null,
      resetPasswordContext: null
    };
  },
  computed: {
    getButtonName() {
      if (this.stage1 == true) {
        return "Next";
      } else {
        return "Confirm Code";
      }
    }
  },
  methods: {
    reseterrortext() {
      this.check = true;
      this.error = "";
    },
    onSubmit: function() {
      if (this.stage1 == true) {
        var authenticationData = {
          Username: this.form.email.toLowerCase(),
          Password: this.form.password
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
          authenticationData
        );
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(
          cognito.poolData
        );
        var userData = {
          Username: this.form.email.toLowerCase(),
          Pool: userPool,
          Storage: localStorage
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        this.cognitoUser = cognitoUser;
        let context = this;
        this.loader = this.$loading.show();
        cognitoUser.forgotPassword({
          onSuccess: function(result) {
            context.reseterrortext();
            console.log(
              "forget call result1: " +
                result +
                " " +
                authenticationDetails.password +
                " " +
                authenticationDetails.username
            );
            authenticationDetails.password = context.form.password;
            cognitoUser.authenticateUser(authenticationDetails, {
              onSuccess: function(response) {
                var idToken = response.idToken.jwtToken;
                storage.setCognitoUser(response);
                cognitoUser.cacheTokens();
                storage.setUserIdToken(idToken);
                storage.setUserId(userData.Username);
                context.loader.hide();
                cognito.gotoIndex();
              },

              onFailure: function(err) {
                console.log("login failed:" + err.message);
                context.check = false;
                context.error = err.message;
                context.loader.hide();
              }
            });
          },
          onFailure: function(err) {
            console.log("error1:" + err.message);
            context.check = false;
            context.error = err.message;
            context.loader.hide();
          },
          inputVerificationCode() {
            context.loader.hide();
            context.stage1 = false;
            context.resetPasswordContext = this;
            context.reseterrortext();
          }
        });
      } else {
        this.loader = this.$loading.show();
        console.log(
          "stage 2 start " + this.form.code + " " + this.form.password
        );
        this.cognitoUser.confirmPassword(
          this.form.code,
          this.form.password,
          this.resetPasswordContext
        );
      }
    },
    onReset: function() {
      this.form.email = "";
    },
    onResend: function() {
      this.loader = this.$loading.show();
      let context = this;
      this.cognitoUser.resendConfirmationCode(function() {});
      setTimeout(function() {
        context.loader.hide();
      }, 2000);
    },
    onSignup: function() {
      this.$router.push("/signup");
    }
  },
  mounted() {
    this.reseterrortext();
    this.loader = this.$loading.show();
    this.loader.hide();
  }
};
</script>

<style scoped>
.jumbotron {
  background-image: url(../assets/image/bg.png);
  background-position: fit;
  background-repeat: repeat-x;
  margin-bottom: 0;
  padding-bottom: 0;
}

.login-container {
  background-color: white;
  padding-bottom: 45px;
  border-radius: 5px;
}

.headerBlueBackground {
  background-color: #05c5ee;
  border-radius: 5px 5px 0 0;
}
.forgetpassword {
  margin-top: 5px;
}
</style>