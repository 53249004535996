import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import storage from '../storage'



export default {
    poolData: {
        UserPoolId: "us-east-1_LNRmsJg1f",
        ClientId: "7hctg6tjl1e7tfbobei42a09up"
    },
    sensors: [],


    /* direct definition of method */
    addSensor: function (sensor) {
        this.sensors.push(sensor)
    },

    getCachedCognitoUser: function () {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(this.poolData);
        let pastSession = storage.getCognitoUser()

        if (pastSession == null) return null
        let pastSessionId = pastSession.idToken.payload['cognito:username']

        var userData = {
            Username: pastSessionId,
            Pool: userPool,
            Storage: localStorage
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        return cognitoUser
    },

    getCognitoUserSession: function (callback) {

        let cognitoUser = this.getCachedCognitoUser()
        if (cognitoUser == null) {
            this.gotoLogin()
            return
        }
        cognitoUser.getSession(function (info, newSession) {
            if (newSession == null) {
                window.location.href = '/#/login'
                return
            } else {
                callback(newSession)
            }
        })
    },
    logoutCognitoUser: function () {
        let cognitoUser = this.getCachedCognitoUser()
        if (cognitoUser == null) {
            this.gotoLogin()
            return
        }
        cognitoUser.signOut()
        storage.setCognitoUser(null)
        storage.logout()
        this.gotoLogin()
    },

    gotoIndex: function () {
        window.location.href = '/'
    },
    gotoLogin: function () {
        window.location.href = '/#/login'
    },
}
