<template>
  <div class="jumbotron d-flex align-items-center min-vh-100">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <div class="col-sm login-container" style="max-width:600px">
          <div class="row headerBlueBackground">
            <div class="col-sm">
              <div class="row mt-3">
                <b-img
                  center
                  :src="require('../assets/image/truthbrush-icon.png')"
                  alt="Center image"
                  width="80px"
                  height="80px"
                ></b-img>
              </div>
              <div class="row" style="color:white">
                <b-img
                  center
                  :src="require('../assets/image/candibell-truthbrush.png')"
                  alt="Center image"
                  width="154px"
                  height="56px"
                ></b-img>
              </div>
              <div class="row mb-3 mt-3" style="color:white">
                <p
                  class="text-center w-100"
                  style="font-size:0.8em;"
                >Improve patient care by focusing on prevention.</p>
              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-center mt-4">
            <h2 class="semi-bold">Account Signup</h2>
          </div>
          <br />
          <div class="row pl-4 pr-4 d-flex justify-content-center">
            <b-form class="w-75" @submit.prevent="onSubmit" @reset="onReset">
              <b-form-group
                class="text-left"
                id="input-group-1"
                label="Your Email"
                label-for="input-1"
                v-if="stage1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="text-left"
                id="input-group-2"
                label="Password"
                label-for="input-2"
                v-if="stage1"
              >
                <b-form-input
                  type="password"
                  id="input-2"
                  v-model="form.password"
                  required
                  placeholder="New password"
                ></b-form-input>
                <b-form-invalid-feedback :state="check">{{error}}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                class="text-left"
                id="input-group-3"
                label="We sent a confirmation code to your email. Please type it below:"
                label-for="input-2"
                v-if="!stage1"
              >
                <b-form-input
                  type="text"
                  id="input-2"
                  v-model="form.code"
                  required
                  placeholder="6 digit code received"
                ></b-form-input>
                <b-form-invalid-feedback :state="check">{{error}}</b-form-invalid-feedback>
              </b-form-group>
              <b-button
                class="mt-2 blueButton"
                type="submit"
                variant="primary"
                style="width: 100%"
              >{{getButtonName}}</b-button>
              <div class="row justify-content-center mt-2 p-1" v-if="stage1">
                <p
                  style="font-size:0.8rem"
                >By creating an account, you agree to our Terms of Service and Privacy Condition.</p>
              </div>
            </b-form>
          </div>

          <div class="row justify-content-center mt-2">
            <a class="aquaBlue" href @click.prevent="onLogin">Already have an account? Log in now.</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import storage from "./../storage";
import cognito from "./../cognito";

export default {
  name: "signup",
  data() {
    return {
      stage1: true,
      check: true,
      error: "",
      form: {
        email: "",
        password: "",
        code: ""
      },
      cognitoUser: null
    };
  },
  computed: {
    getButtonName() {
      if (this.stage1 == true) {
        return "Create Account";
      } else {
        return "Confirm Code";
      }
    }
  },
  mounted() {
    this.stage1 = true;
    this.reseterrortext();
  },
  methods: {
    reseterrortext() {
      this.check = true;
      this.error = "";
    },
    onReset: function() {
      this.form.email = "";
      this.form.password = "";
    },
    onLogin: function() {
      this.$router.push("/login");
    },
    onSubmit: function() {
      if (this.stage1 == true) {
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(
          cognito.poolData
        );
        var dataEmail = {
          Name: "email",
          Value: this.form.email.toLowerCase()
        };
        var attributeList = [];
        var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
          dataEmail
        );
        attributeList.push(attributeEmail);
        let loader = this.$loading.show();
        let context = this;
        userPool.signUp(
          this.form.email.toLowerCase(),
          this.form.password,
          attributeList,
          null,
          function(err, result) {
            if (err) {
              console.log(err.message);
              loader.hide();
              context.error = err.message;
              context.check = false;
              return;
            }
            context.cognitoUser = result.user;
            console.log("user name is " + context.cognitoUser.getUsername());

            if (!result.userConfirmed) {
              loader.hide();
              context.stage1 = false;
              context.reseterrortext()
            } else {
              storage.setCognitoUser(result.user);
              context.cognitoUser.cacheTokens();
              storage.setItem("wizardshown", 0);
              loader.hide();
              cognito.gotoIndex();
            }
          }
        );
      } else {
        let context = this;
        let loader = this.$loading.show();
        this.cognitoUser.confirmRegistration(this.form.code, true, function(
          err
        ) {
          if (err) {
            console.log(err.message);
            context.error = err.message;
            context.check = false;
            loader.hide();
            return;
          }

          console.log("no error in confirm.");

          var authenticationData = {
            Username: context.form.email,
            Password: context.form.password
          };
          var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
          );
          var userPool = new AmazonCognitoIdentity.CognitoUserPool(
            cognito.poolData
          );
          var userData = {
            Username: context.form.email,
            Pool: userPool,
            Storage: localStorage
          };
          var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function(response) {
              var idToken = response.idToken.jwtToken;
              storage.setCognitoUser(response);
              cognitoUser.cacheTokens();
              storage.setUserIdToken(idToken);
              storage.setUserId(userData.Username);
              loader.hide();
              storage.setItem("wizardshown", 0);
              cognito.gotoIndex();
            },

            onFailure: function(err) {
              context.error = err.message;
              context.check = false;
              console.log("login failed:" + err);
              loader.hide();
            }
          });
        });
      }
    }
  }
};
</script>

<style scoped>
.jumbotron {
  background-image: url(../assets/image/bg.png);
  background-position: fit;
  background-repeat: repeat-x;
  margin-bottom: 0;
}

.login-container {
  background-color: white;
  padding-bottom: 45px;
  border-radius: 5px;
}

.headerBlueBackground {
  background-color: #05c5ee;
  border-radius: 5px 5px 0 0;
}

.forgetpassword {
  margin-top: 5px;
}
</style>
