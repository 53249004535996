import http from './http'


const prefix = '/invitation'

export function getInvitationList(m_provider) {
  let url = prefix + "/provider/" + m_provider.providerid
  return http.get(url, {})
}

export function resendInvitation(code) {
  let url = prefix + "/id/" + code+"/resend"
  return http.post(url,{}, {})
}

export function sendInvitation(m_invitation, sendemail) {
  let url = prefix + "/provider/" + m_invitation.providerid+'?email='+sendemail
  return http.post(url, m_invitation, {})
}