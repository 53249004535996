// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.jumbotron[data-v-6e45f49c] {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-position: fit;\r\n  background-repeat: repeat-x;\r\n  margin-bottom: 0;\n}\n.login-container[data-v-6e45f49c] {\r\n  background-color: white;\r\n  padding-bottom: 45px;\r\n  border-radius: 5px;\n}\n.headerBlueBackground[data-v-6e45f49c] {\r\n  background-color: #05c5ee;\r\n  border-radius: 5px 5px 0 0;\n}\n.forgetpassword[data-v-6e45f49c] {\r\n  margin-top: 5px;\n}\r\n", ""]);
// Exports
module.exports = exports;
