import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { BootstrapVue, IconsPlugin, BootstrapVueIcons, ModalPlugin  } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import './css/font.css'
import './css/main.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Loading,{
  color: '#05c5ee'
})
Vue.use(BootstrapVueIcons)
Vue.use(ModalPlugin)


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
