import axios from 'axios'
import env from './../env'
import storage from "./../storage";
var service = axios.create({ timeout: 1000 * 20, baseURL: env.baseURL })

service.interceptors.request.use(
    function (config) {

        let authHeader = config.headers['Authorization']

        //console.log("authHeader:" + authHeader)

        if (authHeader) {
            //console.log("already added authHeader, getProviderInfo call, no need to use providerKey")
        } else {
            var provider = storage.getProvider();
            if(provider==null){
                window.location.href = '/#/login'
                return;
            }
            const providerKey = provider.providerkey
            if (providerKey) {
                config.headers['Authorization'] = providerKey
                //console.log("providerKey:" + providerKey)
            } else {
                // No providerKey go to createprofile page
                window.location.href = '/#/createprofile'
            }
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(function (response) {
    //console.log(response.data)
    return response
}, function (error) {
    return Promise.reject(error)
})

export default service