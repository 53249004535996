var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showModal,
            expression: "showModal"
          }
        ],
        staticClass: "custom-modal"
      },
      [
        _c("div", { staticClass: "mask" }),
        _c("div", { staticClass: "custom-modal-dialog" }, [
          _c("div", { staticClass: "custom-modal-header" }, [
            _c("span", [_vm._v(_vm._s(_vm.title))]),
            _c("a", {
              staticClass: "icon-close",
              attrs: { href: "javascript:;" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            })
          ]),
          _c("div", { staticClass: "custom-modal-body" }, [_vm._t("body")], 2),
          _c("div", { staticClass: "custom-modal-footer" }, [
            _vm.btnType == 1
              ? _c(
                  "a",
                  {
                    staticClass: "custom-btn",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("submit")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.confirmText))]
                )
              : _vm._e(),
            _vm.btnType == 2
              ? _c(
                  "a",
                  {
                    staticClass: "custom-btn",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.cancelText))]
                )
              : _vm._e(),
            _vm.btnType == 3
              ? _c("div", { staticClass: "custom-btn-group" }, [
                  _c(
                    "a",
                    {
                      staticClass: "custom-btn",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("submit")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmText))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "custom-btn custom-btn-secondary",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.cancelText))]
                  )
                ])
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }