<template>
  <b-container fluid style="background-color:#f3f3f3; height:100%">
    <b-modal
      id="modal-xl"
      ref="my-modal"
      centered
      title
      size="lg"
      body-class="pt-0"
      header-class="pb-0"
    >
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h2 class="semi-bold pl-3 pt-3">邀请一个家庭</h2>
        <b-button size="lg" variant="outline" @click="close()">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <template v-slot:modal-footer="{}">
        <p></p>
      </template>
      <b-container fluid>
        <p
          class="my-4"
          style="font-size:14px"
        >生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。</p>
        <b-row class="pl-3 pr-3">
          <b-form @submit.prevent="onSubmit" style="width:100%">
            <div class="row d-flex mt-4">
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="用户手机号 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                  description="11位手机号码"
                >
                  <b-form-input v-model="form.email" type="number" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="姓 *"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                  description="请输入家庭中主要使用人的姓"
                >
                  <b-form-input v-model="form.lastname" type="text" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group
                  class="text-left"
                  label="用户识别码 (可选)"
                  label-class="form-label"
                  label-for="input-1"
                  style="font-size: 12px !important;"
                  description="识别码为可选。帮助您关联用户在康迪贝尔和您其他系统中的相互关系"
                >
                  <b-form-input v-model="form.id" type="text"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <b-row class="pl-3 pr-3">
              <b-form-group label>
                <b-form-radio
                  v-model="selected"
                  name="some-radios"
                  value="0"
                  selected
                >生成邀请码，不发出短信</b-form-radio>
                <b-form-radio
                  v-model="selected"
                  name="some-radios"
                  value="1"
                >生成邀请码，并且短信通知用户</b-form-radio>
              </b-form-group>
            </b-row>

            <div class="row d-flex mt-2 justify-content-center">
              <div class="col-4">
                <b-button
                  class="blueButton"
                  type="submit"
                  variant="primary"
                  size="lg"
                  style="width: 100%;  font-size:16px"
                  @click="sendInvitation()"
                >{{buttontext}}</b-button>
              </div>
            </div>
          </b-form>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-xl2"
      ref="my-modal"
      centered
      title
      size="lg"
      body-class="pt-0"
      header-class="pb-0"
    >
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h2 class="semi-bold pl-3 pt-3">邀请病人</h2>
        <b-button size="lg" variant="outline" @click="close()">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <template v-slot:modal-footer="{}">
        <p></p>
      </template>
      <b-container fluid>
        <p
          class="my-4"
          style="font-size:14px"
        >生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。</p>

        <b-row class="mt-5 ml-1 mr-1 pt-3" style="background-color:#f4f4f4">
          <b-container>
            <b-row class="mt-2">
              <b-col cols="8">
                <p>
                  <b>Or import a list for batch invitation</b>
                </p>
                <p>1. File format must be in .csv format.</p>
                <p>
                  2. Each row must contain
                  <b>{email}</b>,
                  <b>{family name}</b>,
                  <b>{Family ID}</b>
                </p>
                <p>3. The three values in each row must be separated by a comma ","</p>
                <p>
                  4. Download a
                  <b-link href="#samplefile" style="color:#05c5ee">Sample File</b-link>
                </p>
              </b-col>
              <b-col>
                <b-form-file accept=".csv, .txt" v-model="file" class="mt-3" plain></b-form-file>
              </b-col>
            </b-row>
            <b-row class="mt-3 mb-4 justify-content-center">
              <b-col cols="auto">
                <b-button
                  class="blueButton"
                  type="submit"
                  variant="primary"
                  size="lg"
                  style="width: 100%;  font-size:16px"
                  @click="sendBatch()"
                >Upload and send batch invitations</b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      id="modal-invite-main"
      centered
      title
      size="xl"
      body-class="pt-0"
      no-stacking
      header-class="pb-0"
    >
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h3
          class="semi-bold pl-3 pt-3"
        >邀请您的病人</h3>
        <br />
        <b-button size="lg" variant="outline" @click="close()">
          <b-icon icon="x" aria-hidden="true"></b-icon>
        </b-button>
      </template>
      <template v-slot:modal-footer="{}">
        <p></p>
      </template>
      <b-container fluid>
        


        <b-row class="pl-3 pr-3 mt-2">
          <p style="font-size:12px; color:#393939; text-align:justify">
            生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。
          </p>
        </b-row>

        <b-row class="mt-5">
          <b-col>
            <b-row align-h="center">
              <b-img src="/imgs/patient@3x.png" width="64px" height="64px"></b-img>
            </b-row>
            <b-row align-h="center" class="mt-3">
              <h5>添加病人</h5>
            </b-row>
            <b-row align-h="center" class="mt-1">
              <b-button
                class="blueButton"
                type="submit"
                variant="primary"
                size="lg"
                style="font-size:16px"
                v-b-modal.modal-xl
              >邀请病人</b-button>
            </b-row>
          </b-col>

          <b-col>
            <b-row align-h="center">
              <b-img src="/imgs/file@3x.png" width="64px" height="64px"></b-img>
            </b-row>
            <b-row align-h="center" class="mt-3">
              <h5>批量邀请</h5>
            </b-row>
            <b-row align-h="center" class="mt-1">
              <b-button
                class="blueButton"
                type="submit"
                variant="primary"
                size="lg"
                style="font-size:16px"
                v-b-modal.modal-xl2
              >批量邀请</b-button>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-row class="pt-5">
      <b-col>
        <h3 class="semi-bold mb-1">我的邀请</h3>
        <p style="font-size:1rem">{{invitecount}} 个邀请</p>
      </b-col>
      <b-col cols="auto">
        <div class="input-group mb-3">
          <b-form-input
            size="lg"
            type="text"
            class="form-control"
            placeholder="搜索关键字"
            style="background-color:#ced4da"
            v-model="filterinput"
            debounce="800"
          />
          <div class="input-group-append">
            <b-button
              variant="outline-info"
              class
              size="lg"
              style="background-color:#ced4da; border:1px solid #ced4da;"
              @click="onSearch"
            >
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="input-group-append">
            <b-button
              variant="outline-info"
              class
              size="lg"
              style="background-color:#05c5ee; border:1px solid #05c5ee; color:white; "
              v-b-modal.modal-invite-main
            >
              邀请客人
              <b-icon icon="plus" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row align-v="center" class="pt-5">
      <b-col cols="auto">Show</b-col>
      <b-col cols="auto" class="pl-0 pr-0">
        <b-form-group id="input-group-3" label-for="input-3" style="margin-bottom:0!important">
          <b-form-select size="sm" id="input-3" v-model="perPage" :options="countwindows"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="auto">entries</b-col>
    </b-row>
    <b-row class="mt-3 pl-4 pr-4">
      <b-table
        id="my-table"
        :fields="fields"
        :items="invitationlist"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hover
        style="background-color: white;"
        sort-icon-left
        tbody-tr-class="table-row"
        :busy="isBusy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        @filtered="onFiltered"
        :filterIncludedFields="filterOn"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2" style="color:#05c5ee">
            <b-spinner class="align-middle"></b-spinner>
            <strong>&nbsp;Loading...</strong>
          </div>
        </template>

        <!-- A custom formatted data column cell -->
        <template v-slot:cell(userid)="data">
          <span v-html="getUserIDHTML(data.item)"></span>
        </template>

        <template v-slot:cell(createdtime)="data">
          <span v-html="getTimestampHTML(data.value)"></span>
        </template>

        <template v-slot:cell(lastupdatedtime)="data">
          <span v-html="getTimestampHTML(data.value)"></span>
        </template>

        <template v-slot:cell(rowbutton)="data">
          <b-button
            variant="outline-primary"
            class
            size="md"
            style="background-color:white; border:2px solid #05c5ee; color:#05c5ee;"
            @click="onClickRow(data.item)"
          >Resend</b-button>
        </template>
      </b-table>
    </b-row>
    <b-row class="mt-3 pl-4 pr-4" align-h="end">
      <b-pagination
        v-model="currentPage"
        :total-rows="invitecount"
        :per-page="perPage"
        aria-controls="my-table"
        align="right"
      ></b-pagination>
    </b-row>
  </b-container>
</template>

<script>
import {
  getInvitationList,
  resendInvitation,
  sendInvitation
} from "../api/invitation.js";
import storage from "./../storage";
export default {
  name: "my-invite",
  data() {
    return {
      selected: 0,
      buttontext: "生成邀请",
      invitecount: 0,
      allpatients: true,
      countwindows: [10, 20, 50, 100],
      invitationlist: [],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      sortBy: "createdtime",
      sortDesc: true,
      form: {
        email: "",
        lastname: "",
        id: ""
      },
      file: null,
      filter: "",
      filterinput: "",
      filterOn: ["email", "lastname", "userid", "status", "externalid", "code"],
      fields: [
        {
          key: "email",
          label: "手机号",
          sortable: true,
          thStyle: "font-size:14px"
        },
        {
          key: "externalid",
          label: "家庭 ID",
          sortable: true,
          thStyle: "font-size:14px"
        },

        {
          key: "lastname",
          label: "姓",
          sortable: true,
          thStyle: "font-size:14px"
        },
        {
          key: "userid",
          label: "爱牙精灵账号",
          sortable: true,
          thStyle: "font-size:14px"
        },
        {
          key: "code",
          label: "邀请码",
          sortable: false,
          thStyle: "font-size:14px"
        },
        {
          key: "createdtime",
          label: "生成时间",
          sortable: true,
          thStyle: "font-size:14px"
        },
        {
          key: "lastupdatedtime",
          label: "启用时间",
          sortable: true,
          thStyle: "font-size:14px"
        },
        {
          key: "status",
          label: "状态",
          sortable: true,
          thStyle: "font-size:14px"
        }
      ]
    };
  },
  computed: {
    emailTemplate() {
      return "Date\n\nDear Patient, (BEST TO USE PATIENT NAME)\n\n[DENTAL PRACTICE NAME] considers it a privilege to provide you and your family with the highest standard of dental care and we are always looking for ways to better serve you. We would like to take this opportunity to share with you an exciting new technology that enhances our ability to assist you and your child with developing more effective brushing habits in between visits. This innovative tool helps families track and improve daily brushing habits. We provide you with a “Truthbrush Tracker” the size of a button that fits comfortably on any toothbrush.  The tracker  transmits brushing data to you and our office. The data includes the frequency, duration, and thoroughness of brushing events and provides easy to understand analytics on an app that you download. The data that is collected over time provides parents and our office an invaluable tool which can be used to encourage behavior change in a positive way.\n\nAs dentists, we know that good brushing habits are an important key to prevention of periodontal disease and  tooth decay.  Sadly, these diseases  impact half of all children in the U.S., according to the latest figures by the Centers for Disease Control. Now, with the help of Truthbrush Tracker, we can for the first time monitor your child’s brushing in between visits and provide you with real time relevant feedback and strategies we know help with good habit building. This technology offers us a new way to work together to optimize your child’s brushing habits.  To take advantage of the Trushbrush tracker simply reply to this email and let us know the number of family members that you want to have trackers. We will send trackers and instructions to setup. As always, we thank you for entrusting us with your dental care!\n\nSincerely, [DENTIST NAME]";
    }
  },
  watch: {
    parentready: function() {
      this.isBusy = true;
      if (this.parentready === true) {
        getInvitationList(storage.getProvider()).then(res => {
          this.$store.state.invitations = res.data;
          this.invitationlist = res.data;

          this.isBusy = false;
          /*
          let i = 0;
          for (i = 0; i < 20000; i++) {
            let ele = this.invitationlist[0];
            this.invitationlist.push(ele);
          }
          */
          this.invitecount = this.invitationlist.length;
        });
      }
    },
    selected: function() {
      if (this.selected == 0) {
        this.buttontext = "生成邀请码";
      } else {
        this.buttontext = "生成邀请码并发出";
      }
    },
    filterinput: function() {
      this.isBusy = true;
      let context = this;
      setTimeout(function() {
        context.filter = context.filterinput;
      }, 100);
    }
  },
  props: {
    parentready: Boolean
  },
  methods: {
    batchinvite() {
      //alert('not implemented')
    },
    onSubmit() {},
    copyText() {
      document.getElementById("textarea-large").select();
      document.execCommand("copy");
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    },
    onClickRow(row) {
      console.log("clicked:" + row);
      this.isBusy = true;
      let context = this;
      resendInvitation(row.invitationid).then(function() {
        console.log("sent");
        context.isBusy = false;
        context.$bvModal.msgBoxOk("Invitation was successfully sent", {
          title: "Confirmation",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        });
      });
    },
    onSearch: function() {
      if (this.filter == this.filterinput) {
        return;
      }
      console.log("start filter");

      this.isBusy = true;
      let context = this;
      setTimeout(function() {
        context.filter = context.filterinput;
      }, 100);

      console.log("start filter2");
    },
    onFiltered() {
      console.log("filtered");
      this.isBusy = false;
    },
    getUserIDHTML(item) {
      if (
        item == null ||
        item == "" ||
        item.userid == null ||
        item.userid == ""
      ) {
        return "";
      }
      this.$store.state.selectedprofile = item;
      return (
        "<a href='/#/singlepatient/' style='color:#05c5ee' class='semi-bold'>" +
        item.userid +
        "</a>"
      );
    },
    getTimestampHTML(timestamp) {
      var date = new Date(timestamp);
      return date.toLocaleString();
    },
    sendBatch() {
      this.$bvModal.msgBoxOk("Feature not implemented", {
        title: "Sending invitation",
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true
      });
    },
    sendInvitation() {
      let m_invitation = {};
      m_invitation.externalid = this.form.id;
      m_invitation.lastname = this.form.lastname;
      m_invitation.email = this.form.email.toLowerCase();
      m_invitation.status = "PENDING";
      m_invitation.providerid = storage.getProvider().providerid;

      if (!this.form.lastname || !this.form.email) {
        return;
      }

      let context = this;
      let loader = this.$loading.show();
      sendInvitation(m_invitation, this.selected == 1)
        .then(function() {
          loader.hide();
          context.isBusy = true;
          let context2 = context;
          getInvitationList(storage.getProvider()).then(res => {
            context2.$store.state.invitations = res.data;
            context2.invitationlist = res.data;

            context2.isBusy = false;
            context2.invitecount = context2.invitationlist.length;
          });
          context.$refs["my-modal"].hide();
        })
        .catch(function() {
          loader.hide();
        });
    }
  },
  mounted: function() {
    console.log("myinvite mounted");
    var provider = storage.getProvider()
    if (provider != null) {
      this.isBusy = true;
      getInvitationList(provider).then(res => {
        this.$store.state.invitations = res.data;
        this.invitationlist = res.data;
        this.isBusy = false;
        this.invitecount = this.invitationlist.length;
      });
    }
  }
};
</script>

<style>
.table-row {
  font-size: 14px !important;
  border-top-color: #05c5ee;
  border-bottom-color: #05c5ee;
  color: #393939;
}
.page-item.active .page-link {
  background-color: #05c5ee !important;
  border-color: #05c5ee !important;
  color: white !important;
}

.page-link {
  color: #05c5ee !important;
}

.modal-header {
  border-bottom: 0px solid white !important;
}

.modal-footer {
  border-top: 0px solid white !important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  width: 100%;
  border-top: 1px solid rgba(22, 22, 22, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>