import http from './http'


const prefix = '/records'

export function getRecords(profileid, start, end) {
  let url = prefix + "/profile/" + profileid+"?start="+start+"&onlyshowvalid=true"
  if(end){
    url = prefix + "/profile/" + profileid+"?start="+start+"&end="+end+"&onlyshowvalid=true"
  }
  return http.get(url, {})
}

export function getDatafile(url) {
  return http.get(url, {})
}