var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c(
        "b-navbar",
        {
          staticStyle: { "background-color": "#323643 !important" },
          attrs: {
            toggleable: "lg",
            type: "dark",
            fixed: "top",
            variant: "info"
          }
        },
        [
          _c(
            "b-navbar-brand",
            { staticStyle: { "margin-left": "1vw" }, attrs: { href: "#" } },
            [
              _c("b-img", {
                attrs: {
                  center: "",
                  src: require("../assets/image/combined-shape@2x.png"),
                  fluid: "",
                  alt: "Fluid image",
                  width: "30px",
                  height: "30px"
                }
              })
            ],
            1
          ),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    { staticClass: "brand", attrs: { href: "#" } },
                    [_vm._v("爱牙精灵 牙医平台")]
                  )
                ],
                1
              ),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c("b-nav-form", [
                    _c("div", {
                      staticClass: "circle-avatar",
                      style: {
                        "background-image": "url(" + _vm.getimage() + ")"
                      }
                    })
                  ]),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "14px" } },
                                [_vm._v(_vm._s(_vm.username))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.gotoSetting }
                        },
                        [_vm._v("设置")]
                      ),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "#" }, on: { click: _vm.onLogout } },
                        [_vm._v("登出")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }