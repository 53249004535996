import http from './http'


const prefix = '/customer'

export function getCustomer(providerid, userid) {
  let url = prefix + "/provider/" + providerid +'/user/' + encodeURIComponent(userid)
  return http.get(url, {})
}

export function setCustomer(providerid, customer) {
  let url = prefix + "/provider/" + providerid+"/user/"+encodeURIComponent(customer.userid)
  return http.post(url, customer, {})
}