var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticStyle: { "background-color": "#f3f3f3", height: "100%" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            id: "modal-xl",
            centered: "",
            title: "",
            size: "lg",
            "body-class": "pt-0",
            "header-class": "pb-0"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("h2", { staticClass: "semi-bold pl-3 pt-3" }, [
                    _vm._v("邀请一个家庭")
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "lg", variant: "outline" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x", "aria-hidden": "true" }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "modal-footer",
              fn: function(ref) {
                return [_c("p")]
              }
            }
          ])
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "p",
                { staticClass: "my-4", staticStyle: { "font-size": "14px" } },
                [
                  _vm._v(
                    "生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。"
                  )
                ]
              ),
              _c(
                "b-row",
                { staticClass: "pl-3 pr-3" },
                [
                  _c(
                    "b-form",
                    {
                      staticStyle: { width: "100%" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmit($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row d-flex mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "用户手机号 *",
                                  "label-class": "form-label",
                                  "label-for": "input-1",
                                  description: "11位手机号码"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "number", required: "" },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "姓 *",
                                  "label-class": "form-label",
                                  "label-for": "input-1",
                                  description: "请输入家庭中主要使用人的姓"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.form.lastname,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "lastname", $$v)
                                    },
                                    expression: "form.lastname"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-left",
                                staticStyle: { "font-size": "12px !important" },
                                attrs: {
                                  label: "用户识别码 (可选)",
                                  "label-class": "form-label",
                                  "label-for": "input-1",
                                  description:
                                    "识别码为可选。帮助您关联用户在康迪贝尔和您其他系统中的相互关系"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.form.id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "id", $$v)
                                    },
                                    expression: "form.id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "pl-3 pr-3" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  attrs: {
                                    name: "some-radios",
                                    value: "0",
                                    selected: ""
                                  },
                                  model: {
                                    value: _vm.selected,
                                    callback: function($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected"
                                  }
                                },
                                [_vm._v("生成邀请码，不发出短信")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  attrs: { name: "some-radios", value: "1" },
                                  model: {
                                    value: _vm.selected,
                                    callback: function($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected"
                                  }
                                },
                                [_vm._v("生成邀请码，并且短信通知用户")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row d-flex mt-2 justify-content-center"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "blueButton",
                                  staticStyle: {
                                    width: "100%",
                                    "font-size": "16px"
                                  },
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "lg"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendInvitation()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.buttontext))]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "my-modal",
          attrs: {
            id: "modal-xl2",
            centered: "",
            title: "",
            size: "lg",
            "body-class": "pt-0",
            "header-class": "pb-0"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("h2", { staticClass: "semi-bold pl-3 pt-3" }, [
                    _vm._v("邀请病人")
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "lg", variant: "outline" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x", "aria-hidden": "true" }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "modal-footer",
              fn: function(ref) {
                return [_c("p")]
              }
            }
          ])
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "p",
                { staticClass: "my-4", staticStyle: { "font-size": "14px" } },
                [
                  _vm._v(
                    "生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。"
                  )
                ]
              ),
              _c(
                "b-row",
                {
                  staticClass: "mt-5 ml-1 mr-1 pt-3",
                  staticStyle: { "background-color": "#f4f4f4" }
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c("b-col", { attrs: { cols: "8" } }, [
                            _c("p", [
                              _c("b", [
                                _vm._v("Or import a list for batch invitation")
                              ])
                            ]),
                            _c("p", [
                              _vm._v("1. File format must be in .csv format.")
                            ]),
                            _c("p", [
                              _vm._v(" 2. Each row must contain "),
                              _c("b", [_vm._v("{email}")]),
                              _vm._v(", "),
                              _c("b", [_vm._v("{family name}")]),
                              _vm._v(", "),
                              _c("b", [_vm._v("{Family ID}")])
                            ]),
                            _c("p", [
                              _vm._v(
                                '3. The three values in each row must be separated by a comma ","'
                              )
                            ]),
                            _c(
                              "p",
                              [
                                _vm._v(" 4. Download a "),
                                _c(
                                  "b-link",
                                  {
                                    staticStyle: { color: "#05c5ee" },
                                    attrs: { href: "#samplefile" }
                                  },
                                  [_vm._v("Sample File")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "b-col",
                            [
                              _c("b-form-file", {
                                staticClass: "mt-3",
                                attrs: { accept: ".csv, .txt", plain: "" },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3 mb-4 justify-content-center" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "blueButton",
                                  staticStyle: {
                                    width: "100%",
                                    "font-size": "16px"
                                  },
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "lg"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendBatch()
                                    }
                                  }
                                },
                                [_vm._v("Upload and send batch invitations")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-invite-main",
            centered: "",
            title: "",
            size: "xl",
            "body-class": "pt-0",
            "no-stacking": "",
            "header-class": "pb-0"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("h3", { staticClass: "semi-bold pl-3 pt-3" }, [
                    _vm._v("邀请您的病人")
                  ]),
                  _c("br"),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "lg", variant: "outline" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "x", "aria-hidden": "true" }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "modal-footer",
              fn: function(ref) {
                return [_c("p")]
              }
            }
          ])
        },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c("b-row", { staticClass: "pl-3 pr-3 mt-2" }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "12px",
                      color: "#393939",
                      "text-align": "justify"
                    }
                  },
                  [
                    _vm._v(
                      " 生成一个唯一的邀请码并分享给一个家庭。当用户在康迪贝尔手机App中输入此牙医邀请码后，您就可以观察家庭里所有用户的刷牙数据。 "
                    )
                  ]
                )
              ]),
              _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/imgs/patient@3x.png",
                              width: "64px",
                              height: "64px"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3", attrs: { "align-h": "center" } },
                        [_c("h5", [_vm._v("添加病人")])]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1", attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-xl",
                                  modifiers: { "modal-xl": true }
                                }
                              ],
                              staticClass: "blueButton",
                              staticStyle: { "font-size": "16px" },
                              attrs: {
                                type: "submit",
                                variant: "primary",
                                size: "lg"
                              }
                            },
                            [_vm._v("邀请病人")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/imgs/file@3x.png",
                              width: "64px",
                              height: "64px"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3", attrs: { "align-h": "center" } },
                        [_c("h5", [_vm._v("批量邀请")])]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1", attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-xl2",
                                  modifiers: { "modal-xl2": true }
                                }
                              ],
                              staticClass: "blueButton",
                              staticStyle: { "font-size": "16px" },
                              attrs: {
                                type: "submit",
                                variant: "primary",
                                size: "lg"
                              }
                            },
                            [_vm._v("批量邀请")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-5 ml-0 mr-0" },
        [
          _c("b-col", [
            _c("h3", { staticClass: "semi-bold mb-0" }, [_vm._v("Overview")]),
            _c(
              "p",
              { staticStyle: { "font-size": "1rem", "padding-top": "5px" } },
              [_vm._v(_vm._s(_vm.patientcount) + " patients")]
            )
          ]),
          _c("b-col", { attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "input-group mb-3" }, [
              _c(
                "div",
                {},
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.modal-invite-main",
                          modifiers: { "modal-invite-main": true }
                        }
                      ],
                      staticStyle: {
                        "background-color": "#05c5ee",
                        border: "1px solid #05c5ee",
                        color: "white"
                      },
                      attrs: { variant: "outline-info", size: "lg" }
                    },
                    [
                      _vm._v(" 邀请客人 "),
                      _c("b-icon", {
                        attrs: { icon: "plus", "aria-hidden": "true" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-2 pl-0 pr-0 ml-0 mr-0" },
        [
          _c(
            "b-col",
            { staticClass: "pl-5 pr-5", attrs: { cols: "12" } },
            [
              _c(
                "b-row",
                {
                  staticClass: "rounded",
                  staticStyle: { background: "white" }
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-2", attrs: { cols: "12", hidden: "" } },
                    [
                      _c(
                        "b-row",
                        {
                          staticClass: "pb-3 pl-3 pt-3",
                          attrs: { "align-h": "center" }
                        },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "legendbox",
                              staticStyle: {
                                color: "#c92a2a",
                                "background-color": "#c92a2a",
                                "margin-left": "10px"
                              }
                            },
                            [_vm._v("o")]
                          ),
                          _vm._v("# Poor "),
                          _c(
                            "b-badge",
                            {
                              staticClass: "legendbox",
                              staticStyle: {
                                color: "#ff974a",
                                "background-color": "#ff974a",
                                "margin-left": "10px"
                              }
                            },
                            [_vm._v("o")]
                          ),
                          _vm._v("# Fair "),
                          _c(
                            "b-badge",
                            {
                              staticClass: "legendbox",
                              staticStyle: {
                                color: "#3dd598",
                                "background-color": "#3dd598",
                                "margin-left": "10px"
                              }
                            },
                            [_vm._v("o")]
                          ),
                          _vm._v("# Great ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pb-5 pt-5",
                      staticStyle: { position: "relative" },
                      attrs: { cols: "12", "align-self": "center" }
                    },
                    [
                      _c("canvas", {
                        staticStyle: {
                          "max-height": "800px",
                          "max-width": "800px",
                          margin: "auto"
                        },
                        attrs: { id: "overviewchart" }
                      }),
                      _c("div", { staticClass: "absolute-Center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "medium",
                            staticStyle: {
                              "font-size": "40px",
                              "margin-bottom": "0",
                              cursor: "pointer"
                            }
                          },
                          [_vm._v(_vm._s(_vm.patientcount))]
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#696974",
                              "font-size": "14px"
                            }
                          },
                          [_vm._v("Total patients")]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }