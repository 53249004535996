var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jumbotron d-flex align-items-center min-vh-100" },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row d-flex justify-content-center" }, [
          _c(
            "div",
            {
              staticClass: "col-sm login-container",
              staticStyle: { "max-width": "600px" }
            },
            [
              _c("div", { staticClass: "row headerBlueBackground" }, [
                _c("div", { staticClass: "col-sm" }, [
                  _c(
                    "div",
                    { staticClass: "row mt-3" },
                    [
                      _c("b-img", {
                        attrs: {
                          center: "",
                          src: require("../assets/image/truthbrush-icon.png"),
                          alt: "Center image",
                          width: "80px",
                          height: "80px"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row", staticStyle: { color: "white" } },
                    [
                      _c("b-img", {
                        attrs: {
                          center: "",
                          src: require("../assets/image/candibell-truthbrush.png"),
                          alt: "Center image",
                          width: "154px",
                          height: "56px"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._m(0)
                ])
              ]),
              _vm._m(1),
              _c("br"),
              _c(
                "div",
                { staticClass: "row pl-4 pr-4 d-flex justify-content-center" },
                [
                  _c(
                    "b-form",
                    {
                      staticClass: "w-75",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmit($event)
                        },
                        reset: _vm.onReset
                      }
                    },
                    [
                      _vm.stage1
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              attrs: {
                                id: "input-group-1",
                                label: "Your Email",
                                "label-for": "input-1"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-1",
                                  type: "email",
                                  required: "",
                                  placeholder: "Enter email"
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.stage1
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              attrs: {
                                id: "input-group-2",
                                label: "Password",
                                "label-for": "input-2"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "password",
                                  id: "input-2",
                                  required: "",
                                  placeholder: "New password"
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { state: _vm.check } },
                                [_vm._v(_vm._s(_vm.error))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.stage1
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "text-left",
                              attrs: {
                                id: "input-group-3",
                                label:
                                  "We sent a confirmation code to your email. Please type it below:",
                                "label-for": "input-2"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  id: "input-2",
                                  required: "",
                                  placeholder: "6 digit code received"
                                },
                                model: {
                                  value: _vm.form.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "code", $$v)
                                  },
                                  expression: "form.code"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { state: _vm.check } },
                                [_vm._v(_vm._s(_vm.error))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-2 blueButton",
                          staticStyle: { width: "100%" },
                          attrs: { type: "submit", variant: "primary" }
                        },
                        [_vm._v(_vm._s(_vm.getButtonName))]
                      ),
                      _vm.stage1
                        ? _c(
                            "div",
                            {
                              staticClass: "row justify-content-center mt-2 p-1"
                            },
                            [
                              _c(
                                "p",
                                { staticStyle: { "font-size": "0.8rem" } },
                                [
                                  _vm._v(
                                    "By creating an account, you agree to our Terms of Service and Privacy Condition."
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "row justify-content-center mt-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "aquaBlue",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onLogin($event)
                      }
                    }
                  },
                  [_vm._v("Already have an account? Log in now.")]
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row mb-3 mt-3", staticStyle: { color: "white" } },
      [
        _c(
          "p",
          {
            staticClass: "text-center w-100",
            staticStyle: { "font-size": "0.8em" }
          },
          [_vm._v("Improve patient care by focusing on prevention.")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row d-flex justify-content-center mt-4" },
      [_c("h2", { staticClass: "semi-bold" }, [_vm._v("Account Signup")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }